import styled, { css } from 'styled-components';

const Block = styled.div`
  ${({ theme: { color, props } }) => css`
    display: flex;
    border-top: 1px solid currentColor;
    padding: 10px 0 0 0;
    margin-bottom: 4em;
    align-items: baseline;
    color: ${color[props.color]};

    @media (max-width: 480px) {
      flex-direction: column;
    }
  `}
`;

Block.Name = styled.div`
  flex-shrink: 0;
  font-size: 20px;
  padding-right: 60px;
  margin-bottom: 10px;
  @media (min-width: 481px) {
    width: 40%;
  }
`;

Block.Value = styled.div`
  width: 100%;

  font-size: 32px;

  @media (min-width: 481px) {
    text-align: right;
  }
`;

export default Block;

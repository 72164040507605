import styled from "styled-components";

export const Wrapper = styled.div`
  margin: 0 0 0 190px;
  @media (max-width: 1024px) {
    margin: 0 0 0 120px;
  }
  @media (max-width: 600px) {
    margin: 0 0 0 40px;
  }
`;

import React from 'react';

const Button = ({ text, cl, setShow, link }) => {
  const clickHandler = () => {
    !link && setShow((prev) => !prev);
  };

  return (
    <div className={cl('button_wrapper')}>
      <a className={cl('button')} onClick={clickHandler} href={link}>
        {text}
      </a>
    </div>
  );
};

export default Button;

import React from 'react';

const Slide = ({ slide, cl }) => {
  const { position, text, link } = slide;
  return (
    <>
      <a href={link} target={'_blank'}>
        <div className={cl('slide-position')} dangerouslySetInnerHTML={{ __html: position }} />
        <div className={cl('slide-text')} dangerouslySetInnerHTML={{ __html: text }} />
      </a>
    </>
  );
};

export default Slide;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { classnames, classname, raw } from 'utils';
import './Note.css';

/**
 * Текстовая сноска
 */
class Note extends Component {
  cl = classname('note');

  static propTypes = {
    /** Дополнительный класс */
    className: PropTypes.string,
    /** Подпись */
    caption: PropTypes.string,
    /** Стиль текста */
    italic: PropTypes.bool,
    light: PropTypes.bool,
    /** Текст */
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
  };

  static defaultProps = {
    italic: false,
    light: false
  };

  render() {
    const {
      className,
      caption,
      italic,
      light,
      children
    } = this.props;

    const classes = {
      main: classnames(className,this.cl()),
      text: classnames(this.cl('text', {italic: Boolean(italic), light: Boolean(light)}))
    };

    const props = {
      className: classes,
      caption
    };

    return (
      <div className={classes.main}>
        <div className={this.cl('wrapper')}>
          {children && <div className={classes.text} {...raw(children)} />}
          {caption && (
              <div className={this.cl('caption')} {...raw(caption)} />
          )}
        </div>
      </div>
    );

  }
}

export default Note;

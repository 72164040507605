import React, { Component } from 'react';

import { Figure } from 'components';
import { Icon } from 'elements';

import { classnames, classname } from 'utils';

import './Presentation.css';

class Presentation extends Component {
  cl = classname('presentation');

  constructor(props) {
    super(props);

    this.state = {
      active: 0
    };
  }

  getArray(count) {
    const list = [];

    for (let i = 1; i <= count; i++) {
      list.push(i);
    }

    return list;
  }

  onClickSlider(step) {
    const count = this.state.active + step;

    if (this.props.count === count) {
      this.setState({
        active: 0
      });
    } else if (count <= 0) {
      this.setState({
        active: this.props.count - 1
      });
    } else {
      this.setState({
        active: count
      });
    }
  }

  render() {
    const { className, count, path, pdf, google } = this.props;

    const classes = classnames(className, this.cl());

    const list = this.getArray(count);

    return (
      <div className={classes} style={{ '--presentation__active': this.state.active }}>
        <div className={this.cl('slider')} onClick={e => this.onClickSlider(1)}>
          <div className={this.cl('inner')}>
            {list.map(index => {
              return (
                <div
                  className={this.cl('slide', {
                    prev: index === this.state.active,
                    active: index - 1 === this.state.active,
                    next: index - 2 === this.state.active
                  })}
                  key={index}
                >
                  <Figure image={path + index + '.png'} />
                </div>
              );
            })}
          </div>
        </div>
        <div className={this.cl('info')}>
          <div className={this.cl('navigation')}>
            <button className={this.cl('action')} onClick={() => this.onClickSlider(-1)}>
              <Icon name={'pointer'} rotate={180} />
            </button>
            <button className={this.cl('action')} onClick={() => this.onClickSlider(1)}>
              <Icon name={'pointer'} />
            </button>
          </div>
          <div className={this.cl('list')}>
            {pdf && (
              <a href={pdf} target="_blank" className={this.cl('link')}>
                pdf
              </a>
            )}
            {google && (
              <a href={google} target="_blank" className={this.cl('link')}>
                Google Презентации
              </a>
            )}
          </div>
          <div className={this.cl('page')}>
            Слайд {this.state.active + 1} / {count}
          </div>
        </div>
      </div>
    );
  }
}

export default Presentation;

import React, { useEffect, useRef, useState } from 'react';
import createObserver from '../../../../utils/createObserver';

const CursorImages = ({ cl, children, images }) => {
  const { path, quantity, format } = images;

  const wrapperRef = useRef(null);
  const imagesRef = useRef(null);

  const [count, setCount] = useState(0);
  const [load, setLoad] = useState(false);
  const [visible, setVisible] = useState(false);
  const [click, setClick] = useState(false);

  const loadImage = (img) =>
    new Promise((res, rej) => {
      const image = new Image(500, 500);
      image.src = img;
      image.onload = () => res(img);
      image.onerror = image.onabort = (err) => rej(err);
    });

  const createPath = (path, i, format) => `https://static.nimax.ru${path}/image_${i}.${format}`;

  const onVisible = (entries) =>
    wrapperRef.current && entries.forEach((entry) => (entry.isIntersecting ? setVisible(true) : setVisible(false)));

  const handleMouseDown = () => setClick(true);
  const handleMouseUp = () => setClick(false);

  const onMouseEvent = (e) => {
    if (load) {
      e.persist();
      throttleFunc(e);
    }
  };

  const throttle = (func, ms) => {
    let isThrottled = false;
    let savedArgs = false;
    let savedThis = false;

    function wrapper() {
      if (isThrottled) {
        savedArgs = arguments;
        savedThis = this;
        return;
      }
      func.apply(this, arguments);
      isThrottled = true;
      setTimeout(function () {
        isThrottled = false; // (3)
        if (savedArgs) {
          wrapper.apply(savedThis, savedArgs);
          savedArgs = savedThis = null;
        }
      }, ms);
    }

    return wrapper;
  };

  const throttleFunc = throttle((e) => {
    const { type } = e;
    if (wrapperRef.current && imagesRef.current) {
      if (type === 'mouseenter') {
        setClick(false);
      } else if (type === 'mouseleave') {
        setClick(false);
        imagesRef.current.style.left = imagesRef.current.style.top = null;
      } else {
        const { clientX, clientY } = e;
        const { clientHeight, clientWidth } = imagesRef.current;
        const { top } = wrapperRef.current.getBoundingClientRect();
        imagesRef.current.style.left = clientX - clientWidth * 0.5 + 'px';
        imagesRef.current.style.top = clientY - top + 100 - clientHeight + 'px';
      }
    }
  }, 90);

  useEffect(() => {
    const images = [...new Array(quantity)].map((_, i) => loadImage(createPath(path, i, format)));
    Promise.all(images)
      .then(() => setLoad(true))
      .catch(console.log);

    const wrapperObserver = createObserver(onVisible, { threshold: 0.2 });
    wrapperObserver.observe(wrapperRef.current);
    return () => {
      wrapperObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    const intervalId =
      load && visible && !click && setInterval(() => setCount((prev) => (prev >= quantity ? 0 : prev + 1)), 200);
    return () => {
      intervalId && clearInterval(intervalId);
    };
  }, [load, visible, click]);

  useEffect(() => {
    if (click && imagesRef.current) {
      imagesRef.current.style.transform = 'scale(1.6)';
    } else if (imagesRef.current) {
      imagesRef.current.style.transform = null;
    }
  }, [click]);

  return (
    <div
      className={cl('cursor-images')}
      ref={wrapperRef}
      onMouseMove={onMouseEvent}
      onMouseLeave={onMouseEvent}
      onMouseEnter={onMouseEvent}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
    >
      {children}
      {load && (
        <div className={cl('images-wrapper')} ref={imagesRef}>
          <img src={createPath(path, count, format)} className={cl('cursor-image')} />
        </div>
      )}
    </div>
  );
};

export default CursorImages;

import styled, { css } from 'styled-components';

const Block = styled.div`
  ${({ theme: { props } }) => css`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    ${props.pos === 'right' &&
      css`
        align-items: flex-end;
        justify-content: flex-end;
      `}

    margin-left: -30px;
    margin-right: -30px;

    margin-top: 45px;
    margin-bottom: 60px;
    & + & {
      margin-top: -15px;
    }

    ${props.joinTop === true && 
      css`
        margin-top: 0;
      `
    }

    ${props.joinBottom === true && 
      css`
        margin-bottom: 0;
      `
    }

    @media (max-width: 600px) {
      margin-left: 0;
      margin-right: 0;
      display: block;
    }

    @media (max-width: 414px) {
      margin-bottom: 30px;
    }
    .heading__anchor {
      display: none;
    }
  `}
`;

Block.Item = styled.div`
  ${({ theme: { props } }) => css`
    width: ${100 / props.count}%;
    ${props.isSingle &&
      css`
        width: 50%;
      `}
    padding: 0 30px;
    flex-shrink: 0;
    @media (max-width: 600px) {
      width: 100%;
      padding: 0;
      margin-bottom: 20px;
    }
  `}
`;

export default Block;

import React, { Component } from 'react';

import { Card } from 'components';
import { classnames, classname } from 'utils';

/**
 * Карточка спецпроекта
 */
class CardSpec extends Component {
  cl = classname('card-spec');

  static propTypes = {
    ...Card.propTypes
  };

  static defaultProps = {
    type: 'Спецпроект'
  };

  render() {
    const { className, ...props } = this.props;

    const classes = classnames(className, this.cl());

    return <Card className={classes} {...props} />;
  }
}

export default CardSpec;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { classnames, classname } from 'utils';
import { Rating } from 'elements';
import './RatingList.css';

/**
 * Список рейтингов
 */
class RatingList extends Component {
  cl = classname('rating-list');

  static propTypes = {
    /** Дополнительный класс */
    className: PropTypes.string,
    /** Место */
    children: PropTypes.arrayOf(Rating.propTypes).isRequired
  };

  render() {
    const { className, children } = this.props;

    return (
      <div className={classnames(this.cl(), className)}>
        <div className={this.cl('wrapper')}>
          {children.map(item => (
            <div className={this.cl('item')} key={item.name}>
              <Rating {...item} />
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default RatingList;

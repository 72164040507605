import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { classnames, classname } from 'utils';

import './Info.css';

/**
 * Блок с информацией
 */
class Info extends Component {
  cl = classname('info');

  static propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    size: PropTypes.oneOfType(['s', 'm', 'l']),
    caption: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
  };

  static defaultProps = {
    size: 's'
  };

  render() {
    const { className, title, children, caption, size } = this.props;

    const classes = classnames(className, this.cl({ size }));

    return (
      <div className={classes}>
        {title && <div className={this.cl('title')}>{title}</div>}
        {children && <div className={this.cl('content')}>{children}</div>}
        {caption && <div className={this.cl('caption')}>{caption}</div>}
      </div>
    );
  }
}

export default Info;

import React from 'react';

export default {
  width: 82,
  height: 97,
  fill: 'none',
  content: (
    <>
      <path d="M1.10089 16.1982L68.2978 83.3959" stroke="currentColor" strokeWidth="2" />
      <path d="M71.9932 47.9285L68.2958 83.3895L29.7334 84.1511" stroke="currentColor" strokeWidth="2" fill="none" />
    </>
  )
};

import React from 'react';
import { Image, Video } from 'elements';
import Tag from './Tag';
import { clearNbsp } from '../../../../../utils/clearNbsp';

const Case = ({ item, cl, hide }) => {
  const getItemBackground = (image, video) => {
    if (video) {
      return <Video src={[video]} loop={true} autoPlay={true} />;
    } else if (image) {
      return <Image src={image} />;
    }
    return '';
  };

  const getCaseTags = (string) => {
    const tags = string.split(',').map((item) => item.trim());
    return tags.map((item, i) => <Tag cl={cl} key={'dpct' + i} item={item} />);
  };
  return (
    <a
      className={`${cl('item')} ${hide ? 'hide' : ''} ${item.theme === 'light' ? 'light' : 'dark'}`}
      href={item.link}
      title={clearNbsp(`${item.title}. ${item.description}`)}
    >
      <div className={cl('item-inner')}>
        <div className={cl('item-inner-top')}>
          <div className={cl('item-tags')}>{getCaseTags(item.tags)}</div>
          <h3 className={cl('item-title')} dangerouslySetInnerHTML={{ __html: item.title }} />
        </div>
        <div className={cl('item-inner-bottom')}>
          <div className={cl('item-description')} dangerouslySetInnerHTML={{ __html: item.description }} />
        </div>
      </div>

      <div className={cl('item-background')}>{getItemBackground(item.image, item.video)}</div>
    </a>
  );
};

export default Case;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Heading } from 'elements';
import { classnames, classname } from 'utils';

import './ContentInfo.css';

export class ContentInfo extends PureComponent {
  cl = classname('content-info');

  static propTypes = {
    /** Тема */
    theme: PropTypes.oneOf(['light', 'dark', 'green'])
  };

  static defaultProps = {
    theme: 'dark'
  };

  render() {
    const { className, title, descr, theme, label, aside, children, asideContent } = this.props;

    const classes = classnames(className, this.cl({ theme, aside: aside }));

    return (
      <div className={classes}>
        {label && !aside && <div className={this.cl('label')}>{label}</div>}
        <div className={this.cl('wrapper')}>
          {label && aside && <div className={this.cl('label')}>{label}</div>}
          {(title || descr || asideContent) && (
            <div className={this.cl('head')}>
              <div className={this.cl('title')}>
                <Heading level={1} as={'h2'}>
                  {title}
                </Heading>
              </div>
              {descr && <div className={this.cl('descr')} dangerouslySetInnerHTML={{ __html: descr }} />}
              {asideContent && <div className={this.cl('aside-content')}> {asideContent} </div>}
            </div>
          )}
          <div className={this.cl('content')}>{children}</div>
        </div>
      </div>
    );
  }
}

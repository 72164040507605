import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'components';
import { Button } from 'elements';
import { classnames, classname } from 'utils';
import './CardOffice.css';

/**
 * Карточка офиса
 */
class CardOffice extends Component {
  cl = classname('card-office');

  static propTypes = {
    city: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    postCode: PropTypes.string.isRequired,
    office: PropTypes.string.isRequired
  };

  render() {
    const { className, city, address, postCode, office, ...props } = this.props;

    const classes = classnames(className, this.cl());

    return (
      <Card className={classes} {...props}>
        {address && (
          <div className={this.cl('wrapper')}>
            <div className={this.cl('info')}>
              {postCode}, {city},<br />
              {address}, {office}
            </div>
            <Button className={this.cl('button')} size={'m'}>
              Как добраться
            </Button>
          </div>
        )}
      </Card>
    );
  }
}

CardOffice.propTypes = {
  ...Card.propTypes
};

export default CardOffice;

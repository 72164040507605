import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Block from './Ticker.style';
import { ThemeProvider } from 'styled-components';

class Ticker extends Component {
  static propTypes = {
    bgImage: PropTypes.string.isRequired,
    height: PropTypes.string
  };
  render() {
    const { bgImage, height } = this.props;
    const props = { bgImage, height };
    return (
      <ThemeProvider theme={{ props }}>
        <Block>
          <Block.Content />
        </Block>
      </ThemeProvider>
    );
  }
}

export default Ticker;

import React from 'react';
import { Icon, Email } from 'elements';

const FooterLinks = ({ data = [], cl }) => {
  return (
    <div className={cl('footer-links-wrapper')}>
      {data.map((item, i) => (
        <div key={'fl-' + i}>
          {item.link ? (
            <a className={cl('footer-link')} href={item.link} target={'_blank'}>
              <Icon name={'arrowBold'} size={1} className={'arrow-link'} />
              {item.text}
            </a>
          ) : (
            <div className={cl('footer-text')} dangerouslySetInnerHTML={{ __html: item.text }} />
          )}
          <Email email={item.email}>
            {(email) => (
              <a href={`mailto:${email}`} className={cl('email')}>
                {email}
              </a>
            )}
          </Email>
        </div>
      ))}
    </div>
  );
};

export default FooterLinks;

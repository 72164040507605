import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { Link } from 'gatsby';

import Block from './Text.style';

/**
 * Текст
 */
class Text extends PureComponent {
  static propTypes = {
    url: PropTypes.string,
    target: PropTypes.string,
    rel: PropTypes.string,
    color: PropTypes.oneOf([
      'white_500',
      'black_500',
      'black_400',
      'green_700',
      'green_600',
      'green_500',
      'green_300',
      'green_100',
      'gray_500',
      'gray_300',
      'gray_200',
      'gray_100',
      'gray_50'
    ]),
    hover: PropTypes.oneOf([
      'white_500',
      'black_500',
      'black_400',
      'green_700',
      'green_600',
      'green_500',
      'green_300',
      'green_100',
      'gray_500',
      'gray_300',
      'gray_200',
      'gray_100',
      'gray_50'
    ]),
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    size: PropTypes.oneOf(['s', 'm', 'l', 'xl']),
    onClick: PropTypes.func,
    attrs: PropTypes.object,
    disabled: PropTypes.bool,
    icon: PropTypes.oneOf([]),
    weight: PropTypes.oneOf(['normal', 'bold', 'black'])
  };

  static defaultProps = {
    attrs: {}
  };

  onClick(e) {
    const { onClick } = this.props;

    onClick(e);
  }

  render() {
    const {
      children,
      url,
      target,
      rel,
      onClick,
      size,
      color,
      hover,
      icon,
      disabled,
      weight
    } = this.props;

    const props = { color, hover, size, weight, disabled, interactive: url || onClick };
    const attrs = { ...this.props.attrs };

    if (target) {
      attrs.target = target;
    }

    if (rel) {
      attrs.rel = rel;
    }

    if (onClick) {
      attrs.onClick = e => this.onClick(e);
    }

    /** Ссылка */
    if (url) {
      if (/^\/(?!\/)/.test(url)) {
        attrs.to = url;
        attrs.as = Link;
      } else {
        attrs.href = url;
        attrs.as = 'a';
      }
    }

    /** Кнопка */
    if (onClick) {
      attrs.as = 'button';
      attrs.type = 'button';

      if (disabled) attrs.disabled = true;
    }

    /** Текст */
    return (
      <ThemeProvider theme={{ props }}>
        <Block {...attrs}>
          {typeof children === 'string' ? (
            <Block.Inner dangerouslySetInnerHTML={{ __html: children }} />
          ) : (
            <Block.Inner>{children}</Block.Inner>
          )}
        </Block>
      </ThemeProvider>
    );
  }
}

export default Text;

import React from 'react';
import { classname } from 'utils';
import { Image, Video } from 'elements';
import Tag from './components/Tag';

import './dark-banner.css';

const DarkBanner = ({ title = '', tags, team = '', description = '', image, video }) => {
  const cl = classname('dark-banner');
  const tagsArr = tags ? tags.split(',') : [];

  return (
    <div className={cl('flow')}>
      <div className={cl('wrapper')}>
        <div className={cl('title')} dangerouslySetInnerHTML={{ __html: title }} />
        <div className={cl('tags')}>
          {tagsArr.map((tag, i) => (
            <Tag key={'dt' + i} tag={tag} cl={cl} />
          ))}
        </div>
        <div className={cl('team')} dangerouslySetInnerHTML={{ __html: team }} />
        <div className={cl('description')} dangerouslySetInnerHTML={{ __html: description }} />
      </div>
      <div className={cl('background')}>
        {video ? <Video src={[video]} autoPlay={true} loop={true} /> : image ? <Image src={image} /> : ''}
      </div>
    </div>
  );
};

export default DarkBanner;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autosize from 'autosize';
import { classnames, classname } from 'utils';
import './Input.css';

/**
 * Поле ввода
 */
class Input extends Component {
  cl = classname('input');

  static propTypes = {
    /** Дополнительный класс */
    className: PropTypes.string,
    /** Тип */
    type: PropTypes.oneOf(['text', 'tel', 'email']),
    /** Значение */
    value: PropTypes.string,
    /** Имя */
    name: PropTypes.string.isRequired,
    /** Автофокус */
    autoFocus: PropTypes.bool,
    /** Многострочное поле */
    multiLine: PropTypes.bool,
    /** Событие изменения значения */
    onChange: PropTypes.func,
    /** Обязательное поле */
    isRequired: PropTypes.bool,
    /** Таб-индекс */
    tabIndex: PropTypes.number,
    /** Событие фокуса */
    onFocus: PropTypes.func,
    /** Событие потери фокуса */
    onFocusOut: PropTypes.func,
    /** Плейсхолдер */
    placeholder: PropTypes.string
  };

  static defaultProps = {
    attrs: {}
  };

  /**
   * Получение тега
   * @returns {string|(function(): *)}
   */
  getTag() {
    const { multiLine } = this.props;

    if (multiLine) {
      return 'textarea';
    } else {
      return 'input';
    }
  }

  /**
   * Событие изменения сначения
   * @param event
   */
  onChange({ target: { name, value } }) {
    const { onChange } = this.props;

    if (onChange) {
      onChange(name, value);
    }
  }

  /**
   * Событие фокуса
   * @param event
   */
  onFocus(event) {
    const { onFocus } = this.props;

    if (onFocus) {
      onFocus(event);
    }
  }

  /**
   * Событие фокуса
   * @param event
   */
  onFocusOut(event) {
    const { onFocusOut } = this.props;

    if (onFocusOut) {
      onFocusOut(event);
    }
  }

  componentDidMount() {
    const { multiLine } = this.props;

    if (multiLine) {
      autosize(this.field);
    }
  }

  render() {
    const {
      className,
      mask,
      type,
      name,
      autoFocus,
      value,
      multiLine,
      isRequired,
      tabIndex,
      placeholder,
      attrs
    } = this.props;

    const Tag = this.getTag();

    return (
      <div className={classnames(this.cl({ miltiline: multiLine }), className)}>
        <Tag
          className={this.cl('field')}
          mask={mask}
          type={type}
          name={name}
          autoFocus={autoFocus}
          value={value}
          onChange={e => this.onChange(e)}
          autoComplete={'false'}
          ref={node => (this.field = node)}
          required={isRequired}
          tabIndex={tabIndex}
          placeholder={placeholder}
          onFocus={e => this.onFocus(e)}
          onBlur={e => this.onFocusOut(e)}
          {...attrs}
        />
      </div>
    );
  }
}

export default Input;

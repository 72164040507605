import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
  color: ${({ theme: { color } }) => color.green_500};
  font-size: 17px;

  line-height: 36px;
  margin-bottom: 10px;

  @media (max-width: ${({ theme }) => `${theme.m.b_1024}px`}) {
    display: none;
  }
`;

const IndustryTags = ({ tags }) => {
  return <Wrapper>{tags.join(' / ')}</Wrapper>;
};

IndustryTags.propTypes = {
  tags: PropTypes.array
};

export default IndustryTags;

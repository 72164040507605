import React from 'react';
import { classname } from 'utils';
import Departament from './components/Departament';
import './dark-departaments.css';

const DarkDepartaments = ({ title, content = [] }) => {
  const cl = classname('dark-departaments');
  return (
    <div className={cl('wrapper')}>
      <div className={cl('inner-title')}>
        <h2 className={cl('title')} dangerouslySetInnerHTML={{ __html: title }} />
      </div>
      <div className={cl('departaments-wrapper')}>
        {content.map((departament, i) => (
          <Departament key={`departament-${i}`} departament={departament} cl={cl} />
        ))}
      </div>
    </div>
  );
};

export default DarkDepartaments;

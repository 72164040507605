import React from 'react';
import { Image } from 'elements';
import Swiper from 'react-id-swiper';

const Logos = ({ logos = [], cl }) => {
  const swiperProps = {
    slidesPerView: 'auto',
    spaceBetween: 0,
    speed: 3500,
    loop: true,
    allowTouchMove: false,
    noSwiping: false,
    autoplay: {
      delay: 0
    }
  };

  return (
    <div className={cl('logos-wrapper')}>
      <Swiper {...swiperProps}>
        {logos.map((logo, i) => (
          <Image src={logo} key={'lgi' + i} className={cl('logos-image')} original={true} />
        ))}
      </Swiper>
      {/* <div className={cl('logos-inner')}>
        {[...logos, ...logos, ...logos].map((logo, i) => (
          <Image src={logo} key={'lgi' + i} className={cl('logos-image')} original={true} />
        ))}
      </div> */}
    </div>
  );
};

export default Logos;

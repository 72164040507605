import React, { PureComponent } from 'react';

import { FaceList } from 'components';
import { Button, Image } from 'elements';
import { classnames, classname, raw } from 'utils';

import './ContentServices.css';

/**
 * Состав услуг
 */
export class ContentServices extends PureComponent {
  cl = classname('content-services');

  render() {
    const { className, data } = this.props;

    const classes = classnames(className, this.cl());

    return (
      <div className={classes}>
        {data.map(item => (
          <div className={this.cl('item')} key={item.title} >
            <div className={this.cl('title')} {...raw(item.title)} />
            <div className={this.cl('descr')} {...raw(item.descr)} />
            <Image className={this.cl('image')} src={item.image} original={true} />
            <div className={this.cl('info')}>
              {(item['label_head'] || item['label_text']) &&
                <div className={this.cl('label')}>
                  <div className={this.cl('label_head')} {...raw(item['label_head'])} />
                  <div className={this.cl('label_text')} {...raw(item['label_text'])} />
                </div>  
              }
            </div>
          </div>
        ))}
      </div>
    );
  }
}

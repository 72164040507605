import React, { PureComponent } from 'react';
import {Link } from 'gatsby'

import { FaceList } from 'components';
import { Button, Image } from 'elements';
import { classnames, classname, raw } from 'utils';

import './ListServices.css';

/**
 * Список услуг
 */
export class ListServices extends PureComponent {
  cl = classname('list-services');

  render() {
    const { className, data } = this.props;

    const classes = classnames(className, this.cl());

    return (
      <div className={classes}>
        {data.map(item => {
          const Tag = item.link  ? 'a' : 'div';
          
          return (
          <Tag href={item.link} className={this.cl('item')} key={item.title} data-hover={true}>
            <div className={this.cl('title')} {...raw(item.title)} />
            {item.subtitle && (
              <div className={this.cl('subtitle')} {...raw(item.subtitle)} />
            )}
            <div className={this.cl('descr')} {...raw(item.descr)} />
            <div className={this.cl('info')}>
              {item.label && <div className={this.cl('label')}>{item.label}</div>}
              {item.logo && <Image className={this.cl('logo')} src={item.logo} width={77} height={69} />}
              {item.people && 
                <FaceList type={'short'} flow={'row'} size={'l'}>
                  {item.people}
                </FaceList>
              }
              <Button>{item.anchor ? item.anchor : 'Подробнее'}</Button>
            </div>
          </Tag>)
        })}
      </div>
    );
  }
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, FaceList } from 'components';
import { Button, Phone, Email } from 'elements';
import { classnames, classname } from 'utils';
import './CardContact.css';

/**
 * Карточка контакта
 */
class CardContact extends Component {
  cl = classname('card-contact');

  static propTypes = {
    authors: PropTypes.arrayOf(PropTypes.string),
    phone: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired
  };

  render() {
    const { className, authors, phone, email, link, button, ...props } = this.props;

    const classes = classnames(className, this.cl());

    return (
      <Card className={classes} {...props}>
        {authors && (
          <div className={this.cl('authors')}>
            <FaceList type={'hidden'} flow={'row'}>
              {authors}
            </FaceList>
          </div>
        )}
        <div className={this.cl('footer')}>
          <div className={this.cl('info')}>
            {/* <Phone>
              {(phone) => (
                <a href={'tel:' + phone} className={this.cl('phone')}>
                  {phone}
                </a>
              )}
            </Phone> */}
            <Email email={email}>
              {(email) => (
                <a href={`mailto:${email}`} className={this.cl('email')}>
                  {email}
                </a>
              )}
            </Email>
          </div>
          <Button url={link} className={this.cl('button')} size={'m'}>
            {button}
          </Button>
        </div>
      </Card>
    );
  }
}

CardContact.propTypes = {
  ...Card.propTypes
};

export default CardContact;

import React, { useEffect, useRef, useState } from 'react';
import { classname } from 'utils';
import { Video } from 'elements';
import './dark-approach-accordeon.css';
import ApproachItem from './components/ApproachItem';

const DarkApproachAccordeon = ({ title = '', content = [], video }) => {
  const cl = classname('dark-approach-accordeon');
  const ref = useRef();
  const [active, setActive] = useState(0);

  const enterHandler = (i) => {
    setActive(i);
  };

  return (
    <div className={cl('wrapper')}>
      <div className={cl('title-wrapper')}>
        <div className={cl('title')} dangerouslySetInnerHTML={{ __html: title }} />
      </div>
      <div className={cl('content-wrapper')} ref={ref}>
        {content.map((item, i) => (
          <div
            className={`${cl('item-wrapper')}${active === i ? ' active' : ''}`}
            onMouseEnter={() => enterHandler(i)}
            key={'aac-' + i}
          >
            <ApproachItem item={item} cl={cl} active={active === i} />
          </div>
        ))}
      </div>
      <div className={cl('background')}>{video && <Video src={[video]} autoPlay={true} loop={true} />}</div>
    </div>
  );
};

export default DarkApproachAccordeon;

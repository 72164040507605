import React, { useEffect } from 'react';
import { classname, classnames } from 'utils';
import './dark-wrapper.css';
import createAnnotationListener from '../../../utils/createAnnotationsLinks';

const DarkWrapper = ({ children, className }) => {
  const cl = classname('dark-wrapper');

  /* Костыль для аннотации у ссылок */
  useEffect(() => {
    const links = [...document.querySelectorAll('[type="annotation"]')];
    links.forEach(createAnnotationListener);
  }, []);

  return (
    <div className={classnames(className, cl())}>
      {children}
      <div style={{ height: '1px' }} />
    </div>
  );
};

export default DarkWrapper;

import React from 'react';
import './Address.css';

const Address = ({ children }) => {
  return (
    <div className="contacts_address">
      <p>{children}</p>
    </div>
  );
};

export default Address;

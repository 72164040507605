import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { classname } from 'utils';
import { clearNbsp } from '../../../utils/clearNbsp';
import Tag from './components/Tag';
import './text-banner.css';

const TextBanner = ({ text, tags = [] }) => {
  const cl = classname('text_banner');
  const wrapperRef = useRef();
  const clearText = clearNbsp(text);
  const [wrapperPosition, setWrapperPosition] = useState(null);
  const [showTags, setShowTags] = useState(false);

  const tagsPositions = [
    { left: 14, bottom: 62, rotate: 20 },
    { left: 32, bottom: 88, rotate: -13 },
    { left: 75, bottom: 81, rotate: 335 },
    { left: 18, bottom: 4, rotate: -23 },
    { left: 40, bottom: 2, rotate: -23 },
    { left: 57, bottom: 30, rotate: 17 }
  ];

  const completeTags = tags.map((tag, i) => {
    return {
      text: tag,
      left: tagsPositions[i]?.left || tagsPositions[0].left,
      bottom: tagsPositions[i]?.bottom || tagsPositions[0].bottom,
      rotate: tagsPositions[i]?.rotate || tagsPositions[0].rotate
    };
  });

  const handleWrapper = (entries) => {
    entries.forEach((entry) => {
      setWrapperPosition(Math.floor(entry.intersectionRatio * 100));
    });
  };

  const createObserver = (callback, step) => {
    const threshold = [];
    for (let i = 0; i <= 1.0; i += step) {
      threshold.push(i);
    }
    const options = {
      root: null,
      rootMargin: '0px',
      threshold
    };
    return new IntersectionObserver(callback, options);
  };

  useLayoutEffect(() => {
    const wrapperObs = createObserver(handleWrapper, 0.01);
    wrapperObs.observe(wrapperRef.current);
    return () => wrapperObs.disconnect();
  }, []);

  useEffect(() => {
    wrapperPosition >= 99 && setShowTags(true);
    wrapperPosition === 0 && setShowTags(false);
  }, [wrapperPosition]);

  return (
    <div className={cl('wrapper')}>
      <div className={cl('banner')} ref={wrapperRef}>
        <div className={cl(`banner_text ${showTags ? 'active' : ''}`)}>
          {clearText}
          {completeTags.map((tag, i) => (
            <Tag key={`text_tag-${i}`} tag={tag} className={cl('tag')} show={showTags} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TextBanner;

import React from 'react';

export default {
  width: 79,
  height: 33,
  content: (
    <>
      <path d="M72 13.7H0l6.6-6.9L0 0h72l6.5 6.8-6.5 6.9z" fill="var(--color-green_500)" />
      <path
        d="M41.1 19.3l-3.5 6.3-3.6-6.3h-4.4V33h4.9v-5.5l1.6 3H39l1.6-3V33h5V19.3H41zm12.4 8l1.1-3.4 1.2 3.4h-2.3zm4.2 5.7h4.9l-4.8-13.7h-5.2L47.8 33h4.9l.4-1.6h4.1l.5 1.6zm20.8 0L74 26.2l4.6-6.9H73l-1.9 3-1.8-3h-5.6l4.7 6.9-4.7 6.8h5.6l1.8-3 1.9 3h5.5zm-58 0H25V19.3h-4.5V33zm-17 0V19.3h4l4.5 5.4v-5.4h5V33h-4l-4.5-5.3V33h-5z"
        fill="var(--color-black)"
      />
    </>
  )
};

import styled, { css } from 'styled-components';

const Block = styled.div`
  ${({ theme: { props } }) => css`
    position: relative;
    height: ${props.height};
    z-index: 10;
    overflow: hidden;
    width: 100%;
  `}
`;

Block.Content = styled.div`
  ${({ theme: { props } }) => css`
    position: absolute;
    width: 10000%;
    height: ${props.height};
    background: center center repeat-x;
    -webkit-animation: dotleft 50s linear infinite;
    -o-animation: dotleft 50s linear infinite;
    animation: dotleft 50s linear infinite;
    background-image: url(${props.bgImage});

    @-moz-keyframes dotleft {
      0% {
        left: 0;
      }
      100% {
        left: -100%;
      }
    }
    @-webkit-keyframes dotleft {
      0% {
        left: 0;
      }
      100% {
        left: -100%;
      }
    }
    @-o-keyframes dotleft {
      0% {
        left: 0;
      }
      100% {
        left: -100%;
      }
    }
    @keyframes dotleft {
      0% {
        left: 0;
      }
      100% {
        left: -100%;
      }
    }
  `}
`;

export default Block;

import React, { Component } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';

import {
  CardProject,
  CardArticle,
  CardEvent,
  CardSpec,
  CardOffice,
  CardService,
  CardJob,
  CardMore,
  CardContact,
  Card
} from 'components';
import { classnames, classname } from 'utils';

import './Feed.css';

class Feed extends Component {
  cl = classname('feed');

  constructor(props) {
    super(props);
    this.state = { filter: props.tag.length > 0, filtered: false, expand: props.expand };
  }
  static propTypes = {
    /** Дополнительный класс */
    className: PropTypes.string,
    /** Список элементов */
    children: PropTypes.arrayOf(
      PropTypes.shape({
        size: PropTypes.oneOf(['s', 'mw', 'mh', 'l']),
        content: PropTypes.node.isRequired
      }).isRequired
    ),
    /** Смешение сетки (для главной страницы) */
    main: PropTypes.bool,
    /** Количество колонок */
    columns: PropTypes.oneOf([2, 3]),
    /** Тег для фильтрации */
    tag: PropTypes.array
  };

  static defaultProps = {
    children: [],
    columns: 3,
    tag: []
  };

  expand() {
    this.setState({
      expand: false
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (JSON.stringify(this.props.tag) !== JSON.stringify(prevProps.tag)) {
      this.setState({
        filter: true,
        filtered: true
      });
      setTimeout(() => {
        this.setState({
          filtered: false
        });
      }, 600);
    }
  }

  render() {
    const { className, list, feed, main, columns, more, tag, category } = this.props;
    const { expand } = this.state;
    const classes = classnames(
      className,
      this.cl({
        main,
        columns,
        filter: this.state.filter && this.props.tag?.length > 0,
        filtered: this.state.filtered,
        category: !!category
      })
    );

    let isVisible = 0;

    function checkProjectVisibility(data) {
      if (!tag || !tag?.length) return true;

      if (data && data.tags) {
        const tags = data.tags.split(';');

        for (let tagIndex = 0; tagIndex < tags.length; tagIndex++) {
          const pageTag = tags[tagIndex];
          if (tag.find((i) => i === pageTag?.trim())) {
            return true;
          }
        }
      }
      return false;
    }

    return (
      <div className={classes}>
        {list.map((id, index) => {
          const data = feed[id];

          if (!data) return null;
          if (data.theme === 'white') data.theme = 'light';
          switch (data.category) {
            case 'projects':
              const isProjectVisible = checkProjectVisibility(data);

              if (isProjectVisible) {
                isVisible++;
              }
              return tag && (tag.length || category) ? (
                <CardProject
                  className={this.cl('item', {
                    index: isProjectVisible ? isVisible : false,
                    visible: !!isProjectVisible
                  })}
                  key={id}
                  url={data.redirect || data.uri}
                  title={data.card_title || data.title}
                  announce={data.descr}
                  image={data.image}
                  video={data.video ? data.video.split(',') : null}
                  theme={data.theme || 'dark'}
                  soon={Boolean(data.soon)}
                  tags={data.tags}
                />
              ) : !expand || (expand && index < expand) ? (
                <CardProject
                  className={this.cl('item')}
                  key={id}
                  url={data.redirect || data.uri}
                  title={data.card_title || data.title}
                  announce={data.descr}
                  image={data.image}
                  video={data.video ? data.video.split(',') : null}
                  theme={data.theme || 'dark'}
                  soon={Boolean(data.soon)}
                />
              ) : null;
            case 'articles':
              return (
                <>
                  {tag && tag.length ? (
                    <CardArticle
                      className={this.cl('item', {
                        visible:
                          data.tags &&
                          tag.some((item) => {
                            return ~data.tags
                              .split(',')
                              .map((tag) => tag.toLowerCase())
                              .indexOf(item.toLowerCase());
                          })
                      })}
                      key={id}
                      url={data.redirect || data.uri}
                      title={data.title}
                      label={data.label}
                      announce={data.descr}
                      image={data.image}
                      authors={data.authors ? data.authors.split(',') : null}
                    />
                  ) : (
                    <CardArticle
                      className={this.cl('item')}
                      key={id}
                      url={data.redirect || data.uri}
                      title={data.title}
                      label={data.label}
                      announce={data.descr}
                      image={data.image}
                      authors={data.authors ? data.authors.split(',') : null}
                    />
                  )}
                </>
              );
            case 'departments':
              return (
                <>
                  {tag && tag.length ? (
                    <CardService
                      className={this.cl('item', {
                        visible:
                          data.tags &&
                          tag.some((item) => {
                            return ~data.tags
                              .split(',')
                              .map((tag) => tag.toLowerCase())
                              .indexOf(item.toLowerCase());
                          })
                      })}
                      key={id}
                      url={data.uri}
                      title={data.title}
                      image={data.image}
                      video={data.video ? data.video.split(',') : null}
                      theme={data.theme || 'light'}
                      announce={data.descr}
                      authors={data.authors ? data.authors.split(',') : null}
                    />
                  ) : (
                    <CardService
                      className={this.cl('item')}
                      key={id}
                      url={data.uri}
                      title={data.title}
                      image={data.image}
                      video={data.video ? data.video.split(',') : null}
                      theme={data.theme || 'light'}
                      announce={data.descr}
                      authors={data.authors ? data.authors.split(',') : null}
                    />
                  )}
                </>
              );
            case 'services':
              return (
                <>
                  {tag && tag.length ? (
                    <CardService
                      className={this.cl('item', {
                        visible:
                          data.tags &&
                          tag.some((item) => {
                            return ~data.tags
                              .split(',')
                              .map((tag) => tag.toLowerCase())
                              .indexOf(item.toLowerCase());
                          })
                      })}
                      key={id}
                      url={data.uri}
                      title={data.title}
                      announce={data.descr}
                      authors={data.authors ? data.authors.split(',') : ''}
                      image={data.image}
                      video={data.video ? data.video.split(',') : null}
                    />
                  ) : (
                    <CardService
                      className={this.cl('item')}
                      key={id}
                      url={data.uri}
                      title={data.title}
                      announce={data.descr}
                      authors={data.authors ? data.authors.split(',') : ''}
                      image={data.image}
                      video={data.video ? data.video.split(',') : null}
                    />
                  )}
                </>
              );
            case 'events':
              return (
                <CardEvent
                  className={this.cl('item', { type: 'events' })}
                  {...data}
                  key={id}
                  url={data.recording || data.redirect || data.uri}
                  theme={data.theme || 'green'}
                  video={data.video ? data.video.split(',') : null}
                  authors={data.authors ? data.authors.split(',') : null}
                />
              );
            case 'spec':
              return (
                <>
                  {tag && tag.length ? (
                    <CardSpec
                      className={this.cl('item', {
                        visible:
                          data.tags &&
                          tag.some((item) => {
                            return ~data.tags
                              .split(',')
                              .map((tag) => tag.toLowerCase())
                              .indexOf(item.toLowerCase());
                          })
                      })}
                      key={id}
                      url={data.redirect || data.uri}
                      title={data.title}
                      type={data.type}
                      image={data.image}
                      theme={data.theme}
                      action={data.action}
                      caption={data.caption}
                      video={data.video ? data.video.split(',') : null}
                    />
                  ) : (
                    <CardSpec
                      className={this.cl('item')}
                      key={id}
                      url={data.redirect || data.uri}
                      title={data.title}
                      type={data.type}
                      image={data.image}
                      theme={data.theme}
                      action={data.action}
                      caption={data.caption}
                      video={data.video ? data.video.split(',') : null}
                    />
                  )}
                </>
              );
            case 'jobs':
              return (
                <>
                  {tag && tag.length ? (
                    <CardJob
                      className={this.cl('item', {
                        visible:
                          data.tags &&
                          tag.some((item) => {
                            return ~data.tags
                              .split(',')
                              .map((tag) => tag.toLowerCase())
                              .indexOf(item.toLowerCase());
                          })
                      })}
                      key={id}
                      url={data.redirect || data.uri}
                      title={data.title}
                      theme={data.theme}
                      image={data.image}
                      city={data.city}
                      address={data.address}
                    />
                  ) : (
                    <CardJob
                      className={this.cl('item')}
                      key={id}
                      url={data.redirect || data.uri}
                      title={data.title}
                      theme={data.theme}
                      image={data.image}
                      city={data.city}
                      address={data.address}
                    />
                  )}
                </>
              );
            case 'offices':
              return (
                <CardOffice
                  className={this.cl('item', { type: 'offices' })}
                  key={id}
                  url={data.redirect}
                  showBgOnHover={true}
                  title={data.city}
                  type={data.title}
                  theme={'dark'}
                  postCode={data.postCode}
                  city={data.city}
                  address={data.address}
                  office={data.office}
                  video={data.video ? data.video.split(',') : null}
                  image={data.image}
                />
              );
            case 'contacts':
              return (
                <CardContact
                  className={this.cl('item', { type: 'contacts' })}
                  key={id}
                  title={data.title}
                  theme={'dark'}
                  authors={data.authors ? data.authors.split(',') : null}
                  phone={data.phone}
                  email={data.email}
                  link={data.link}
                  button={data.button}
                />
              );
            case 'more':
              return (
                <CardMore
                  className={this.cl('item')}
                  key={id}
                  url={data.redirect || data.uri}
                  title={data.title}
                  theme={'dark'}
                  image={data.image}
                  label={data.label}
                  type={data.title || 'more'}
                />
              );
            default:
              return {
                content: <div>notfound</div>
              };
          }
        })}
        {expand && (
          <Card
            className={this.cl('item', { expand: true })}
            title="Больше проектов"
            theme={'dark'}
            image={'/departments/brands/projects-brands-more.png'}
            type={`Ещё ${list.length - expand}`}
            onClick={() => this.expand()}
            button
          />
        )}
        {/* Блок для смещения */}
        {(main || (list.length === 2 && columns === 3)) && <div className={this.cl('item', { empty: true })} />}
      </div>
    );
  }
}

export default (props) => (
  <StaticQuery
    query={graphql`
      fragment CardProject on MdxConnection {
        edges {
          node {
            frontmatter {
              title
              card_title
              image
              theme
              video
              soon
              filters
              tags
            }
            fields {
              id
              uri
              category
            }
          }
        }
      }
      fragment CardArticle on MdxConnection {
        edges {
          node {
            fields {
              id
              uri
              category
            }
            frontmatter {
              title
              descr
              label
            }
          }
        }
      }
      fragment CardJobs on MdxConnection {
        edges {
          node {
            fields {
              id
              uri
              category
            }
            frontmatter {
              title
              city
              address
              begin
            }
          }
        }
      }
      fragment CardSpec on MdxConnection {
        edges {
          node {
            fields {
              id
              uri
              category
            }
            frontmatter {
              title
              type
              theme
              image
              action
              caption
              redirect
            }
          }
        }
      }
      fragment CardJob on MdxConnection {
        edges {
          node {
            fields {
              id
              uri
              category
            }
            frontmatter {
              title
              theme
              address
              city
              image
            }
          }
        }
      }
      fragment CardService on MdxConnection {
        edges {
          node {
            fields {
              id
              uri
              category
            }
            frontmatter {
              title
              feed
              redirect
              caption
              descr
              authors
            }
          }
        }
      }
      fragment CardOffice on MdxConnection {
        edges {
          node {
            fields {
              id
              uri
              category
            }
            frontmatter {
              title
              city
              postCode
              address
              office
              video
            }
          }
        }
      }
      fragment CardContact on MdxConnection {
        edges {
          node {
            fields {
              id
              uri
              category
            }
            frontmatter {
              title
              authors
              phone
              email
              link
              button
            }
          }
        }
      }
      fragment CardEvent on MdxConnection {
        edges {
          node {
            fields {
              id
              uri
              category
            }
            frontmatter {
              begin
              end
              type
              repeat
              city
              address
              btnTitle
              theme
              recording
              topic
              authors
            }
          }
        }
      }

      query {
        feed: allMdx {
          ...CardProject
          ...CardArticle
          ...CardJob
          ...CardService
          ...CardSpec
          ...CardOffice
          ...CardContact
          ...CardEvent
        }
      }
    `}
    render={({ feed: { edges: feed } }) => {
      feed = feed.reduce(
        (list, { node }) => ({ ...list, [node.fields.id]: { ...node.frontmatter, ...node.fields } }),
        {}
      );

      return <Feed {...props} feed={feed} />;
    }}
  />
);

import React, { useRef } from 'react';
import { classnames, classname, raw } from 'utils';
import { Image, Icon } from 'elements';
import { Cursor } from 'components';
import Swiper from 'react-id-swiper';

import './SliderScrollbar.css';

function SliderScrollbar({ className, list }) {
  const params = {
    Swiper,
    slidesPerView: 'auto',
    spaceBetween: 30,
    centeredSlides: false,
    initialSlide: 0,
    slideToClickedSlide: true,
    grabCursor: true,
    watchOverflow: true,
    breakpoints: {
      768: {
        spaceBetween: 30
      },
      1280: {
        spaceBetween: 50
      }
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'progressbar'
    }
  };

  const cl = classname('slider-scrollbar');
  const classes = classnames(className, cl());

  return (
    <Cursor>
      <div className={classes}>
        <Swiper {...params}>
          {list.map((item, index) => (
            <a href={item.url} target={'_blank'} className={cl('slide')}>
              <div className={cl('image')}>
                <Image src={item.image} original={true} />
              </div>
              <div className={cl('title')} {...raw(item.title)} />
              <div className={cl('text')} {...raw(item.text)} />
            </a>
          ))}
        </Swiper>
      </div>
    </Cursor>
  );
}

export default SliderScrollbar;

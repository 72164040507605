import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { classname, classnames, raw } from 'utils';
import { Icon } from 'elements';
import './Invite.css';

/**
 * Инвайт
 */
class Invite extends Component {
  cl = classname('invite');

  static propTypes = {
    className: PropTypes.string,
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    url: PropTypes.string,
    link: PropTypes.string
  };

  render() {
    const { className, content, url, link } = this.props;

    const classes = classnames(className, this.cl());

    return (
      <div className={classes}>
        {content && <div className={this.cl('content')} {...raw(content)} />}
        <div className={this.cl('icon')}><Icon name={'arrowR'} /></div>
        {url && <a href={url} className={this.cl('url')} {...raw(link)} />}
      </div>
    );
  }
}

export default Invite;

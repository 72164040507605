import React from 'react';
import { IndustryAnimation, IndustryTags } from '../index';
import { IconWrapper, MobileWrapper, Title, Wrapper, Description, SubTitle} from './IndustryGreeting.style';
import PropTypes from 'prop-types';
import { Icon } from 'elements';
import { INDUSTRY_CATEGORIES } from '../../utils/constants';
import FadeIn from './FadeIn';


const IndustryGreeting = ({title, category, primarySubTitle, secondarySubTitle, tags}) => {

    return (
      <Wrapper>
        <Description>
          <FadeIn>
            <IndustryTags tags={tags}/>
            <Title>{title}</Title>
          </FadeIn>
          <FadeIn>
            <SubTitle primary>{primarySubTitle}</SubTitle>
            <SubTitle opacity={0.5} mobileHide>{secondarySubTitle}</SubTitle>
          </FadeIn>
          <IconWrapper mobileHide>
            <Icon name='arrowCircle' />
          </IconWrapper>
        </Description>
        <IndustryAnimation category={category} />
        <MobileWrapper>
          <IconWrapper>
            <Icon name='arrowCircle' />
          </IconWrapper>
          <SubTitle opacity={0.5}>{secondarySubTitle}</SubTitle>
        </MobileWrapper>
      </Wrapper>
    )
};

IndustryGreeting.propTypes = {
  category: PropTypes.oneOf(Object.values(INDUSTRY_CATEGORIES)),
  title: PropTypes.string,
  primarySubTitle: PropTypes.string,
  secondarySubTitle: PropTypes.string,
  tags: PropTypes.array
}

export default IndustryGreeting;

import styled from 'styled-components';

const Block = styled.div``;

Block.Image = styled.img`
  display: block;
  width: 100%;
`;

export default Block;

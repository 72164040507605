import React, { useRef, useLayoutEffect, useMemo } from 'react';
import styled from 'styled-components';

const Canvas = styled.canvas`
  width: auto;
  height: auto;
  animation: opacityAnimation;
  animation-duration: 1s;
  max-width: 100%;

  @keyframes opacityAnimation {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @media (max-width: ${({ theme }) => `${theme.m.b_1440}px`}) {
  }

  @media (max-width: ${({ theme }) => `${theme.m.b_1024}px`}) {
    width: 400px;
    height: 400px;
  }

  @media (max-width: ${({ theme }) => `${theme.m.b_425}px`}) {
    width: 300px;
    height: 300px;
  }
`;

const Animation = ({ frames }) => {
  const canvasRef = useRef(null);
  const frameCount = useMemo(() => frames.length, [frames]);

  const currentFrame = (index) => frames[index - 1];

  useLayoutEffect(() => {
    const html = document.documentElement;

    const handleScroll = () => {
      const scrollTop = html.scrollTop;
      const scrollFraction = scrollTop / (window.innerHeight / 8);
      const frameIndex = Math.min(frameCount - 1, Math.floor(scrollFraction * frameCount));
      requestAnimationFrame(() => updateImage(frameIndex + 1));
    };

    window.addEventListener('scroll', handleScroll);

    const { current: canvas } = canvasRef;
    const context = canvas.getContext('2d');

    const img = new Image();

    img.src = currentFrame(1);
    canvas.width = 800;
    canvas.height = 800;
    img.onload = function () {
      context.drawImage(img, 0, 0, 800, 800);
    };

    const updateImage = (index) => {
      img.src = currentFrame(index);
      context.drawImage(img, 0, 0, 800, 800);
    };

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return <Canvas ref={canvasRef} />;
};

export default Animation;

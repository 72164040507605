import React, { Component } from 'react';
import Block from './Progress.Style';

export default class Progress extends Component {
  constructor(props) {
    super(props);
    this.bar = React.createRef();
  }
  static defaultProps = {
    percent: 0
  };
  get dash() {
    const { percent } = this.props;
    const RADIUS = 54;
    const CIRCUMFERENCE = 2 * Math.PI * RADIUS;

    return {
      offset: CIRCUMFERENCE * (1 - percent),
      array: CIRCUMFERENCE
    };
  }
  componentDidUpdate() {
    this.setOffset();
  }
  getBar = node => {
    this.bar = node;
    this.setOffset();
  };
  setOffset = () => {
    if (this.bar) {
      this.bar.style.strokeDashoffset = this.dash.offset + 'px';
      this.bar.style.strokeDasharray = this.dash.array + 'px';
    }
  };
  render() {
    return (
      <Block.Root>
        <Block.Svg viewBox="0 0 120 120">
          <Block.Circle cx="60" cy="60" r="54" fill="none" />
          <Block.Bar ref={this.getBar} cx="60" cy="60" r="54" fill="none" />
        </Block.Svg>
      </Block.Root>
    );
  }
}

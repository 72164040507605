import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { classname, classnames } from 'utils';
import DataTable from 'react-data-table-component';
import './Table.css';

class Table extends Component {
  cl = classname('table');

  render() {

    const {columns, data} = this.props;

    columns.forEach((column, index) => {
      columns[index].wrap = true;
      columns[index].style = {};
      if(index == 0) {
        columns[index].maxWidth = '347px';
        columns[index].minWidth = '173px';
        columns[index].style.marginRight = 'calc(20px + ((30 - 20) * ((100vw - 320px) / (1920 - 320))))';
        columns[index].style.fontSize = '16px';
        columns[index].style.lineHeight = '24px';
        columns[index].style.fontWeight = 'bold';
      } else if(index > 0 && !!(index%2)) {
          columns[index].maxWidth = '340px';
          columns[index].minWidth = '170px';
          columns[index].style.marginRight = 'calc(20px + ((30 - 20) * ((100vw - 320px) / (1920 - 320))))';
          columns[index].style.fontSize = '14px';
          columns[index].style.lineHeight = '20px';
      } else if(index > 0 && !(index%2)) {
          columns[index].maxWidth = '340px';
          columns[index].minWidth = '170px';
          columns[index].style.fontSize = '14px';
          columns[index].style.lineHeight = '20px';
      }
    })

    return(
      <div className={classnames(this.cl())}>
        <DataTable 
          className={classnames(this.cl('inner'))}
          columns={columns}
          data={data}
        />
      </div>  
    )
  }
}

export default Table;
import React, { useLayoutEffect, useRef } from 'react';
import { HeadingWrapper, SpoileredWrapper, Wrapper } from './ServicesContent.Style';
import { IndustryTitles } from 'components';


const ServicesContent = ({title, children}) => {
    const wrapperRef = useRef(null);
    
    useLayoutEffect(() => {
      const {height} = wrapperRef.current.getBoundingClientRect();
      wrapperRef.current.style.height = `${parseFloat(height)}px`;
    }, [])
  
    return (
      <Wrapper>
         <HeadingWrapper>
           <IndustryTitles>{title}</IndustryTitles>
         </HeadingWrapper>
        <SpoileredWrapper ref={wrapperRef}>
          {children}
        </SpoileredWrapper>
      </Wrapper>
    );
};

export default ServicesContent;

export default [
  {
    name: 'Проекты',
    link: '/projects/'
  },
  {
    name: 'Услуги',
    type: 'services',
    children: [
      { name: 'Стратегия', link: '/strategy/', desc: 'Выбираем путь и создаем основу' },
      {
        name: 'Брендинг',
        link: '/brands/',
        desc: 'От идеи до коммуникации',
        subMenu: [
          { name: 'Фирменный стиль', link: '/corporate-identity/' },
          { name: 'Дизайн-поддержка', link: '/design-support/' },
          { name: 'Брендинг работодателя', link: '/employer-branding/' },
          { name: 'Брендинг для IT', link: '/it-branding/' },
          { name: 'Бренд-платформа', link: '/brand-platform/' },
          { name: 'Нейминг', link: '/naming/' }
        ]
      },
      {
        name: 'UX/UI-дизайн',
        link: '/ux-ui/',
        desc: 'Осмысленный и эстетичный',
        subMenu: [
          { name: 'Исследования и проектирование интерфейсов', link: '/ux-research/' },
          { name: 'UX-аудит', link: '/ux-audit/' },
          // { name: 'Дизайн интерфейсов сайтов и продуктов', link: '/' },
          { name: 'Дизайн-команды', link: '/design-team/' },
          { name: 'Сайты на Nocode-решениях', link: 'https://no-code.nimax.ru/' }
        ]
      },
      {
        name: 'Веб-разработка',
        link: '/soft/',
        desc: 'Полный цикл разработки',
        subMenu: [
          { name: 'Корпоративные сайты', link: '/corp/' },
          { name: 'Разработка сайтов и сервисов', link: '/web-services/' },
          { name: 'Техническая поддержка', link: '/support/' },
          // { name: 'Разработка мобильных приложений', link: '/' },
          // { name: 'Технологический аудит', link: '/' },
          // { name: 'DevOps-сопровождение', link: '/' },
          { name: 'Боты: Telegram / Slack', link: '/chat-bots/' }
          // { name: 'Адаптивная вёрстка', link: '/strategy/' },
        ]
      },
      {
        name: 'Performance-маркетинг',
        link: '/performance/',
        desc: 'Вдумчивый и эффективный',
        subMenu: [
          // { name: 'Growth-маркетинг', link: '/' },
          { name: 'Global лидогенерация', link: '/global-lead-generation/' },
          { name: 'SEO: поисковая оптимизация', link: '/seo/' },
          { name: 'ASO: оптимизация мобильных приложений', link: '/aso/' },
          { name: 'Контекстная реклама', link: '/context/' },
          { name: 'Таргетированная реклама', link: '/target/' }
          // { name: 'Digital-стратегия', link: '/' },
          // { name: 'Медийная и programmatic-реклама', link: '/' },
          // { name: 'Веб-аналитика', link: '/dashboards/' },
        ]
      },
      {
        name: 'Коммуникация',
        link: '/media/',
        desc: 'От SMM до креативных кампаний',
        subMenu: [
          { name: 'Инфлюенс-маркетинг', link: '/influence/' },
          { name: 'SMM-продвижение', link: '/smm/' },
          { name: 'Контент', link: '/content/' }
        ]
      }
      // {
      //   name: 'Образование',
      //   link: 'http://nimax.school/?utm_source=main_page&utm_medium=banner&utm_campaign=up_menu_btn'
      // }
    ]
  },
  {
    name: 'Отрасли',
    type: 'industry',
    children: [
      { name: 'IT', link: '/it/' },
      { name: 'Финтех', link: '/fintech/' },
      { name: 'ESG', link: '/esg/' }
    ]
  },
  {
    name: 'Компания',
    type: 'company',
    children: [
      { name: 'Постеры', link: '/posters/' },
      { name: 'Тесты, спецпроекты', link: '/spec/' },
      { name: 'События', link: '/events/' },
      { name: 'Статьи', link: '/articles/' }
    ]
  },
  {
    name: 'Курсы',
    link: 'https://school.nimax.ru'
  },
  {
    name: 'Вакансии',
    link: 'https://hr.nimax.ru'
  },
  // {
  //   name: 'Школа',
  //   link: 'https://nimax.school/'
  // },
  {
    name: 'Контакты',
    link: '/contacts/'
  }
];

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { classname, classnames, raw } from 'utils';
import './Digits.css';

/**
 * Цифры
 */
class Digits extends Component {
  cl = classname('digits');

  static propTypes = {
    /** Размер */
    className: PropTypes.string,
    /** Размер */
    size: PropTypes.oneOf(['m', 'l']),
    /** Заголовок */
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /** Содержимое */
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    /** Подпись */
    caption: PropTypes.string,
    precaption: PropTypes.string,
    /** Цвет */
    color: PropTypes.oneOf(['black', 'green', 'white'])
  };

  static defaultProps = {
    size: 'm',
    color: 'black'
  };

  render() {
    const { className, title, content, caption, size, color, precaption } = this.props;
    const classes = classnames(className, this.cl({ size, color, title }));

    let digits = content.split(',');

    return (
      <div className={classes}>
        {title && <div className={this.cl('title')} {...raw(title)} />}
        {(content || caption) &&
          <div className={this.cl('wrapper')}>
            {precaption && <div className={this.cl('caption')} {...raw(precaption)} />}
            {content && (
              digits.map((item, i) => {
                return (
                  <>
                    <div className={this.cl('content')} > {item} </div>
                    {
                      (i < digits.length - 1) ? <div className={this.cl('divider')} > , </div> : false
                    }
                  </>  
                )
              })
            )}
            {caption && <div className={this.cl('caption')} {...raw(caption)} />}
          </div>
        }
      </div>
    );
  }
}

export default Digits;

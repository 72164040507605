import { INDUSTRY_CATEGORIES } from '../../utils/constants';

const AnimationFrames = {
  [INDUSTRY_CATEGORIES.FINTECH]: [
    'https://static.nimax.ru/departments/industries/fintech/animation/1.png',
    'https://static.nimax.ru/departments/industries/fintech/animation/2.png',
    'https://static.nimax.ru/departments/industries/fintech/animation/3.png',
    'https://static.nimax.ru/departments/industries/fintech/animation/4.png',
    'https://static.nimax.ru/departments/industries/fintech/animation/5.png',
    'https://static.nimax.ru/departments/industries/fintech/animation/6.png',
    'https://static.nimax.ru/departments/industries/fintech/animation/7.png',
    'https://static.nimax.ru/departments/industries/fintech/animation/8.png',
    'https://static.nimax.ru/departments/industries/fintech/animation/9.png',
    'https://static.nimax.ru/departments/industries/fintech/animation/10.png',
    'https://static.nimax.ru/departments/industries/fintech/animation/11.png',
    'https://static.nimax.ru/departments/industries/fintech/animation/12.png',
    'https://static.nimax.ru/departments/industries/fintech/animation/13.png',
    'https://static.nimax.ru/departments/industries/fintech/animation/14.png',
    'https://static.nimax.ru/departments/industries/fintech/animation/15.png',
    'https://static.nimax.ru/departments/industries/fintech/animation/16.png',
    'https://static.nimax.ru/departments/industries/fintech/animation/17.png',
    'https://static.nimax.ru/departments/industries/fintech/animation/18.png',
    'https://static.nimax.ru/departments/industries/fintech/animation/19.png'
  ],
  [INDUSTRY_CATEGORIES.IT]: [
    'https://static.nimax.ru/departments/industries/it/animation/1.png',
    'https://static.nimax.ru/departments/industries/it/animation/2.png',
    'https://static.nimax.ru/departments/industries/it/animation/3.png',
    'https://static.nimax.ru/departments/industries/it/animation/4.png',
    'https://static.nimax.ru/departments/industries/it/animation/5.png',
    'https://static.nimax.ru/departments/industries/it/animation/6.png',
    'https://static.nimax.ru/departments/industries/it/animation/7.png',
    'https://static.nimax.ru/departments/industries/it/animation/8.png',
    'https://static.nimax.ru/departments/industries/it/animation/9.png',
    'https://static.nimax.ru/departments/industries/it/animation/10.png',
    'https://static.nimax.ru/departments/industries/it/animation/11.png',
    'https://static.nimax.ru/departments/industries/it/animation/12.png',
    'https://static.nimax.ru/departments/industries/it/animation/13.png',
    'https://static.nimax.ru/departments/industries/it/animation/14.png',
    'https://static.nimax.ru/departments/industries/it/animation/15.png',
    'https://static.nimax.ru/departments/industries/it/animation/16.png',
    'https://static.nimax.ru/departments/industries/it/animation/17.png',
    'https://static.nimax.ru/departments/industries/it/animation/18.png',
    'https://static.nimax.ru/departments/industries/it/animation/19.png'
  ]
};

export default AnimationFrames;

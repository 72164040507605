export default {
  light: {
    bg: 'white_500',
    color: 'black_500',
    button: 'black_500'
  },
  dark: {
    bg: 'black_500',
    color: 'white_500',
    button: 'green_500'
  },
  green: {
    bg: 'green_500',
    color: 'black_500',
    button: 'white_500'
  }
};

import React from 'react';
import { classname } from 'utils';
import './dark-breef.css';

const DarkBreef = ({ content = [] }) => {
  const cl = classname('dark-breef');
  return (
    <div className={cl('wrapper')}>
      <div className={cl('inner')}>
        {content.map((item, i) => (
          <a className={cl('item')} href={item.link} dangerouslySetInnerHTML={{ __html: item.title }} key={`db` + i} />
        ))}
      </div>
    </div>
  );
};

export default DarkBreef;

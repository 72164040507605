import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { classnames, classname } from 'utils';
import './Logo.css';

/**
 * Логотип
 */
class Logo extends Component {
  cl = classname('logo');

  static propTypes = {
    /** Дополнительный класс */
    className: PropTypes.string
  };

  render() {
    const { className } = this.props;

    const classes = classnames(className, this.cl());

    return (
      <Link to={'/'} className={classes}>
        <picture>
          <source
            media={'(max-width: 680px)'}
            srcSet={'/logo/logo_small.png, /logo/logo_small@2x.png 2x, /logo/logo_small@3x.png 3x'}
          />
          <img
            src="/logo/logo.png"
            srcSet={'/logo/logo@2x.png 2x, /logo/logo@3x.png 3x'}
            alt="Digital-агентство Nimax"
          />
        </picture>
      </Link>
    );
  }
}

export default Logo;

import React from 'react';
import styled from 'styled-components';
import { Wrapper } from './IndustryWrapper.styled.js';
import './industry-wrapper.css';

const IndustryWrapper = ({ children }) => {
  return <div className="industry_wrapper">{children}</div>;
};

export default IndustryWrapper;

import React, { useEffect, useRef, useState } from 'react';
import { classname } from 'utils';
import AwardItem from './components/AwardItem';
import Swiper from 'react-id-swiper';

import './main-awards.css';

const MainAwards = ({ top = [], bottom = [] }) => {
  const cl = classname('main-awards');
  const propsTop = {
    slidesPerView: 'auto',
    spaceBetween: 30,
    centeredSlides: false,
    initialSlide: 0,
    slideToClickedSlide: true,
    grabCursor: true,
    autoplay: {
      delay: 3000,
      pauseOnMouseEnter: true
    },
    loop: true,
    breakpoints: {
      768: {
        spaceBetween: 52
      },
      1080: {
        spaceBetween: 80
      },
      1920: {
        spaceBetween: 120
      }
    }
  };

  const propsBottom = {
    ...propsTop,
    autoplay: {
      delay: 3000,
      reverseDirection: true,
      pauseOnMouseEnter: true
    }
  };

  return (
    <div className={cl('wrapper')}>
      <div className={cl('inner')}>
        <Swiper {...propsTop}>
          {top.map((item, i) => (
            <div className={cl('slide')} key={`award--${i}`}>
              <AwardItem item={item} cl={cl} />
            </div>
          ))}
        </Swiper>
      </div>
      <div className={cl('inner')}>
        <Swiper {...propsBottom}>
          {bottom.map((item, i) => (
            <div className={cl('slide')} key={`award--${i}`}>
              <AwardItem item={item} cl={cl} />
            </div>
          ))}
        </Swiper>
      </div>
    </div>
  );

  // return (
  //   <div className={cl('wrapper')}>
  //     <div className={cl('inner')} style={{ right: `${shift}px` }}>
  //       {top.map((item, i) => (
  //         <AwardItem item={item} key={`award--${i}`} cl={cl} />
  //       ))}
  //     </div>
  //     <div className={cl('inner_left')} style={{ right: `-${shift}px` }}>
  //       {bottom.map((item, i) => (
  //         <AwardItem item={item} key={`award--${i}`} cl={cl} />
  //       ))}
  //     </div>
  //   </div>
  // );
};

export default MainAwards;

import React from 'react';

export const order = {
  title: 'Оставьте заявку,<br/>чтобы обсудить проект',
  descr: <>Расскажите о&nbsp;проекте и&nbsp;задайте вопросы&nbsp;&mdash; мы&nbsp;скоро ответим</>,
  contactsArr: [
    {
      type: 'email',
      value: 'order@nimax.ru'
    },
    {
      type: 'phone',
      value: '+7 (812) 385-52-06'
    },
    {
      type: 'link',
      value: 'telegramB',
      text: 'телеграм',
      link: 'https://t.me/NimaxAgencyBot',
      move: true
    },
    {
      type: 'link',
      value: 'attach',
      text: 'Скачать бриф',
      link: 'https://docs.google.com/document/d/1DzAEZN-8tCjvll_hiqYdv1bfZBx99A-SBcW-tRNrbwg'
    }
  ],
  footerLinks: [
    {
      text: 'Работа',
      link: 'https://hr.nimax.ru/',
      email: 'rabota@nimax.ru'
    },
    {
      text: 'Для сотрудничества',
      email: 'info@nimax.ru'
    }
  ],
  account: (
    <>
      Наш аккаунт-директор
      <br /> отвечает в мессенджерах
    </>
  ),
  about: 'О проекте',
  contacts: 'Ваши контакты',
  submit: <>Отправить</>,
  custom: {
    department: [
      { value: 'Комплексный', name: 'Комплексный' },
      { value: 'Брендинг', name: 'Брендинг' },
      { value: 'Веб', name: 'Веб' },
      { value: 'Продвижение', name: 'Продвижение' }
    ],
    budget: {
      full: [
        { value: '< 1 млн', name: '< 1 млн' },
        { value: '1–4 млн', name: '1–4 млн' },
        { value: '> 4 млн', name: '> 4 млн' }
      ],
      small: [
        { value: '1–2 млн', name: '1–2 млн' },
        { value: '2—5 млн', name: '2—5 млн' },
        {
          value: 'Более 5 млн',
          name: (
            <>
              <span>Более </span>5 млн
            </>
          )
        }
      ]
    },
    describe: [
      'Из какой вы&nbsp;компании, чем&nbsp;она&nbsp;занимается?',
      'С чем&nbsp;мы&nbsp;можем помочь? Как&nbsp;представляете результат?',
      'На какой срок работы и&nbsp;бюджет рассчитываете?',
      'Напишите, если удобнее общаться в&nbsp;мессенджере.'
    ]
  },
  field: {
    name: 'Имя',
    phone: '+7',
    email: 'Почта',
    descr: 'Опишите задачу',
    subscribe: (
      <>
        Я хочу получать предложения
        <br />и полезные статьи
        <br />
        от&nbsp;команды Nimax
      </>
    ),
    files: <>Прикрепить файл</>,
    agree:
      "Нажав на&nbsp;кнопку, соглашаюсь на&nbsp;обработку <a href='/privacy.pdf' target='_blank'>персональных данных</a>"
  },
  required: {
    name: <>Введите имя</>,
    phone: <>Введите телефон</>,
    email: <>Введите эл.&nbsp;почту</>,
    descr: <>Опишите задачу</>,
    append: <>Дайте согласие на&nbsp;обработку персональных данных</>
  },
  error: {
    phone: (
      <>
        Укажите телефон в&nbsp;формате <nobr>+7 812 385-52-06</nobr>
      </>
    ),
    email: <>Укажите почту в&nbsp;формате nikita@nimax.ru</>
  }
};

export const jobs = {
  title: (
    <>
      Оставьте <br />
      заявку
    </>
  ),
  descr: <>Расскажите о&nbsp;себе и&nbsp;задайте вопросы&nbsp;&mdash; мы скоро ответим.</>,
  about: <>Сопроводительное письмо</>,
  submit: <>Отправить</>,
  field: {
    name: 'Имя *',
    phone: 'Телефон *',
    email: 'Почта *',
    descr: '',
    subscribe: <>Я хочу получать полезные статьи от&nbsp;команды Nimax</>,
    files: <>Прикрепите резюме и&nbsp;портфолио</>,
    agree:
      "Нажав на&nbsp;кнопку, соглашаюсь на&nbsp;обработку <a href='/privacy.pdf' target='_blank'>персональных данных</a>"
  },
  required: {
    name: <>Введите имя</>,
    phone: <>Введите телефон</>,
    email: <>Введите эл.&nbsp;почту</>,
    append: <>Дайте согласие на&nbsp;обработку персональных данных</>
  },
  error: {
    phone: (
      <>
        Укажите телефон в&nbsp;формате <nobr>+7 812 385-52-06</nobr>
      </>
    ),
    email: <>Укажите почту в&nbsp;формате nikita@nimax.ru</>
  }
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { classnames, classname } from 'utils';
import './Steps.css';


class Steps extends Component {
  cl = classname('steps');

  static propTypes = {
    className: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.string)
  };

  render() {
    const { className, data } = this.props;

    const classes = classnames(className, this.cl());
    
    return (
        <div className={classes}>
            {
                data.map((item, i) => {
                    return (
                        <div className={this.cl('item')}>
                            <div className={this.cl('number')}>
                                {i < 9 ? `0${i+1}` : `${i+1}`}
                            </div>
                            <div className={this.cl('text')}>{item}</div>
                        </div>
                    )
                })
            }
        </div>
    );
  }
}

export default Steps;
import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { classname } from 'utils';
import Case from './components/Case';
import More from './components/More';

import './dark-cases.css';

const DarkCases = ({ feed, pinned, content, more, title, moreText }) => {
  const cl = classname('dark-cases');
  return (
    <div className={cl('wrapper')}>
      {title && (
        <div className={cl('inner-title')}>
          <div className={cl('title')} dangerouslySetInnerHTML={{ __html: title }} />
        </div>
      )}
      <div className={cl('pinned')}>
        <Case item={feed[pinned]} cl={cl} type={'sticky'} />
      </div>
      <div className={cl('all')}>
        {content.map((item, i) => (
          <Case item={feed[item]} cl={cl} key={'dc-' + i} />
        ))}
        <div className={cl('more')}>
          <More cl={cl} link={more} moreText={moreText} />
        </div>
      </div>
    </div>
  );
};

export default (props) => (
  <StaticQuery
    query={graphql`
      fragment CardProjects on MdxConnection {
        edges {
          node {
            frontmatter {
              nnx_title
              nnx_tags
              nnx_description
              nnx_video
              nnx_image
              nnx_theme
              announce_title
              redirect
            }
            fields {
              id
              uri
              category
            }
          }
        }
      }

      query {
        feed: allMdx {
          ...CardProjects
        }
      }
    `}
    render={({ feed: { edges: feed } }) => {
      feed = feed.reduce(
        (list, { node }) => ({ ...list, [node.fields.id]: { ...node.frontmatter, ...node.fields } }),
        {}
      );

      return <DarkCases {...props} feed={feed} />;
    }}
  />
);

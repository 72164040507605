import React from 'react';
import { Video } from 'elements';

const MobileBackground = ({ cl, background, children }) => {
  return (
    <div>
      {children}
      <div className={cl('cursor-images-mobile-video-wrapper')}>
        <Video src={[background]} autoPlay={true} loop={true} className={cl('cursor-images-mobile-video')} />
      </div>
    </div>
  );
};

export default MobileBackground;

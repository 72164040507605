import React from 'react';
import { classnames, classname } from 'utils';
import Item from './components/Item';
import Title from './components/Title';

import './how-we-work.css';
import getImageUrl from './utils/getImageUrl';

const HowWeWork = ({ title, data, className, mobileBg }) => {
  const cl = classname('dark-how-we-work');
  const classNames = classnames(className, cl());
  return (
    <section className={classNames}>
      {title && <Title text={title} cl={cl} />}
      <div className={cl('items-wrapper')}>
        {data.map((el, i) => (
          <Item key={'dhww-' + i} data={{ ...el, index: i + 1 }} cl={cl} />
        ))}
        <div className={cl('item-bg-mobile')} style={{ backgroundImage: `url(${getImageUrl(mobileBg)})` }} />
      </div>
    </section>
  );
};

export default HowWeWork;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Logo } from 'elements';
import { Burger } from 'components';
import { classnames, classname } from 'utils';

import './Bar.css';

/**
 * Бар приложения
 */
class Bar extends Component {
  cl = classname('bar');

  static propTypes = {
    /** Дополнительный класс */
    className: PropTypes.string
  };

  render() {
    const classes = classnames(this.cl());

    return (
      <div className={classes}>
        <Logo className={this.cl('logo')} />
        <Burger className={this.cl('burger')} />
        <div className={this.cl('lang')} />
      </div>
    );
  }
}

export default Bar;

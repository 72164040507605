import React from 'react';

const Tab = ({ item, active, setActive, cl }) => {
  const isActive = active ? 'active' : '';
  const clickHandler = () => {
    setActive(item.code);
  };
  return (
    <div className={cl('tab') + ' ' + isActive} onClick={clickHandler}>
      {item.name}
    </div>
  );
};

export default Tab;

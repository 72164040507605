import React from 'react';
import styled from 'styled-components';
import PreloadImageContainer from './PreloadImageContainer';
import AnimationFrames from './AnimationFrames';
import Animation from './Animation';
import PropTypes from 'prop-types';
import { INDUSTRY_CATEGORIES } from '../../utils/constants';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  flex: 1;
`;

const IndustryAnimation = ({category}) => {
    return (
      <Wrapper>
        <PreloadImageContainer images={AnimationFrames[category]} render={Animation} />
      </Wrapper>
    )
};

IndustryAnimation.propTypes =  {
  category: PropTypes.oneOf(Object.values(INDUSTRY_CATEGORIES)),
}

export default IndustryAnimation;

import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { classnames, classname } from 'utils';
import './SocialList.css';

/**
 * Список соц. сетей
 */
class SocialList extends Component {
  cl = classname('social-list');

  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.arrayOf(PropTypes.shape({ url: PropTypes.string, title: PropTypes.string }))
  };

  render() {
    const { className, children } = this.props;

    const classes = classnames(className, this.cl());

    return (
      <div className={classes}>
        {children.map(({ url, title }) => (
          <a href={url} target={'_blank'} className={this.cl('item')} key={title}>
            {title}
          </a>
        ))}
      </div>
    );
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      {
        site {
          siteMetadata {
            social {
              title
              url
            }
          }
        }
      }
    `}
    render={({ site }) => <SocialList {...props}>{site.siteMetadata.social}</SocialList>}
  />
);

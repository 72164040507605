import React from 'react';
import { navigate } from 'gatsby';

import { classnames } from 'utils';
import { Seo, Feed, SeoHidden, ToggleList } from 'components';
import { Button } from 'elements';
import { Hat } from 'components/Hat/Hat';

import { getFeedList, getTagsListFromFeed, protfolioFeedKeys } from './utils';

const cl = classnames('tags projects-listing');

const ProjectsTag = (props) => {
  const title = props.id.replace('tag_', '');
  const mainTag = title.toLowerCase();
  const [feed, setFeed] = React.useState(getFeedList('tags', props.feed, { keys: protfolioFeedKeys, tags: [mainTag] }));
  const [activeTags, setActiveTags] = React.useState([mainTag]);

  const id = props.id;
  const projectsList = feed.map((i) => i.id);
  // const tags = getTagsListFromFeed(feed);

  const tags = getTagsListFromFeed(getFeedList('tags', props.feed, { keys: protfolioFeedKeys, tags: [] }));
  const inactiveTags = tags.filter((t) => !activeTags.some((at) => at === t));

  const toggleListItems = () => {
    const items = [...activeTags, ...(projectsList.length === 1 ? [] : inactiveTags)];
    return [
      activeTags.length > 0 ? (
        <Button size="s" onClick={onClickReset} className="reset">
          Сбросить теги
        </Button>
      ) : null,
      ...items.map((tag, index) => (
        <Button
          size="s"
          key={tag + index}
          onClick={() => onClickTag(tag)}
          className={classnames(checkActiveTag(tag) && 'active')}
        >
          {tag}
        </Button>
      ))
    ];
  };

  function checkActiveTag(tag) {
    return !!activeTags.find((i) => i === tag);
  }

  function onClickTag(tag) {
    if (tag === mainTag) return;

    const _activeTags = (() => {
      if (activeTags.length === 1) return [tag];

      if (checkActiveTag(tag)) {
        return [...activeTags.filter((i) => i !== tag)];
      } else {
        return [tag];
        // return [...activeTags, tag];
      }
    })();

    setActiveTags(_activeTags);
    setFeed(getFeedList('tags', props.feed, { keys: protfolioFeedKeys, tags: _activeTags }));
  }

  function onClickReset() {
    // if (activeTags.length === 1) {
    // navigate('/tags/');
    // } else {
    setActiveTags([]);
    setFeed(getFeedList('tags', props.feed, { keys: protfolioFeedKeys, tags: [] }));
    // }
  }

  return (
    <div className={classnames(cl, props.className)} id={id}>
      {/**
       * SEO: Теги и H1 (скрытый)
       */}
      <div style={{ display: 'none' }}>
        <Seo title={'Проекты: ' + title + ' — Digital-агентство Nimax'} description={title} />
        <SeoHidden>
          <h1>Проекты: {title}</h1>
        </SeoHidden>
      </div>
      {/**
       * H2, он же Заголовок страницы
       */}
      <Hat title={activeTags[0] ? 'Проекты: ' + activeTags[0] : 'Все проекты'} pageType={id} as="h2" />
      {/**
       * Tags
       */}
      {/* <div className={classnames(cl, 'filter')}>
        <div className={classnames(cl, 'filter-list')}>
          <ToggleList items={toggleListItems()} minItems={inactiveTags.length < 8 ? 0 : 8} />
        </div>
      </div> */}
      {/**
       * Листинг тегов
       */}
      <div className={classnames(cl, 'projects-feed')}>
        <Feed list={[...projectsList, 'more/all']} />
      </div>
    </div>
  );
};

export default ProjectsTag;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { classnames, classname } from 'utils';
import { Image, Video } from '../../elements';
import './Gallery.css';

/**
 * Галлерея
 */
class Gallery extends Component {
  cl = classname('gallery');

  render() {
    const { className, children } = this.props;

    const classes = classnames(className, this.cl());

    return (
      <div className={classes}>
        {children.map(item => (
          <div className={this.cl('item')}>
            {/mp4/.test(item) ? 
            <Video
              src={[item]}
              autoplay={true}
              loop={true}
            />
          : <Image src={item} width={872} />}
            
          </div>
        ))}
      </div>
    );
  }
}

export default Gallery;

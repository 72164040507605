const showIndex = (index) => {
  try {
    return Math.abs(Number(index)) < 10 ? `0${index}` : index;
  } catch (error) {
    console.log(error);
    return 'err';
  }
};

export default showIndex;

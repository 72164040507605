import React, { useRef } from 'react'
import { classname, classnames } from 'utils';
import './Toggle.css';


function Toggle({className, children, button, desktop}) {
    const containerRef = useRef(null);

    const cl = classname('toggle');
    const classes = classnames(className, cl());

    function toggle() {
        if(containerRef.current.style.maxHeight && containerRef.current.style.maxHeight !== '0px') {
            containerRef.current.style.maxHeight = 0;
            containerRef.current.style.opacity = 0;
        } else {
            containerRef.current.style.maxHeight = '1000' + 'px';
            containerRef.current.style.opacity = 1;
        } 
        containerRef.current.classList.toggle('toggle__content_hide');
    }

    return (
        <div className={classes, cl({desktop: desktop})}>
            <div ref={containerRef} className={cl('content', {hide: true})}>{children}</div>
            <button className={cl('button')} onClick={toggle}>{button}</button>
        </div>
    )
};

export default Toggle;
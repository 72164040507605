import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Match } from '@reach/router';

import { classnames, classname, back } from 'utils';

import './Burger.css';

/**
 * Бургер
 */
class Burger extends Component {
  cl = classname('burger');

  static propTypes = {
    /** Дополнительный класс */
    className: PropTypes.string
  };

  renderInner() {
    return (
      <div className={this.cl('wrapper')}>
        {[1, 2, 3].map(index => (
          <div className={this.cl('line', { index })} key={index} />
        ))}
      </div>
    );
  }

  render() {
    const { className } = this.props;

    return (
      <Match path={'*'}>
        {({ match }) => {
          const isMenu = ['menu'].includes(match['*']);
          const classes = classnames(className, this.cl({ active: isMenu }));

          if (!isMenu) {
            return (
              <Link to={'/menu/'} className={classes}>
                {this.renderInner()}
              </Link>
            );
          } else {
            return (
              <button type={'button'} className={classes} onClick={() => back()}>
                {this.renderInner()}
              </button>
            );
          }
        }}
      </Match>
    );
  }
}

export default Burger;

import React from 'react';
import { Image } from 'elements';

const Slide = ({ image, cl }) => {
  const { title, subTitle, description, src } = image;

  return (
    <>
      <div className={cl('slide-image')}>
        <Image src={src} />
      </div>
      <div className={cl('slide-title')} dangerouslySetInnerHTML={{ __html: title }} />
      <div className={cl('slide-subTitle')} dangerouslySetInnerHTML={{ __html: subTitle }} />
      <div className={cl('slide-description')} dangerouslySetInnerHTML={{ __html: description }} />
    </>
  );
};

export default Slide;

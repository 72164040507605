import React from 'react';
import { Image } from 'elements';

const Rating = ({ rating, cl }) => {
  return (
    <a className={cl('rating-item')} href={rating.link}>
      <div className={cl('rating-image')}>
        <Image src={rating.image} />
      </div>
      <div className={cl('rating-position')} dangerouslySetInnerHTML={{ __html: rating.position }} />
      <div className={cl('rating-description')} dangerouslySetInnerHTML={{ __html: rating.description }} />
    </a>
  );
};

export default Rating;

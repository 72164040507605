import React from 'react';
import { Image } from 'elements';
import { clearNbsp } from '../../../../utils/clearNbsp';
const DescriptionItem = ({ item, cl }) => {
  const text = clearNbsp(item.text);

  return (
    <div className={cl('item')}>
      <div className={cl('image_wrapper')}>
        <Image src={item.image} className={cl('image')} />
      </div>
      <div className={cl('text')}>{text}</div>
    </div>
  );
};

export default DescriptionItem;

import React, { Fragment, PureComponent } from 'react';
import { ThemeProvider } from 'styled-components';
import smoothscroll from 'smoothscroll-polyfill';

import { isTouch } from 'utils';

import Theme from './Layout.Theme';

import './Layout.css';

if (process.browser) {
  NodeList.prototype.map = HTMLCollection.prototype.map = Array.prototype.map;
  NodeList.prototype.forEach = HTMLCollection.prototype.forEach = Array.prototype.forEach;

  const _wr = function(type) {
    const orig = window.history[type];
    return function() {
      const rv = orig.apply(this, arguments);
      const e = new Event(type);
      e.arguments = arguments;
      window.dispatchEvent(e);
      return rv;
    };
  };
  window.history.pushState = _wr('pushState');
  window.history.replaceState = _wr('replaceState');

  smoothscroll.polyfill();

  Element.prototype.offset = function() {
    const e = document.body.getBoundingClientRect(),
      t = this.getBoundingClientRect();

    return {
      top: t.top - e.top,
      left: t.left - e.left
    };
  };
}

/**
 * Общий лейаут
 */
class Layout extends PureComponent {
  updateHeight() {
    const isChangeTouch = isTouch && this.width === window.innerWidth;
    const isChangeNonTouch = !isTouch && this.width === window.innerWidth && this.height === window.innerHeight;

    if (isChangeNonTouch || isChangeTouch) return;

    const vw = window.innerWidth;
    const vh = window.innerHeight;

    document.documentElement.style.setProperty('--app__real-vw', String(vw) + 'px');
    document.documentElement.style.setProperty('--app__real-vh', String(vh) + 'px');

    this.width = window.innerWidth;
    this.height = window.innerHeight;

    document.documentElement.style.setProperty(
      '--app__scroll',
      String(window.innerWidth - document.body.clientWidth) + 'px'
    );
  }

  onClick(e) {
    if (e.target.closest('[data-scroll="hello"]')) {
      const scrollTop = window.innerHeight - 100;
      document.scrollingElement.scrollTop = scrollTop;
    }
  }

  /**
   * Обновляю элементы
   */
  getElements() {
    this.card = document.querySelector('[data-card]');
    this.hello = document.querySelector('[data-hello]');
  }

  componentDidMount() {
    if (window.document.hasFocus()) {
      this.updateHeight();
    } else {
      this.onFocus = () => {
        this.updateHeight();
        window.removeEventListener('blur', this.onFocus);
      };
      window.addEventListener('blur', this.onFocus);
    }
    window.addEventListener('resize', e => this.updateHeight(e));
    window.addEventListener('orientationchange', e => this.updateHeight(e));
    document.body.addEventListener('click', e => this.onClick(e));

    const ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf('safari') !== -1) {
      if (ua.indexOf('chrome') > -1) {
        document.documentElement.classList.add('app_chrome');
      } else {
        document.documentElement.classList.add('app_safari');
        document.documentElement.classList.add('app_legacy');
      }
    }
    if (ua.indexOf('Edge/')) {
      document.documentElement.classList.add('app_edge');
      document.documentElement.classList.add('app_legacy');
    }
    if (ua.indexOf('Trident/') > 0) {
      document.documentElement.classList.add('app_ie');
      document.documentElement.classList.add('app_legacy');
    }

    this.getElements();
  }

  componentDidUpdate() {
    this.getElements();
  }

  render() {
    const { children } = this.props;

    return (
      <ThemeProvider theme={Theme}>
        <Fragment>{children}</Fragment>
      </ThemeProvider>
    );
  }
}

export default Layout;

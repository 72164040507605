import React, { Component } from 'react';
import axios from 'axios';
import { Button, Heading } from 'elements';
import { classname } from 'utils';
import './ShareDownload.css';
import PropTypes from 'prop-types';

class ShareDownload extends Component {
  cl = classname('share-download');

  constructor(props) {
    super(props);

    this.state = {
      isDownload: false,
      vk: 0,
      facebook: 0,
      pinterest: 0
    };
  }

  componentDidMount() {
    if (process.browser) {
      this.getCount();
    }
  }

  getCount() {
    axios
      .get('https://api.nimax.ru/share.php', { params: { link: 'https://nimax.ru' + window.location.pathname } })
      .then(({ data: { status, data } }) => {
        if (status === 'success') {
          this.setState({
            vk: Number(data.vk),
            facebook: Number(data.facebook),
            pinterest: Number(data.pinterest)
          });
        }
      })
      .catch((err) => console.error(err));
  }

  onClick(social) {
    const { image } = this.props;

    const list = {
      vk: `http://vk.com/share.php?url=${window.location}`,
      fb: `https://www.facebook.com/sharer/sharer.php?u=${window.location}`,
      pinterest: `https://www.pinterest.ru/pin/create/button/?url=${window.location}${
        image ? `&media=https://static.nimax.ru${image}` : ''
      }`
    };

    window.open(list[social], '_blank');

    this.setState({
      isDownload: true
    });
  }

  static propTypes = {
    src: PropTypes.string.isRequired
  };

  render() {
    const { isDownload, vk, facebook, pinterest } = this.state;
    const { src, price, pay } = this.props;

    return (
      <div className={this.cl()}>
        <Heading level={4} as={'h2'} className={this.cl('heading')}>
          {pay ? 'Скачать бесплатно&nbsp;за репост или купить' : 'Скачайте плакат, поделившись страницей:'}
        </Heading>
        <div className={this.cl('wrapper')}>
          {!isDownload ? (
            <>
              {/* <div className={this.cl('item')}>
                <Button onClick={(e) => this.onClick('fb')}>
                  <div className={this.cl('inner')}>
                    Facebook
                    {facebook > 0 && <div className={this.cl('count')}>&nbsp;{facebook}</div>}
                  </div>
                </Button>
              </div> */}
              <div className={this.cl('item')}>
                <Button onClick={(e) => this.onClick('vk')}>
                  <div className={this.cl('inner')}>
                    ВКонтакте
                    {vk > 0 && <div className={this.cl('count')}>&nbsp;{vk}</div>}
                  </div>
                </Button>
              </div>
              <div className={this.cl('item')}>
                <Button onClick={(e) => this.onClick('pinterest')}>
                  <div className={this.cl('inner')}>
                    Запинить
                    {pinterest > 0 && <div className={this.cl('count')}>&nbsp;{pinterest}</div>}
                  </div>
                </Button>
              </div>
              {pay && (
                <div className={this.cl('item')}>
                  <Button url={pay} icon={'link'}>
                    Купить за {price}&thinsp;₽
                  </Button>
                </div>
              )}
            </>
          ) : (
            <Button url={'https://static.nimax.ru' + src} target={'_blank'}>
              Скачать
            </Button>
          )}
        </div>
      </div>
    );
  }
}

export default ShareDownload;

import React, { useState, useRef } from 'react'
import Swiper from 'react-id-swiper'
import { classname, classnames } from 'utils';
import './SliderStaff.css';
import {Icon, Image} from 'elements';
import {Figure} from 'components';

const imagesConformity = {
  0: 0,
  25: 1,
  50: 2,
  75: 3,
  100: 4  
}

function SliderStaff({ slider, className }) {
  const containerRef = useRef(null)
  const controlRef = useRef(null)
  const swiper = useRef(null)

  const cl = classname('slider-staff');
  const classes = classnames(cl(), className);

  const [progress, setProcess] = useState({
    currentItem: 1,
    nextItem: 2,
    currentPhoto: 0,
    nextPhoto: 4,
  })

  const params = {
    slidesPerView: 2,
    spaceBetween: 10,
    centeredSlides: true,
    initialSlide: 2,
    speed: 900,
    runCallbacksOnInit: false,
    slideToClickedSlide: true,
    grabCursor: true,
    on: {
      progress: function (curr) {
        onSwipe(curr)
      }
    },
    breakpoints: {
      768: {
        slidesPerView: 3,
        spaceBetween: 20
      },
      1280: {
        slidesPerView: 3,
        spaceBetween: 80
      }
    }
  }

  function onSwipe(curr) {
    const step = 1 / (slider.length - 1)

    const currentSection = Math.floor((curr + 0.0001) / step)
    const nextSection = currentSection + 1

    const photoRange = (curr * 100 * 8) - (100 * currentSection)
    const currentPhotoInt = round(photoRange, 25, 0)
    const nextPhotoInt = 100 - currentPhotoInt

    const obj = {
      currentItem: currentSection,
      nextItem: nextSection,
      currentPhoto: imagesConformity[currentPhotoInt],
      nextPhoto: imagesConformity[nextPhotoInt],
    }

    setProcess(obj)
  }

  function onMouseEvent (e) {
    e.persist();
    throttleFunc(e)
  }
  function onMouseDown (e) {
    if (controlRef.current) {
      controlRef.current.classList.add('up')
    }
    e.persist()
    setTimeout(() => throttleFunc(e), 100)
  }
  function onMouseUp (e) {
    if (controlRef.current) {
      controlRef.current.classList.remove('up')
    }
    e.persist()
    setTimeout(() => throttleFunc(e), 100)
  }

  function throttle(func, ms) {
    let isThrottled = false
    let savedArgs = false
    let savedThis = false
  
    function wrapper() {
  
      if (isThrottled) { // (2)
        savedArgs = arguments;
        savedThis = this;
        return;
      }
  
      func.apply(this, arguments); // (1)
  
      isThrottled = true;
  
      setTimeout(function() {
        isThrottled = false; // (3)
        if (savedArgs) {
          wrapper.apply(savedThis, savedArgs);
          savedArgs = savedThis = null;
        }
      }, ms);
    }
  
    return wrapper;
  }
  
  const throttleFunc = throttle((e) => {
    const { type } = e
    if (containerRef.current && controlRef.current) {
      if (type === 'mouseenter'){
        controlRef.current.classList.add('in')
        controlRef.current.classList.remove('out')
      } else if (type === 'mouseleave' ) {
        controlRef.current.classList.add('out')
        controlRef.current.classList.remove('in')
      } else {
        const { clientX, clientY } = e
        const { current, current: { clientHeight, clientWidth } } = containerRef
        const { current: currentControl, current: { clientHeight: clientHeightControl, clientWidth: clientWidthControl } } = controlRef
        const { left, top } = current.getBoundingClientRect();
        const heightOffset = clientHeight / 2
        const widthOffset = clientWidth / 2
        const x = clientX - left - widthOffset - (clientWidthControl / 2)
        const y = clientY - top  - heightOffset - (clientHeightControl / 2)
        currentControl.style.transform = `translate(${x}px, ${y}px`
      }
    }
  }, 90)

  function round(number, increment, offset) {
    return Math.ceil((number - offset) / increment) * increment + offset;
  }

  return (
    <div className={classes}>
      <div className={cl('slider')}
        ref={containerRef}
        onMouseMove={onMouseEvent}
        onMouseLeave={onMouseEvent}
        onMouseEnter={onMouseEvent}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
      >
        <Swiper getSwiper={(instance) => (swiper.current = instance)} {...params} >
          {slider.map((slide, index) => {
            const isLast = slide.isLast
            return (
            <div className={cl('item', {last: isLast})} key={index} >
                <div className={cl('images', {last: isLast})} >
                  {slide.images.map((image, key) => {
                    const { currentItem, nextItem, currentPhoto, nextPhoto } = progress
                    const activeItemCurrent = index === currentItem
                    const activeItemNext = index === nextItem
                    const activeImage = (activeItemCurrent && key === currentPhoto) || (activeItemNext && key === nextPhoto)
                    return (
                        <Image 
                          className={cl('image', {initial: key === slide.images.length - 1, active: activeImage})} 
                          original={true}
                          src={image.img}
                          alt={image.alt}
                          key={key}
                        />
                    )
                  })}
                </div>
                <div className={cl('content')}>
                    <div className={cl('name')}>
                        {slide.name}
                    </div>
                    <div className={cl('position')}>
                        {slide.position}
                    </div>
                </div>
              </div>
            )
          })}
        </Swiper>
        <div className={cl('cursor')} 
          ref={controlRef}
        >
          <Icon name={'arrow'} rotate={90} />
          <Icon name={'arrow'} rotate={270} />
        </div>
      </div>
    </div>
  )
}

export default SliderStaff;

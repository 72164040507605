import React from 'react';

const Tags = ({ tags, className }) => {
  const tagsArr = (tags && tags.split(',')) || [];
  return (
    <>
      {tagsArr.map((tag) => (
        <span className={className} key={`tag--${tag}`} dangerouslySetInnerHTML={{ __html: tag.trim() }} />
      ))}
    </>
  );
};

export default Tags;

import { INDUSTRY_CATEGORIES } from '../../utils/constants';

const Tickers = {
  [INDUSTRY_CATEGORIES.FINTECH]: [
    'https://static.nimax.ru/departments/industries/logos/bspb-logo.png',
    'https://static.nimax.ru/departments/industries/logos/forabank-logo.png',
    'https://static.nimax.ru/departments/industries/logos/psb-logo.png',
    'https://static.nimax.ru/departments/industries/logos/mkb-logo.png',
    'https://static.nimax.ru/departments/industries/logos/pscb-logo.png',
    'https://static.nimax.ru/departments/industries/logos/zunami-logo.png',
    'https://static.nimax.ru/departments/industries/logos/frankrg-logo.png'
  ],
  [INDUSTRY_CATEGORIES.IT]: [
    'https://static.nimax.ru/departments/industries/logos/kaspersky-logo.png',
    // 'https://static.nimax.ru/departments/industries/logos/mkb-logo.png',
    'https://static.nimax.ru/departments/industries/logos/mail-logo.png',
    'https://static.nimax.ru/departments/industries/logos/yota-logo.png',
    // 'https://static.nimax.ru/departments/industries/logos/taksovichkoff-logo.png',
    'https://static.nimax.ru/departments/industries/logos/abbyy-logo.png',
    // 'https://static.nimax.ru/departments/industries/logos/frankrg-logo.png',
    'https://static.nimax.ru/departments/industries/logos/qiwi-logo.png',
    'https://static.nimax.ru/departments/industries/logos/ibs-logo.png',
    'https://static.nimax.ru/departments/industries/logos/aviasales-logo.png'
  ],
  [INDUSTRY_CATEGORIES.LID]: [
    'https://static.nimax.ru/pages/lid/lid_logo__0.svg',
    'https://static.nimax.ru/pages/lid/lid_logo__1.svg',
    'https://static.nimax.ru/pages/lid/lid_logo__2.svg',
    'https://static.nimax.ru/pages/lid/lid_logo__3.svg',
    'https://static.nimax.ru/pages/lid/lid_logo__4.svg',
    'https://static.nimax.ru/pages/lid/lid_logo__5.svg',
    'https://static.nimax.ru/pages/lid/lid_logo__6.svg'
  ]
};

export default Tickers;

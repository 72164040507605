import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { classnames, classname } from 'utils';
import './List.css';

/**
 * Список
 */
class List extends Component {
  cl = classname('list');

  static propTypes = {
    /** Дополнительный класс */
    className: PropTypes.string,
    /** Тип списка */
    type: PropTypes.oneOf(['number', 'bullet'])
  };

  static defaultProps = {
    type: 'bullet'
  };

  render() {
    const { className, children, type } = this.props;

    return (
      <ul className={classnames(this.cl({ type: type }), className)}>
        {React.Children.map(children, child => React.cloneElement(child, { className: this.cl('item') }))}
      </ul>
    );
  }
}

export default List;

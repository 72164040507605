import React, { Component } from 'react';

import ProjectsTags from './Portfolio/ProjectsTags';
import ProjectsTag from './Portfolio/ProjectsTag';
import ProjectsPortfolio from './Portfolio/ProjectsPortfolio';
import ProjectsMain from './Portfolio/ProjectsMain';
import ProjectDepartments from './Portfolio/ProjectsDepartments';

import { Seo, Feed, SeoHidden } from 'components';
import { Hat } from 'components/Hat/Hat';
import { graphql, StaticQuery } from 'gatsby';
import { classname, classnames } from 'utils';

import './Projects.css';

const tagsImport = require('../../../content/categories/tags.json');
const projectsMeta = require('../../../projects-meta.json');
const tags = tagsImport['tags'];

/**
 * Страницы портфолио
 */

class Projects extends Component {
  cl = classname('projects');

  constructor(props) {
    super(props);

    this.state = {
      tags: [],
      isMobile: false
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  }

  handleWindowResize = () => {
    if (window.innerWidth < 950 && this.state.tags.length) {
      this.setState({ tags: this.state.tags.filter((i) => i.split('/')?.[0] === 'm') });
    } else {
      this.setState({ tags: this.state.tags.filter((i) => i.split('/')?.[0] !== 'm') });
    }
  };

  onClickTag = (value) => {
    if (this.state.tags.includes(value)) {
      this.setState({
        tags: this.state.tags.filter((item) => item !== value)
      });
    } else {
      this.setState({
        tags: [...this.state.tags, value]
      });
    }
  };

  onClickReset = () => {
    this.setState({
      tags: []
    });
  };

  render() {
    const { id, category, className, list } = this.props;

    if (category === 'projects' && id === 'projects') {
      return <ProjectsPortfolio {...this.props} />;
    }

    if (category === 'main') {
      return <ProjectsMain {...this.props} />;
    }

    if (category === 'tags_feed') {
      return <ProjectsTags {...this.props} />;
    }

    if (category === 'tag_page') {
      return <ProjectsTag {...this.props} />;
    }

    if (id === 'projects') {
      return <ProjectDepartments {...this.props} />;
    }

    return (
      <div className={classnames(this.cl(), className)}>
        <Seo
          title={projectsMeta[category]['seo_title'] || projectsMeta[category]['title']}
          description={projectsMeta[category]['description']}
        />
        {projectsMeta[category]['seo_h1'] ? (
          <>
            <Hat title={projectsMeta[category]['title']} pageType={id} tags={tags['projects']} />
            <SeoHidden>
              <h1>{projectsMeta[category]['seo_h1']}</h1>
            </SeoHidden>
          </>
        ) : (
          <Hat title={projectsMeta[category]['title']} pageType={id} as={'h1'} tags={tags['projects']} />
        )}

        <Feed
          list={[
            ...list.map((item) => {
              if (/\//.test(item)) {
                return item;
              } else {
                return [id, item].join('/');
              }
            }),
            'more/all'
          ]}
          category={false}
          tag={this.state.tags}
        />
      </div>
    );
  }
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        feed: allMdx {
          ...CardProject
        }
      }
    `}
    render={({ feed: { edges: feed } }) => {
      feed = feed.reduce(
        (list, { node }) => ({ ...list, [node.fields.id]: { ...node.frontmatter, ...node.fields } }),
        {}
      );

      return <Projects {...props} feed={feed} />;
    }}
  />
);

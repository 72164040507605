import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { classnames, classname, raw } from 'utils';
import {Icon} from 'elements'
import './Recording.css';

/**
 * Ссылка на запись мероприятия в карточке
 */
class Recording extends Component {
  cl = classname('recording');

  static propTypes = {
    /** Дополнительный класс */
    className: PropTypes.string,
    /** Содержимое */
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
  };

  render() {
    const { children, className } = this.props;

    const classes = classnames(className, this.cl());

    return  <div className={classes} >
              <div className={this.cl('button')} >
                <div className={this.cl('icon')}>
                  <Icon name={'play'} size="s" />
                </div>  
              </div>
              <div className={this.cl('text')}>
                {children}
              </div>
            </div>;
  }
}

export default Recording;

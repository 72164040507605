import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Image, Video } from 'elements';
import { classname, classnames } from 'utils';
import tokens from './Figure.tokens';
import './Figure.css';

/**
 * Группировка медиа-элементов
 */
class Figure extends Component {
  cl = classname('figure');

  static propTypes = {
    /** Дополнительный класс */
    className: PropTypes.string,
    /** Изображение */
    image: PropTypes.any,
    /** Видео */
    video: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
    /** Растягивание */
    cover: PropTypes.oneOf(['area', 'gap', 'fullscreen']),
    joinTop: PropTypes.bool,
    joinBottom: PropTypes.bool,
    btSize: PropTypes.oneOf(['s', 'm', 'l', 'fit', 'xl']),
    autoplay: PropTypes.bool
  };

  static defaultProps = {
    cover: 'area',
    autoPlay: true,
    btSize: 'l'
  };

  render() {
    let {
      className,
      image,
      video,
      caption,
      cover,
      alt,
      children,
      legend,
      autoPlay,
      joinTop,
      joinBottom,
      btSize
    } = this.props;
    if (typeof image === 'object') {
      image = image.src;
    }

    if (cover === 'cover') cover = 'fullscreen';

    return (
      <figure
        className={classnames(this.cl({ cover: cover }), className)}
        data-join-top={joinTop}
        data-join-bottom={joinBottom}
      >
        <div className={this.cl('content')}>
          {children ? (
            children
          ) : (
            <>
              <Image
                className={this.cl('image')}
                src={image}
                alt={alt}
                {...tokens[cover]}
                original={!video && /\.gif$/.test(image)}
              />
              {video && (
                <div className={this.cl('video')}>
                  <Video src={video} autoplay={autoPlay} loop={true} poster={image} muted={false} />
                </div>
              )}
            </>
          )}
        </div>
        {(caption || legend) && (
          <div className={this.cl('info')}>
            {caption && <figcaption className={this.cl('caption')}>{caption}</figcaption>}

            {legend && (
              <div className={this.cl('legend')}>
                {legend.map((item) => (
                  <div className={this.cl('mark')} style={{ '--color': `var(--color-${item.color})` }} key={item.name}>
                    {item.name}
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </figure>
    );
  }
}

export default Figure;

import React, { Component } from 'react';
import { classnames, classname } from 'utils';
import PropTypes from 'prop-types';

import './InfoList.css';

class InfoList extends Component {
  cl = classname('info-list');

  static propTypes = {
    sticky: PropTypes.bool
  };

  render() {
    const { sticky, className, children } = this.props;

    const classes = classnames(className, this.cl({ sticky }));

    return (
      <div className={classes}>
        {React.Children.map(children, item => (
          <div className={this.cl('item')}>{item}</div>
        ))}
      </div>
    );
  }
}

export default InfoList;

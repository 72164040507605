import React from 'react';
import { classnames, classname } from 'utils';
import { Icon } from 'elements';
import './select.css';

const Select = ({ onChange, options = [], className, placeholder, name }) => {
  const cl = classname('select');
  const classes = classnames(className, cl());

  const selectChangeHandler = (e) => {
    onChange(e.target.value);
  };

  return (
    <div className={classes}>
      <select className={cl('inner')} onChange={selectChangeHandler} defaultValue={placeholder}>
        <option defaultValue={placeholder} label={placeholder} hidden name={name} />
        {options.map((item, i) => (
          <option key={'sel-o' + i} value={item} label={item} className={cl('option')} />
        ))}
      </select>
      <Icon name="arrow" rotate={0} size={1} className={cl('arrow')} />
    </div>
  );
};

export default Select;

import React, { Component } from 'react';
import { Link } from 'gatsby';

import { Heading, Button } from 'elements';
import { classname } from 'utils';

import './NotFound.css';

/**
 * Страница не найдена
 */
class NotFound extends Component {
  cl = classname('notfound');

  render() {
    return (
      <div className={this.cl()}>
        <div className={this.cl('wrapper')}>
          <div className={this.cl('head')}>
            <Heading className={this.cl('heading')}>
              Такой
              <br />
              страницы нет
            </Heading>
            <Button url={'/'}>Вернитесь на главную</Button>
          </div>
          <picture>
            <source
              srcSet={require('./res/bagel.webp') + ', ' + require('./res/bagel@2x.webp') + ' 2x'}
              type={'image/webp'}
            />
            <img
              className={this.cl('bagel')}
              src={require('./res/bagel.png')}
              srcSet={require('./res/bagel@2x.png') + ' 2x'}
              loading={'lazy'}
              alt=""
            />
          </picture>
          <div className={this.cl('append')}>
            Возможно, мы ее удалили. Вернитесь <Link to={'/'}>на главную</Link> или напишите{' '}
            <a href="mailto:pr@nimax.ru">pr@nimax.ru</a>
          </div>
        </div>
        <picture>
          <source srcSet={'/bg/bg.webp'} type={'image/webp'} />
          <img className={this.cl('bg')} src={'/bg/bg.jpg'} loading={'lazy'} alt="" />
        </picture>
      </div>
    );
  }
}

export default NotFound;

import React from 'react';
import { Image } from 'elements';

const ApproachItem = ({ item, cl, active }) => {
  return (
    <>
      <div className={cl('item-num')} dangerouslySetInnerHTML={{ __html: item.num }} />
      <div className={cl('item-title')} dangerouslySetInnerHTML={{ __html: item.title }} />
      <div className={cl('item-text-wrapper')}>
        {item.texts.map((text, i) => (
          <p className={cl('item-text')} key={'it-' + item.num + i} dangerouslySetInnerHTML={{ __html: text }} />
        ))}
      </div>
      <div className={cl('arrow')} style={{ transform: `rotate(${active ? 180 : 0}deg)` }}>
        <Image src="/test/design-page/arrow-down.svg" original={true} />
      </div>
    </>
  );
};

export default ApproachItem;

import React from 'react';

export default {
  width: 28,
  height: 28,
  fill: 'none',
  content: (
    <>
      <path
        d="M12.834 5.83398L7.00065 11.2007H2.33398V16.8007H7.00065L12.834 22.1673V5.83398Z"
        stroke="#202020"
        strokeWidth="1"
        strokeLinecap="square"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M22.2492 5.75195C24.4364 7.93978 25.665 10.9067 25.665 14.0003C25.665 17.0939 24.4364 20.0608 22.2492 22.2486M18.1309 9.87029C19.2244 10.9642 19.8388 12.4477 19.8388 13.9945C19.8388 15.5412 19.2244 17.0247 18.1309 18.1186"
        stroke="#202020"
        strokeWidth="1"
        strokeLinecap="square"
        strokeLinejoin="round"
        fill="none"
      />
    </>
  )
};

import React from 'react';

export default {
  width: 28,
  height: 28,
  content: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0874 7.11134C9.81597 6.46122 9.61012 6.4365 9.19887 6.41988C9.05887 6.41178 8.90291 6.40369 8.72988 6.40369C8.19481 6.40369 7.63547 6.56009 7.29794 6.90572C6.88669 7.3255 5.86622 8.30484 5.86622 10.313C5.86622 12.3213 7.33075 14.2636 7.52828 14.5355C7.73413 14.8067 10.3836 18.9879 14.4977 20.692C17.7148 22.0253 18.6694 21.9017 19.4016 21.7455C20.4713 21.5152 21.8124 20.7246 22.15 19.7702C22.4873 18.8153 22.4873 18.0005 22.3884 17.8277C22.2897 17.6549 22.0181 17.5564 21.6068 17.3504C21.1956 17.1445 19.196 16.1569 18.8175 16.0252C18.4472 15.8854 18.0935 15.9348 17.8139 16.3299C17.4188 16.8814 17.0323 17.4412 16.7195 17.7785C16.4727 18.0418 16.0694 18.0749 15.7321 17.9349C15.2795 17.7459 14.0125 17.3009 12.4491 15.9101C11.2394 14.8321 10.4167 13.4907 10.1782 13.0874C9.93956 12.6759 10.1535 12.437 10.3427 12.215C10.5486 11.9597 10.7459 11.7788 10.9515 11.5399C11.1573 11.3013 11.2724 11.1779 11.4041 10.8979C11.5441 10.6264 11.4452 10.3464 11.3466 10.1406C11.2477 9.93431 10.425 7.92597 10.0874 7.11134ZM13.9958 0C6.27791 0 0 6.27966 0 13.9998C0 17.0614 0.987438 19.901 2.66591 22.2055L0.921375 27.4072L6.30263 25.6874C8.51594 27.1523 11.1571 28 14.0042 28C21.7221 28 28 21.7199 28 14.0002C28 6.28009 21.7221 0.0004375 14.0042 0.0004375H13.9961V0H13.9958Z"
    />
  )
};

import React, { PureComponent } from 'react';
import { navigate } from 'gatsby';
import { Button } from 'elements';
import { classnames, classname, cyrillicToTranslit } from 'utils';

import './ListTags.css';

/**
 * Список тегов
 */
export class ListTags extends PureComponent {
  cl = classname('list-tags');

  render() {
    const { className, tags } = this.props;

    const classes = classnames(className, this.cl());

    return (
      <div className={classes}>
        {tags.map(tag => (
          <div className={this.cl('tag')} key={tag}>
            <Button color={'gray_50'} size={'s'} weight={'normal'} key={tag} type={'button'} url={`/tags/${cyrillicToTranslit.transform(tag.toLowerCase(),'_')}`} >
              {tag}
            </Button>
          </div>
        ))}
      </div>
    );
  }
}

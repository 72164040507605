import React, { useState } from 'react';
import { classname } from 'utils';
import Tab from './components/Tab';
import Technology from './components/Technology';
import './dark-technologies.css';

const DEFAULT = {
  tabs: [
    {
      name: 'Фронтенд',
      code: 'frontend'
    },
    {
      name: 'Интеграции',
      code: 'integration'
    },
    {
      name: 'Бэкенд',
      code: 'backend'
    },
    // {
    //   name: 'Мобильные приложения',
    //   code: 'mobile'
    // },
    {
      name: 'Инфраструктура',
      code: 'infrastructure'
    }
  ],
  technologies: [
    {
      name: 'React',
      image: '/test/soft-page/technologies/react-icon.svg',
      imageActive: '/test/soft-page/technologies/react-icon-active.svg',
      code: 'frontend'
    },
    {
      name: 'Redux',
      image: '/test/soft-page/technologies/redux-icon.svg',
      imageActive: '/test/soft-page/technologies/redux-icon-active.svg',
      code: 'frontend'
    },
    {
      name: 'Next.js',
      image: '/test/soft-page/technologies/next-icon.svg',
      imageActive: '/test/soft-page/technologies/next-icon-active.svg',
      code: 'frontend'
    },
    {
      name: 'Vue',
      image: '/test/soft-page/technologies/vue-icon.svg',
      imageActive: '/test/soft-page/technologies/vue-icon-active.svg',
      code: 'frontend'
    },
    {
      name: 'TypeScript',
      image: '/test/soft-page/technologies/ts-icon.svg',
      imageActive: '/test/soft-page/technologies/ts-icon-active.svg',
      code: 'frontend'
    },
    {
      name: 'PHP',
      image: '/test/soft-page/technologies/php-icon.svg',
      imageActive: '/test/soft-page/technologies/php-icon-active.svg',
      code: 'backend'
    },
    {
      name: 'Python',
      image: '/test/soft-page/technologies/python-icon.svg',
      imageActive: '/test/soft-page/technologies/python-icon-active.svg',
      code: 'backend'
    },
    {
      name: 'Node JS',
      image: '/test/soft-page/technologies/node-icon.svg',
      imageActive: '/test/soft-page/technologies/node-icon-active.svg',
      code: 'backend'
    },
    {
      name: 'Laravel',
      image: '/test/soft-page/technologies/laravel-icon_1.svg',
      imageActive: '/test/soft-page/technologies/laravel-icon-active_1.svg',
      code: 'backend'
    },
    {
      name: 'Laravel Nova',
      image: '/test/soft-page/technologies/nova-icon.svg',
      imageActive: '/test/soft-page/technologies/nova-icon-active.svg',
      code: 'backend'
    },
    {
      name: 'Bitrix',
      image: '/test/soft-page/technologies/bitrix-icon.svg',
      imageActive: '/test/soft-page/technologies/bitrix-icon-active.svg',
      code: 'backend'
    },
    {
      name: 'PostgreSQL',
      image: '/test/soft-page/technologies/postgres-icon.svg',
      imageActive: '/test/soft-page/technologies/postgres-icon-active.svg',
      code: 'backend'
    },
    {
      name: 'MySQL',
      image: '/test/soft-page/technologies/mysql-icon.svg',
      imageActive: '/test/soft-page/technologies/mysql-icon-active.svg',
      code: 'backend'
    },
    {
      name: 'Redis',
      image: '/test/soft-page/technologies/redis-icon.svg',
      imageActive: '/test/soft-page/technologies/redis-icon-active.svg',
      code: 'backend'
    },
    {
      name: 'MongoDB',
      image: '/test/soft-page/technologies/mongo-db-icon.svg',
      imageActive: '/test/soft-page/technologies/mongo-db-icon-active.svg',
      code: 'backend'
    },
    {
      name: 'GitLab CI/CD',
      image: '/test/soft-page/technologies/gitlab-icon.svg',
      imageActive: '/test/soft-page/technologies/gitlab-icon-active.svg',
      code: 'infrastructure'
    },
    {
      name: 'Docker',
      image: '/test/soft-page/technologies/docker-icon.svg',
      imageActive: '/test/soft-page/technologies/docker-icon-active.svg',
      code: 'infrastructure'
    },
    {
      name: 'kubernetes',
      image: '/test/soft-page/technologies/kubernetes-icon.svg',
      imageActive: '/test/soft-page/technologies/kubernetes-icon-active.svg',
      code: 'infrastructure'
    },
    {
      name: 'helm',
      image: '/test/soft-page/technologies/helm-icon.svg',
      imageActive: '/test/soft-page/technologies/helm-icon-active.svg',
      code: 'infrastructure'
    },
    {
      name: 'Jenkins',
      image: '/test/soft-page/technologies/jenkins-icon.svg',
      imageActive: '/test/soft-page/technologies/jenkins-icon-active.svg',
      code: 'infrastructure'
    },
    {
      name: 'CircleCI',
      image: '/test/soft-page/technologies/circleci-icon.svg',
      imageActive: '/test/soft-page/technologies/circleci-icon-active.svg',
      code: 'infrastructure'
    },
    {
      name: 'PM2',
      image: '/test/soft-page/technologies/pm2-icon.svg',
      imageActive: '/test/soft-page/technologies/pm2-icon-active.svg',
      code: 'infrastructure'
    },
    {
      name: 'GraphQL',
      image: '/test/soft-page/technologies/graphql-icon.svg',
      imageActive: '/test/soft-page/technologies/graphql-icon-active.svg',
      code: 'integration'
    },
    {
      name: 'Rest API',
      image: '/test/soft-page/technologies/rest-icon.svg',
      imageActive: '/test/soft-page/technologies/rest-icon-active.svg',
      code: 'integration'
    },
    {
      name: 'SOAP',
      image: '/test/soft-page/technologies/soap-icon.svg',
      imageActive: '/test/soft-page/technologies/soap-icon-active.svg',
      code: 'integration'
    },
    {
      name: 'JSON',
      image: '/test/soft-page/technologies/json-icon.svg',
      imageActive: '/test/soft-page/technologies/json-icon-active.svg',
      code: 'integration'
    },
    {
      name: 'gRPC',
      image: '/test/soft-page/technologies/grpc-icon.svg?v=1',
      imageActive: '/test/soft-page/technologies/grpc-icon-active.svg',
      code: 'integration'
    },
    {
      name: 'Twirp',
      image: '/test/soft-page/technologies/twirp-icon.svg',
      imageActive: '/test/soft-page/technologies/twirp-icon-active.svg',
      code: 'integration'
    },
    {
      name: 'Protocol Buffers',
      image: '/test/soft-page/technologies/pb-icon.svg',
      imageActive: '/test/soft-page/technologies/pb-icon-active.svg',
      code: 'integration'
    },
    {
      name: 'Zabbix',
      image: '/test/soft-page/technologies/zabbix-icon.svg',
      imageActive: '/test/soft-page/technologies/zabbix-icon-active.svg',
      code: 'infrastructure'
    }
    // {
    //   name: 'Swift',
    //   image: '/test/soft-page/technologies/swift-icon.svg',
    //   imageActive: '/test/soft-page/technologies/swift-icon-active.svg',
    //   code: 'mobile'
    // },
    // {
    //   name: 'Kotlin',
    //   image: '/test/soft-page/technologies/kotlin-icon.svg',
    //   imageActive: '/test/soft-page/technologies/kotlin-icon-active.svg',
    //   code: 'mobile'
    // }
  ]
};

const DarkTechnologies = () => {
  const cl = classname('dark-technologies');
  const { tabs, technologies } = DEFAULT;
  const [active, setActive] = useState('frontend');
  const checkActive = (code) => (active === code ? true : false);
  const sortHandler = (a, b) => (a.name > b.name ? 1 : -1);
  return (
    <>
      <div className={cl('wrapper')}>
        <div className={cl('tabs')}>
          {tabs.map((item, i) => (
            <Tab item={item} active={checkActive(item.code)} cl={cl} key={'tab_' + i} setActive={setActive} />
          ))}
        </div>
        <div className={cl('description')}>
          <div className={cl('title')}>Технологии</div>
          <div className={cl('text')}>
            Используем востребованный стек разработки и готовы подобрать индивидуальный под конкретную задачу.
          </div>
        </div>
      </div>
      <div className={cl('tech-wrapper')}>
        {technologies.sort(sortHandler).map((item, i) => (
          <Technology item={item} cl={cl} key={'tc_' + i} active={checkActive(item.code)} />
        ))}
      </div>
    </>
  );
};

export default DarkTechnologies;

import React from 'react';
import { classnames, classname } from 'utils';
import { Image } from 'elements';

import './sertificates.css';

const Sertificates = ({ className, feed = [] }) => {
  const cl = classname('dark-performance-sertificates');
  const classNames = classnames(className, cl());
  return (
    <section className={classNames}>
      <div className={cl('wrapper')}>
        {feed.map((item, i) => (
          <div className={item.type === 'column' ? cl('item-column') : cl('item')} key={'dps-' + i}>
            <div className={cl('item-image')}>
              <Image src={item.img} />
            </div>
            {item.text && <div className={cl('item-text')} dangerouslySetInnerHTML={{ __html: item.text }} />}
          </div>
        ))}
      </div>
    </section>
  );
};

export default Sertificates;

import styled, { css } from 'styled-components';

const Block = styled.a`
  ${({ theme: { color } }) => css`
    display: block;
    padding-top: 10px;
    --link: var(--color-black);
    --link_hover: var(--color-black);
    &:nth-of-type(1) {
      border-top: 1px solid ${color.green_500};
      @media (max-width: 768px) {
        margin-top: 32px;
      }
    }

    @media (max-width: 768px) {
      &:nth-last-of-type(1) {
        margin-bottom: 32px;
      }
    }
  `}
`;

Block.Label = styled.div`
  ${({ theme: { color } }) => css`
    font-size: 13px;
    line-height: 15px;
  `}
`;

Block.Title = styled.div`
  ${({ theme: { color } }) => css`
    font-size: 18px;
    line-height: 21px;

    margin-top: 10px;
    transition-duration: 0.45s;
    transition-property: color;
    ${Block}:hover & {
      color: ${color.green_500};
      transition-duration: 0.15s;
    }
  `}
`;

Block.Image = styled.div`
  margin-top: 15px;
  margin-bottom: 20px;
`;

Block.Time = styled.div`
  font-size: 12px;
  margin-top: 5px;
`;

export default Block;

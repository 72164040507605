import React, { Component } from 'react';
import { navigate } from '@reach/router';
import { LocationProvider, Match } from '@reach/router';

import Layout from 'components/Layout/Layout';
import { Header } from 'components/Header';
import { Footer } from 'components/Footer';
import Order from 'components/Order/Order';
import FormStatus from 'components/FormStatus/FormStatus';
import { classnames, classname, refEmail } from 'utils';

import './App.css';

export default class App extends Component {
  cl = classname('app');

  componentDidMount() {
    if (!process.browser) return;

    refEmail();

    this.event = (event) => this.onClick(event);
    document.body.addEventListener('click', this.event);

    document.documentElement.removeAttribute('data-loading');

    LocationProvider.defaultProps.history.listen(() => {
      setTimeout(() => this.goToHash());
    });

    this.goToHash();
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.event);
  }

  goToHash = () => {
    if (window.location.hash) {
      const anchor = document.querySelector(decodeURI(window.location.hash));

      if (anchor) {
        document.scrollingElement.scrollTop = anchor.offset().top;
      }
    }
  };

  onClick = (event) => {
    const link = event.target.closest('a');
    if (link && link.target !== '_self') {
      event.preventDefault();

      const href = link.getAttribute('href');
      const target = link.getAttribute('target');

      if (target === '_blank') {
        window.open(link.href);
      } else if (target === '_self') {
        navigate(href);
      } else {
        if (href && href[0] !== '#') {
          if (href[0] === '/') {
            navigate(href);
          } else {
            window.open(href);
          }
        }
      }
    }
  };

  render() {
    const {
      className,
      path,
      children,
      pageContext: { header, category }
    } = this.props;
    const classes = classnames(this.cl('container'), className);
    const overlay = header && header.type ? ['main', 'light'].includes(header.type) : false;
    const url = this.props['*'];
    const logo = header && header.logo && header.logo;

    return (
      <Match path={'*'}>
        {({ match }) => {
          const isOrder = ['order', 'contacts', 'menu', '404', 'order-success'].includes(match['*']);
          const budget = {
            strategy: null,
            brands: null,
            performance: null,
            media: null,
            dashboards: null,
            sites: 'small',
            services: 'small'
          };

          return (
            <Layout>
              <div className={classes}>
                <Header
                  className={this.cl('header')}
                  theme={overlay ? 'light' : 'dark'}
                  path={path}
                  type={header ? header.type : null}
                  logo={logo}
                  headerColor={header?.headerColor}
                />
                <div className={this.cl('flow')}>{children}</div>
                {!isOrder &&
                  (category !== 'jobs' ? (
                    //   url in budget ? (
                    //     <Order budget={budget[url]} />
                    //   ) : (
                    <Order department={true} />
                  ) : (
                    // )
                    <Order title={'Отправить<br />резюме'} action={'jobs'} />
                  ))}

                <Footer className={this.cl('footer')} />
              </div>
            </Layout>
          );
        }}
      </Match>
    );
  }
}

import React from 'react';
import { classnames } from 'utils';
import { Seo, Feed, SeoHidden, ToggleList } from 'components';
import { Button } from 'elements';
import { Hat } from 'components/Hat/Hat';

import { getFeedList, getMoreId, portfolioFilters, protfolioFeedKeys } from './utils';

const cl = classnames('projects-listing');
const ProjectsPortfolio = (props) => {
  const [filters, setFilters] = React.useState([]);
  const [activeFilters, setActiveFilters] = React.useState([]); // string[]
  const [feed, setFeed] = React.useState(getFeedList('portfolio', props.feed, { keys: protfolioFeedKeys }));

  const id = props.id;
  const projectsList = feed.map((i) => i.id);

  const toggleListItems = (items) => {
    return [
      ...items.map((tag) => (
        <Button
          size="s"
          key={tag}
          onClick={() => onClickTag(tag)}
          className={classnames(checkActiveTag(tag) && 'active')}
        >
          {tag.replace('m/', '')}
        </Button>
      )),
      activeFilters.length ? (
        <Button size="s" onClick={onClickReset} className="reset">
          Сбросить фильтр
        </Button>
      ) : null
    ];
  };

  function checkActiveTag(tag) {
    return !!activeFilters.find((i) => i === tag);
  }

  function updateFilters() {
    setFilters(() => {
      if (window.innerWidth < 950) {
        return portfolioFilters.filter((i) => i.split('/')?.[0] === 'm');
      } else {
        return portfolioFilters.filter((i) => i.split('/')?.[0] !== 'm');
      }
    });
  }

  function onClickTag(tag) {
    setActiveFilters((prev) => {
      if (window.innerWidth < 950) {
        return [tag];
      }
      if (checkActiveTag(tag)) {
        return prev.filter((i) => i !== tag);
      } else {
        return [
          // ...prev,
          tag
        ];
      }
    });
  }

  function onClickReset() {
    setActiveFilters([]);
  }

  const handleWindowResize = () => {
    updateFilters();

    setActiveFilters((prev) => {
      if (prev.length) return [];
      return prev;
    });
  };

  function updateFeed() {
    setFeed(getFeedList('portfolio', props.feed, { filters: activeFilters, keys: protfolioFeedKeys }));
  }

  React.useEffect(() => {
    updateFeed();
  }, [activeFilters]);

  React.useEffect(() => {
    updateFilters();
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    <div className={classnames(cl, props.className)} id={id}>
      {/**
       * SEO: Теги и H1 (скрытый)
       */}
      <div style={{ display: 'none' }}>
        <Seo
          title="Проекты агентства Nimax"
          description="Проекты digital-агентства Nimax. Кейсы по стратегии, брендингу, веб-разработке, интернет-маркетингу, SMM, SEO, контекстной и таргетированной рекламе."
        />
        <SeoHidden>
          <h1>Кейсы digital-агентства Nimax</h1>
        </SeoHidden>
      </div>

      {/**
       * H2, он же Заголовок страницы
       */}
      <Hat title="Проекты" pageType={id} as="h2" />
      {/**
       * filters
       */}
      <div className={classnames(cl, 'filter')}>
        <div className={classnames(cl, 'filter-list')}>
          <ToggleList items={toggleListItems(filters)} />
        </div>
      </div>
      {/**
       * Листинг проектов
       */}
      <div className={classnames(cl, 'projects-feed')}>
        <Feed list={[...projectsList]} />
      </div>
    </div>
  );
};

export default ProjectsPortfolio;

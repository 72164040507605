import LazyLoad from 'vanilla-lazyload';

if (process.browser && !window.document.lazyLoadInstance) {
  window.document.lazyLoadInstance = new LazyLoad({
    elements_selector: '.lazy',
    class_loading: 'lazy_loading',
    class_loaded: 'lazy_loaded',
    class_error: 'lazy_error',
    threshold: 1000
  });
}

export default process.browser ? window.document.lazyLoadInstance : {};

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Heading, Email, Phone } from 'elements';
import { classnames, classname, raw } from 'utils';

import './Hello.css';

/**
 * Шапка страницы
 */
class Hello extends PureComponent {
  cl = classname('hello');

  static propTypes = {
    /** Дополнительный класс */
    className: PropTypes.string,
    /** Заголовок */
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    /** Размер заголовка */
    level: PropTypes.oneOf([1, 2, 3]),
    /** Уровень */
    as: PropTypes.string,
    /** Описание */
    descr: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /** На весь экран */
    fullscreen: PropTypes.bool,
    /** Видео */
    video: PropTypes.array,
    /** Тема */
    theme: PropTypes.oneOf(['light', 'dark', 'green']),
    /** Тип страницы */
    pageType: PropTypes.string,
  };

  static defaultProps = {
    theme: 'dark',
    level: 1
  };

  componentDidMount() {
    if (process.browser && this.video) {
      this.observer = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting) {
          this.play();
        } else {
          this.video.pause();
        }
      });

      this.observer.observe(this.video);
    }
  }

  componentWillUnmount() {
    if (process.browser && this.video) {
      this.observer.disconnect();
    }
  }

  play() {
    this.video.play().catch(err =>
      setTimeout(() => {
        if (!this.video) return;

        this.play();
      }, 1000)
    );
  }

  render() {
    const { className, title, descr, fullscreen, video, theme, level, as, pageType } = this.props;

    const classes = classnames(className, this.cl({ fullscreen, theme }));

    return (
      <div className={classes} ref={node => (this.block = node)}>
        <div className={this.cl('outer')}>
          <div className={this.cl('inner')}>
            <Heading level={level} as={as}>{title}</Heading>
            <div className={this.cl('append')}>
              {descr ? (
                <div className={this.cl('descr')} {...raw(descr)} />
              ) : (
                <>
                  <Phone>
                    {phone => (
                      <a href={'tel:' + phone} className={this.cl('phone')}>
                        {phone}
                      </a>
                    )}
                  </Phone>
                  <Email>
                    {email => (
                      <a href={`mailto:${pageType == 'jobs' ? 'rabota@nimax.ru' : email}`} className={this.cl('email')}>
                        {pageType == 'jobs' ? 'rabota@nimax.ru' : email}
                      </a>
                    )}
                  </Email>
                </>
              )}
            </div>
          </div>
        </div>
        {fullscreen && (
          <div className={this.cl('contacts')}>
            <a href="tel:+78123855206" className={this.cl('phone')}>
              +7 (812) 385-52-06
            </a>
            <Email>
              {email => (
                <a href={`mailto:${pageType == 'jobs' ? 'rabota@nimax.ru' : email}`} className={this.cl('email')}>
                  {pageType == 'jobs' ? 'rabota@nimax.ru' : email}
                </a>
              )}
            </Email>
          </div>
        )}

        {video && (
          <video
            loop={true}
            className={this.cl('bg')}
            ref={node => (this.video = node)}
            playsInline={true}
            loading={'lazy'}
          >
            {video.map(item => (
              <source key={item} src={'https://static.nimax.ru' + item} type={'video/' + item.split('.').pop()} />
            ))}
          </video>
        )}
      </div>
    );
  }
}

export default Hello;

import React, { Component } from 'react';
import { classnames, classname, raw } from 'utils';
import {Video} from 'elements';
import './ContentShowreel.css';
import MagneticButton from '../MagneticButton/MagneticButton';

class ContentShowreel extends Component {
  cl = classname('content-showreel');

  render() {

    const { className, src, poster, text} = this.props;

    const classes = classnames(className, this.cl());

    return (
      <div className={classes}>
        <div className={this.cl('video')} >
          <Video src={[src]} poster={poster} autoplay={true} loop={true} />
        </div>
        <div className={this.cl('text-wrapper')}>
          <MagneticButton border filled>
            <div className={this.cl('text')} {...raw(text)} />
          </MagneticButton>
        </div>
      </div>
  )};
};

export default ContentShowreel;
import React, { Component } from 'react';
import { classname, classnames } from 'utils';
import { Hat } from 'components/Hat/Hat';
import { Feed, Seo, SeoHidden } from 'components';
import { Helmet } from 'react-helmet';

import './Contacts.css';

const SCHEMA = {
  '@context': 'https://schema.org',
  '@type': 'Organization',
  url: 'https://www.nimax.ru',
  logo: 'https://www.nimax.ru/logo/logo_light.svg',
  address: {
    '@type': 'PostalAddress',
    addressCountry: 'Россия',
    addressLocality: 'Санкт-Петербург',
    postalCode: '190005',
    streetAddress: 'Московский проспект, д. 55, 3&nbsp;этаж'
  },
  email: 'hello@nimax.ru',
  name: 'Nimax',
  telephone: '+7 (812) 604-83-56',
  image: [
    'https://www.nimax.ru/img/schema/image0.jpeg',
    'https://www.nimax.ru/img/schema/image1.jpeg',
    'https://www.nimax.ru/img/schema/image2.jpeg'
  ]
};

class Contacts extends Component {
  cl = classname('contancts');

  render() {
    const { list, feed, className } = this.props;
    return (
      <>
        <Helmet>
          <script type="application/ld+json">{`${JSON.stringify(SCHEMA)}`}</script>
        </Helmet>
        <div className={classnames(this.cl(), className)}>
          <Seo title={'Контакты агентства Nimax'} description={'Адрес, телефон и почта агентства Nimax. '} />
          <Hat title={'Контакты <br />Nimax'} office={true} />
          <Feed list={list} feed={feed} />
          <SeoHidden>
            <h1>Контакты</h1>
          </SeoHidden>
        </div>
      </>
    );
  }
}

export default Contacts;

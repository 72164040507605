import PropTypes from 'prop-types';
import React from 'react'
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  ${({ type, height }) => type === 'margin' 
    ? css`
      margin-top: ${height}px;
      @media (max-width: 1024px) {
        margin-top: 25px;
      }
    `
    : css`
      padding-top: ${height}px;
      @media (max-width: 1024px) {
        padding-top: 25px;
      }
    `}
`;

const Divider = ({ height = 0, type = 'margin' }) => {
  return (
    <Wrapper type={type} height={height}/>
  )
}

Divider.propTypes = {
  height: PropTypes.number,
  type: PropTypes.oneOf(['margin', 'padding'])
}



export default Divider;

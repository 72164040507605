import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Image } from 'elements';
import { classname, classnames } from 'utils';
import tokens from './Face.tokens';
import './Face.css';

/**
 * Превью
 */
class Face extends Component {
  cl = classname('face');

  static propTypes = {
    /** Дополнительный класс */
    className: PropTypes.string,
    /** Фотография */
    image: PropTypes.string,
    /** Имя */
    title: PropTypes.string.isRequired,
    /** Должность (подпись) */
    post: PropTypes.oneOfType([PropTypes.string]).isRequired,
    /** Размер */
    size: PropTypes.oneOf(['s', 'm', 'l']),
    /** Тип */
    type: PropTypes.oneOf(['default', 'short', 'hidden']),
    /** Тема */
    theme: PropTypes.oneOf(['dark', 'light']),
    /** Сторис */
    stories: PropTypes.bool
  };

  static defaultProps = {
    theme: 'dark',
    type: 'default',
    stories: false,
    size: 's'
  };

  /**
   * Ховер
   */
  onMouseEnter() {
    if (this.props.type !== 'hidden') return;
    this.block.style.width = this.info.clientWidth + 50 + 'px';
  }

  /**
   * Потеря ховера
   */
  onMouseLeave() {
    if (this.props.type !== 'hidden') return;
    this.block.style.width = null;
  }

  render() {
    const { className, image, type, size, title, post } = this.props;

    return (
      <div
        className={classnames(this.cl({ size, type }), className)}
        ref={node => (this.block = node)}
        onMouseLeave={e => this.onMouseLeave(e)}
        onMouseEnter={e => this.onMouseEnter(e)}
      >
        {image && (
          <div className={this.cl('photo')}>
            <Image src={image} alt={title} {...tokens.image[size]} />
          </div>
        )}
        {type !== 'short' && (
          <div className={this.cl('info')} ref={node => (this.info = node)}>
            <div className={this.cl('title')}>{title}</div>
            <div className={this.cl('post-list')}>
              { 
                post.split('/').map((item, index) => <div className={this.cl('post')} dangerouslySetInnerHTML={{__html: item }} key={`post-${index}`}  />)
              }
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Face;

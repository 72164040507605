import { useState, useEffect, useMemo } from 'react';

const checkWindow = (size) => {
  if (typeof window !== 'undefined') {
    return window.innerWidth >= size ? true : false;
  } else {
    return true;
  }
};

const useScreenSizeCheck = (size = 0) => {
  const [check, setCheck] = useState(checkWindow(size));

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleResize = () => {
        setCheck(window.innerWidth >= size ? true : false);
      };
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [size]);

  return useMemo(() => check, [check]);
};

export default useScreenSizeCheck;

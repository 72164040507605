import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { classnames, classname } from 'utils';
import { Card } from 'components';
import { Button } from 'elements';
import './CardJob.css';

/**
 * Карточка вакансии
 */
class CardJob extends Component {
  cl = classname('card-job');

  static propTypes = {
    ...Card.propTypes,
    /** Город */
    city: PropTypes.string,
    /** Адрес */
    address: PropTypes.string
  };

  static defaultProps = {
    theme: 'dark',
    type: 'Вакансия'
  };

  render() {
    const { className, city, address, ...props } = this.props;

    const classname = classnames(className, this.cl());

    return (
      <Card {...props} className={classname}>
        <div className={this.cl('wrapper')}>
          <div className={this.cl('info')}>
            {city && (
              <>
                {city},<br />
              </>
            )}
            {address}
          </div>
        </div>
      </Card>
    );
  }
}
export default CardJob;
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { classnames, classname } from 'utils';

import { Icon, Heading, Phone, Email } from 'elements';

import './OrderSuccess.css';

/**
 * Статус формы
 */
class OrderSuccess extends Component {
  cl = classname('order-success');

  static propTypes = {
    /** Дополнительный класс */
    className: PropTypes.string
  };

  render() {
    const { className } = this.props;

    const classes = classnames(className, this.cl());

    return (
      <div className={classes}>
        <div className={this.cl('area')}>
          <div className={this.cl('window')}>
            <div className={this.cl('inner')}>
              <div className={this.cl('left')}>
                <div className={this.cl('left-inner')}>
                  <Heading level={1} className={this.cl('title')}>
                    Спасибо! Наши менеджеры свяжутся с&nbsp;вами как можно скорее!
                  </Heading>
                  <div className={this.cl('caption')}>
                    А пока вы можете посмотреть наши кейсы, статьи, <br />
                    или скачать
                    <a href="https://static.nimax.ru/docs/Nimax_Presentation_2022.pdf" className={this.cl('link')}>
                      <Icon name={'attach'} size={1} />
                      презентацию об агентстве.
                    </a>
                  </div>
                </div>
                {/* <div className={this.cl('download')}>
                  <a href="https://static.nimax.ru/docs/Nimax_Presentation_2021.pdf" className={this.cl('link')}>
                    <div className={this.cl('icon')}>
                      <Icon name="pdf" />
                    </div>
                    <div className={this.cl('text')}>Презентация</div>
                  </a>
                  <a href="https://static.nimax.ru/docs/Nimax_Agreement_2021.pdf" className={this.cl('link')}>
                    <div className={this.cl('icon')}>
                      <Icon name="pdf" />
                    </div>
                    <div className={this.cl('text')}>Шаблон договора</div>
                  </a>
                </div> */}
              </div>
              <div className={this.cl('right')}>
                <div className={this.cl('process')}>
                  <h3>Как будет строиться процесс:</h3>
                  <ul>
                    <li>Свяжемся с вами для уточнения деталей проекта и сориентируем по стоимости</li>
                    <li>Проведем брифинг или попросим заполнить бриф</li>
                    <li>Подготовим предложение и презентуем</li>
                    <li>Запланируем старт работ и подготовим документы</li>
                  </ul>
                </div>
                {/* <div className={this.cl('contacts')}>
                  <Phone>
                    {(phone) => (
                      <a href={'tel:' + phone} className={this.cl('phone')}>
                        {phone}
                      </a>
                    )}
                  </Phone>
                  <Email>
                    {(email) => (
                      <a href={`mailto:${email}`} className={this.cl('email')}>
                        {email}
                      </a>
                    )}
                  </Email>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OrderSuccess;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';

import Block from './Columns.style';
import './Columns.css';

/**
 * Колонки
 */
class Columns extends PureComponent {
  static propTypes = {
    children: PropTypes.array.isRequired,
    isSingle: PropTypes.bool,
    pos: PropTypes.oneOf(['left', 'right'])
  };

  render() {
    const { children, isSingle, pos, count, joinBottom, joinTop } = this.props;
    const props = { count: count ? count : children.length, isSingle, pos, joinBottom, joinTop };

    return (
      <ThemeProvider theme={{ props }}>
        <Block>
          {Array.isArray(children)
            ? children.map((content, index) => <Block.Item key={index}>{content}</Block.Item>)
            : React.Children.map(children, (content, index) => <Block.Item key={index}>{content}</Block.Item>)}
        </Block>
      </ThemeProvider>
    );
  }
}

export default Columns;

class Activity {
  constructor() {
    this.events = [
      'touchmove',
      'blur',
      'focus',
      'focusin',
      'focusout',
      'load',
      'resize',
      'scroll',
      'unload',
      'click',
      'dblclick',
      'mousedown',
      'mouseup',
      'mousemove',
      'mouseover',
      'mouseout',
      'mouseenter',
      'mouseleave',
      'change',
      'select',
      'submit',
      'keydown',
      'keypress',
      'keyup',
      'error'
    ];

    this.count = 0;
    this.isActivity = false;

    this.event = () => this.action();

    this.update();
  }

  action() {
    this.isActivity = true;
    this.events.forEach(event => window.removeEventListener(event, this.event));
  }

  update() {
    if (this.isActivity) {
      this.count++;
    }

    if (this.count === 6) {
      this.events.forEach(event => window.removeEventListener(event, this.event));
      if (window.dataLayer) {
        window.dataLayer.push({ event: 'active_true' });
      }
    } else {
      this.events.forEach(event => window.addEventListener(event, this.event));
      setTimeout(() => this.update(), 10000);
    }
  }
}

if (process.browser) {
  new Activity();
}

import React from 'react';
import { classname } from 'utils';
import TeamImage from './components/TeamImage';
import './team-slider.css';

const TeamSlider = ({ text, topImages = [], bottomImages = [] }) => {
  const cl = classname('team_slider');
  return (
    <div className={cl()}>
      <div className={cl('top')}>
        {topImages.map((images, i) => (
          <TeamImage key={`tst-${i}`} images={images} className={cl('top')} />
        ))}
      </div>
      <div className={cl('text')}>{text}</div>
      <div className={cl('top second')}>
        {bottomImages.map((images, i) => (
          <TeamImage key={`tst-${i}`} images={images} className={cl('top')} />
        ))}
      </div>
    </div>
  );
};

export default TeamSlider;

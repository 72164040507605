import React from 'react';

const Wrapper = ({ active, clickHandler, link, pageType, cl, children }) => {
  if (pageType === 'desktop') {
    return (
      <a className={active ? cl('item active') : cl('item')} href={link}>
        {children}
      </a>
    );
  }
  return (
    <div className={active ? cl('item active') : cl('item')} onClick={clickHandler}>
      {children}
    </div>
  );
};

export default Wrapper;

import React from 'react';
import { Image } from 'elements';

const Departament = ({ departament, cl }) => {
  const { name, text, image, imageActive, link } = departament;
  return (
    <a className={cl('departament-item')} href={link}>
      <div className={cl('departament-name')} dangerouslySetInnerHTML={{ __html: name }} />
      <div className={cl('departament-text')} dangerouslySetInnerHTML={{ __html: text }} />
      {image && (
        <>
          <div className={cl('departament-image')}>
            <Image src={image} original={true} />
          </div>
          <div className={cl('departament-image-active')}>
            <Image src={imageActive} original={true} />
          </div>
        </>
      )}
    </a>
  );
};

export default Departament;

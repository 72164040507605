import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'elements';
import { classnames, classname } from 'utils';
import './Video.css';

/**
 * Видео
 */
class Video extends Component {
  cl = classname('video');

  static propTypes = {
    src: PropTypes.array.isRequired,
    srcMobile: PropTypes.array,
    className: PropTypes.string,
    loop: PropTypes.bool,
    autoplay: PropTypes.bool,
    btSize: PropTypes.string
  };

  static defaultProps = {
    loop: false,
    muted: true,
    preload: 'metadata',
    src: []
  };

  constructor(props) {
    super(props);

    this.updateWigth = this.updateWigth.bind(this);

    this.state = {
      isPlay: props.autoplay || props.autoPlay,
      pageWidth: 0,
      src: props.src,
      srcMobile: props.srcMobile
    };
  }

  componentDidMount() {
    if (process.browser && this.video) {
      this.observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            this.play();
          } else {
            this.video.pause();
          }
        },
        { threshold: 0.5 }
      );

      this.observer.observe(this.video);
      this.updateWigth();
      window.addEventListener('resize', this.updateWigth);
    }
  }

  componentWillUnmount() {
    if (process.browser && this.video) {
      this.observer.disconnect();
      window.removeEventListener('resize', this.updateWigth);
      this.setState({ src: [], srcMobile: [] });
      this.video.load();
    }
  }

  play() {
    this.video.play().catch((err) =>
      setTimeout(() => {
        if (!this.video) return;

        this.play();
      }, 1000)
    );
  }

  onClick(e) {
    this.setState({
      isPlay: true
    });

    e.preventDefault();
  }

  updateWigth() {
    this.setState({ pageWidth: window.screen.width });
  }

  render() {
    const { loop, muted, autoplay, autoPlay, poster, className, preload } = this.props;
    const { isPlay, pageWidth, src, srcMobile } = this.state;

    const classes = classnames(this.cl(), className);

    return (
      <div className={classes}>
        {!isPlay && (
          <div className={this.cl('play')} data-hover={true} onClick={(e) => this.onClick(e)}>
            <div className={this.cl('control')}>
              <Icon name={'play'} />
            </div>
          </div>
        )}
        {/^http/.test(src[0])
          ? isPlay && <iframe className={this.cl('video')} src={src[0]} />
          : isPlay && (
              <video
                className={this.cl('video')}
                loop={loop}
                // autoPlay={autoplay || autoPlay}
                autoPlay={false}
                muted={muted}
                preload={preload ? preload : 'auto'}
                src={
                  srcMobile && pageWidth <= 1080
                    ? 'https://static.nimax.ru' + srcMobile[0]
                    : 'https://static.nimax.ru' + src[0]
                }
                controls={!autoplay && !autoPlay}
                playsInline={true}
                ref={(node) => (this.video = node)}
                poster={poster ? 'https://static.nimax.ru' + poster : undefined}
              >
                {srcMobile && pageWidth <= 1080
                  ? srcMobile.map((item) => (
                      <source
                        key={item}
                        src={'https://static.nimax.ru' + item}
                        type={'video/' + item.split('.').pop()}
                      />
                    ))
                  : src.map((item) => (
                      <source
                        key={item}
                        src={'https://static.nimax.ru' + item}
                        type={'video/' + item.split('.').pop()}
                      />
                    ))}
              </video>
            )}
      </div>
    );
  }
}

export default Video;

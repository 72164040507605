import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  color: var(--color-white);
  background-color: var(--color-black);
  /* padding-left: var(--space-s);
  padding-right: var(--space-s); */

  @media (max-width: ${({theme}) => `${theme.m.b_768}px`}) {
    flex-direction: column;
  }
`;

export const HeadingWrapper = styled.div`
  margin-bottom: 40px;
  flex-basis: calc(100%/3 - var(--app__space) / 3);

  @media (max-width: ${({theme}) => `${theme.m.b_1024}px`}) {
     padding-right: 10px;
  }

  @media (max-width: ${({theme}) => `${theme.m.b_768}px`}) {
    justify-content: flex-start;
  }
`;

export const SpoileredWrapper = styled.div`
  padding-left: var(--app__space);
  flex-basis: calc(100%*2/3);
  padding-top: 8px;

  @media (max-width: ${({theme}) => `${theme.m.b_768}px`}) {
    padding-top: 0;
    padding-left: 0;
  }
`;

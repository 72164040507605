import React, { Component } from 'react';

import Icon from 'elements/Icon/Icon';
import { classnames, classname } from 'utils';

import './Footer.css';

export class Footer extends Component {
  cl = classname('footer');

  render() {
    const { className } = this.props;

    const classes = classnames(className, this.cl());

    return (
      <header className={classes}>
        <div className={this.cl('nav')}>
          <a href="/projects/">Проекты</a>
          <a href="/strategy/">Стратегия</a>
          <a href="/brands/">Брендинг</a>
          <a href="/ux-ui/">UX/UI-дизайн</a>
          <a href="/soft/">Веб-разработка</a>
          <a href="/media/">СММ</a>
          <a href="/performance/">Реклама</a>
        </div>
        <div className={this.cl('social')}>
          <a href="https://vk.com/nimaxru" target="_blank" rel="noreferrer noopener">
            <Icon name={'vk'} />
          </a>
          {/* <a 
            href="https://www.facebook.com/nimax.ru" 
            target="_blank" 
            rel="noreferrer noopener"
          >
            <Icon name={'facebook'} />
          </a> */}
          <a href="https://www.youtube.com/channel/UCBzRw6EMHslbfIiqoQCCdKQ" target="_blank" rel="noreferrer noopener">
            <Icon name={'youtube'} />
          </a>
          {/* <a href="http://instagram.com/Nimax_agency" target="_blank" rel="noreferrer noopener">
            <Icon name={'instagram'} />
          </a> */}
          <a href="https://www.behance.net/nimaxbrands" target="_blank" rel="noreferrer noopener">
            <Icon name={'behance'} />
          </a>
          <a href="https://dribbble.com/Nimax" target="_blank" rel="noreferrer noopener">
            <Icon name={'dribbble'} />
          </a>
          <a href="https://t.me/nimax_agency" target="_blank" rel="noreferrer noopener">
            <Icon name={'telegram'} />
          </a>
        </div>
      </header>
    );
  }
}

import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  background-color: ${({ color }) => (color ? color : '#1e1e1e')};
  padding: 50px;
  height: ${({ fixed }) => (fixed ? '700px' : 'auto')};
  position: relative;
  overflow: hidden;
  @media (max-width: 1023px) {
    padding: 50px var(--space-s);
  }
  @media (max-width: 500px) {
    height: ${({ fixed }) => (fixed ? '480px' : 'auto')};
    padding: var(--space-s);
    &:nth-child(2) {
      padding: 20px var(--space-s) 40px;
    }
  }
`;

export const SectionTop = styled.div`
  width: 100%;
  @media (max-width: 500px) {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`;

export const BgImage = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  transition: transform 500ms, top 500ms, left 500ms;
  ${({ active, type }) =>
    active &&
    type === 1 &&
    css`
      transform: rotate(-24deg) scale(1.5);
      top: 200px;
      left: 100px;
    `}

  .image {
    height: 100%;
    object-fit: ${({ type }) => (type === 1 ? 'contain' : 'cover')};
    ${({ type }) =>
      type === 1
        ? css`
            object-position: bottom;
          `
        : css`
            object-position: bottom 10% right 50%;
          `}

    ${({ active, type }) =>
      type === 2
        ? active
          ? css`
              object-position: top;
            `
          : css`
              object-position: bottom 15% right 50%;
              @media (max-width: 1920px) {
                object-position: bottom 10% right 50%;
              }
            `
        : ''}
    transition: object-position 500ms;
  }
`;

export const SectionMiddle = styled.div`
  position: relative;
  overflow: hidden;
  height: 470px;
  width: 80%;

  @media (max-width: 500px) {
    display: ${({ active }) => (active ? 'block' : 'none')};
    height: ${({ active }) => (active ? 'auto' : '0px')};
    width: auto;
    overflow: initial;
  }
`;

export const SectionBottom = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 50px;
  padding: 0 50px;
  left: 0;
  width: 100%;
  @media (max-width: 1023px) {
    padding: 0 var(--space-s);
  }
  @media (max-width: 500px) {
    position: initial;
    padding: 0;
    min-height: 22px;
  }
`;

export const Title = styled.h2`
  transition: font-size 500ms;
  padding-bottom: 20px;
  font-size: ${({ active }) => (active ? 'var(--dark-default-h3-size)' : 'var(--dark-default-p2-size)')};
  position: relative;
  z-index: 1;
  @media (max-width: 500px) {
    font-size: 20px;
  }
`;

export const Description = styled.div`
  transition: width 500ms, font-size 500ms, opacity 250ms, line-height 500ms;
  width: ${({ active }) => (active ? '60%' : '0%')};
  height: ${({ active }) => (active ? 'auto' : '0px')};
  font-size: ${({ active }) => (active ? 'var(--dark-default-p2-size)' : '0px')};
  line-height: ${({ active }) => (active ? 'var(--dark-default-p2-line)' : '0px')};
  opacity: ${({ active }) => (active ? '1' : '0')};
  position: relative;
  z-index: 1;
  @media (max-width: 500px) {
    opacity: 1;
    width: 100%;
    height: auto;
    display: ${({ active }) => (active ? 'block' : 'none')};
    color: var(--dark-default-color-gray);
    width: ${({ active }) => (active ? '100%' : '0%')};
    animation-duration: 600ms;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
    animation-name: mobileShow;
  }
`;

export const Item = styled.div`
  position: absolute;
  left: 0;
  top: 0;

  transition: opacity 300ms, height 500ms;
  opacity: ${({ active }) => (active ? '1' : '0')};
  animation-name: ${({ active, an }) => {
    if (active) {
      if (an === 'next') {
        return 'ItemShowNext';
      } else if (an === 'prev') {
        return 'ItemShowPrev';
      }
    } else {
      if (an === 'next') {
        return 'ItemHideNext';
      } else if (an === 'prev') {
        return 'ItemHidePrev';
      }
    }
  }};

  animation-duration: 600ms;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;

  @keyframes ItemShowNext {
    0% {
      top: 350px;
    }
    100% {
      top: 0px;
    }
  }

  @keyframes ItemHideNext {
    0% {
      top: 0px;
    }
    100% {
      top: -300px;
    }
  }

  @keyframes ItemShowPrev {
    0% {
      top: -400px;
    }
    100% {
      top: 0;
    }
  }

  @keyframes ItemHidePrev {
    0% {
      top: 0px;
    }
    100% {
      top: 300px;
    }
  }

  @keyframes mobileShow {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @media (max-width: 500px) {
    position: relative;
    display: ${({ active }) => (active ? 'block' : 'none')};
    animation-name: mobileShow;
  }
`;

export const Num = styled.div`
  font-size: var(--dark-default-h2-size);
  margin-bottom: 20px;
`;

export const SubTitle = styled.h3`
  font-size: var(--dark-default-h3-size);
  line-height: var(--dark-default-h3-line);
  margin-bottom: 25px;

  animation-duration: 1000ms;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;

  animation-name: ${({ active, an }) => (active && an === 'next' ? 'MarginTop_1' : '')};

  @keyframes MarginTop_1 {
    0% {
      margin-top: 100px;
    }
    100% {
      margin-top: 0px;
    }
  }

  @media (max-width: 500px) {
    color: var(--dark-default-color-main);
    animation-name: none;
    margin-bottom: 10px;
  }
`;

export const Content = styled.div`
  font-size: var(--dark-default-p2-size);
  line-height: 1.1;

  animation-duration: 2000ms;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;

  animation-name: ${({ active }) => (active ? 'MarginTop_2' : '')};

  @keyframes MarginTop_2 {
    0% {
      margin-top: 50px;
    }
    100% {
      margin-top: 0px;
    }
  }

  p {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0px;
    }
  }

  ol,
  ul {
    list-style: none;
    counter-reset: list-counter;
    margin-bottom: 15px;
  }
  ol li,
  ul li {
    counter-increment: list-counter;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: none;
    }
  }
  ul li::before {
    content: '•  ';
  }
  ol li::before {
    content: counter(list-counter) '. ';
  }

  @media (max-width: 500px) {
    color: var(--dark-default-color-gray);
    animation-name: none;
  }
`;

export const Tags = styled.div`
  display: flex;
  gap: 2px;
  z-index: 1;
`;

export const Tag = styled.div`
  font-size: var(--dark-default-p2-size);
  line-height: 1;
  text-transform: lowercase;
  border: 1px solid white;
  border-radius: 30px;
  padding: 1px 7px 7px;
  @media (max-width: 500px) {
    padding: 1px 8px 5px;
    font-size: 15px;
  }
`;

export const ProgressBar = styled.div``;

export const Arrows = styled.div`
  display: flex;
  gap: 2px;
`;

export const Arrow = styled.div`
  border: 1px solid white;
  border-radius: 30px;
  transition: opacity 500ms;
  padding: 1px 20px;
  line-height: 0;

  opacity: ${({ active }) => (active ? '1' : '0.5')};
  cursor: ${({ active }) => (active ? 'pointer' : 'default')};
  transform: ${({ left }) => (left ? 'rotate(180deg)' : '')};

  display: flex;
  align-items: center;

  @media (max-width: 500px) {
    border: 1px solid black;
    padding: 1px 15px;
  }
`;

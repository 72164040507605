import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { classname, classnames } from 'utils';
import {Heading} from 'elements'
import './ProjectTeam.css';

/** Список сотрудников, работавших над проектом */

class ProjectTeam extends Component {
    cl = classname('project-team');

    static propTypes = {
        team: PropTypes.arrayOf(PropTypes.object),
    }

    render() { 
        const { className, team, data, ...props } = this.props;

        return (
            <div className={this.cl()}>
                <Heading level={6} as={'h2'}>Над проектом работали</Heading>
                <div className={this.cl('items')}>
                    {team.map((item, index) => {
                        let persons = item['list'];
                        let titles = persons.map( person => {
                            return data[person]['title'];
                        });

                        let posts = persons.map( person => {
                            return data[person]['post'];
                        });
                        
                        return (<div className={this.cl('item')} key={`${item}-${index}`}>
                                    
                                    <div className={this.cl('post')}>
                                        {item['spec'] ? item['spec'] : posts[0]}
                                    </div>
                                    <div className={this.cl('persons')}>
                                        {titles.map((title, index) => {
                                            return (
                                                <div className={this.cl('person')} key={`person-${index}`}>{title}</div>
                                            )
                                        })}
                                    </div>
                                    
                                </div>)
                    })}
                </div>    
            </div>
        );
    }
}

export default props =>
  (
    <StaticQuery
      query={graphql`
        {
          data: allMdx(filter: { fields: { id: { regex: "^/peoples//" } } }) {
            nodes {
              fields {
                slug
              }
              frontmatter {
                title
                post
                image
              }
            }
          }
        }
      `}
      render={({ data: { nodes: peoples } }) => {
        peoples = peoples.reduce((list, item) => ({ ...list, [item.fields.slug]: item.frontmatter }), {});

        return <ProjectTeam data={peoples} {...props} />;
      }}
    />
  );

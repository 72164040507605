import './activity';

export { default as classname } from './classname';
export { default as cyrillicToTranslit } from './cyrillicToTranslit';
export { default as back } from './back';
export { default as lazy } from './lazy';
export { classnames } from '@bem-react/classnames';

/**
 * Вывод текста в нужном типе
 * @param content
 * @returns {{children: *}|{dangerouslySetInnerHTML: {__html: string}}}
 */
export const raw = (content) => {
  if (typeof content === 'string') {
    return { dangerouslySetInnerHTML: { __html: content } };
  } else {
    return { children: content };
  }
};

export const scroll = (scrollTop) => {
  window.scroll({ top: scrollTop, left: 0, behavior: 'smooth' });
};

/**
 * Является ли устройство айосом
 */
export const isiOS = process.browser ? !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform) : false;

/**
 * Является ли устройство тачем
 */
export const isTouch = process.browser ? 'ontouchstart' in window : false;

/**
 * Список месяцев
 */
export const months = [
  'января',
  'февраля',
  'марта',
  'апреля',
  'мая',
  'июня',
  'июля',
  'августа',
  'сентября',
  'октября',
  'ноября',
  'декабря'
];

/**
 * Создаю дату
 */
export const createDate = (value) => {
  // смещаю пояс
  value += new Date().getTimezoneOffset() * 60;

  // добавляю пояс
  value += 180 * 60;

  // в миллисекунды
  value *= 1000;

  const date = new Date(value);
  return date;
};

/*
const config = {
    type: 'phone',
    options: {
      required: true,
      maxLength: 3,
      email: true,
    },
    messages: {
      required: 'Необходимо ввести что-нибудь',
      maxLength: 'Кастомная ошибка'
    },
}
*/
export const validate = (value, config) => {
  const { type, options: _options = [] } = config;
  const messages = config.messages || {};

  const validation = {
    required(value, arg) {
      const message = 'Обязательное поле';
      const valid = arg ? value.length >= 1 : true;

      return { valid, message: messages['required'] || message };
    },
    email(value, arg) {
      const message = 'В написании почты закралась ошибка';
      const valid = arg ? /(.+)@(.+){2,}\.(.+){2,}/.test(value) : true;

      return { valid, message: messages['email'] || message };
    },
    minLength(value, arg) {
      const message = 'Минимальное количество символов - ' + arg;
      const valid = arg ? value.length >= arg : true;

      return { valid, message: messages['minLength'] || message };
    },
    checked(value, arg) {
      const message = 'Обязательное условие';
      const valid = arg ? value : true;

      return { valid, message: messages['checked'] || message };
    },
    phone(value, arg) {
      const message = 'Проверьте написание телефона';
      const valid = arg ? value.replace(/\D/g, '').length === 11 : true;

      return { valid, message: messages['phone'] || message };
    }
  };

  const types = {
    text: {
      required: true
    },
    textarea: {
      required: true,
      minLength: 30
    },
    phone: {
      required: true,
      phone: true
    },
    email: {
      required: true,
      email: true
    },
    checkbox: {
      checked: true
    }
  };

  const options = { ...types[type], ..._options };

  let result = '';
  Object.keys(options).some((key) => {
    const argument = options[key];
    const valid = validation[key](value, argument);
    result = valid;

    return valid && !valid.valid;
  });

  return result;
};

export const onInputChange = (fields, name, value, validateConfig) => {
  const field = { ...fields[name] };
  const cfg = validateConfig[name];

  field.value = value;
  if (cfg) {
    const valid = validate(value, cfg);
    field.valid = valid.valid;
    field.errorMessage = valid.message;
  }

  return {
    ...fields,
    [name]: {
      ...field
    }
  };
};

export const validateForm = (fields, cfg) => {
  const copy = { ...fields };

  Object.keys(cfg).forEach((name) => {
    const field = copy[name];
    const valid = validate(field.value, cfg[name]);
    field.valid = valid.valid;
    field.errorMessage = valid.message;

    if (!field.valid) field.showErrors = true;
  });

  return copy;
};

export const deepCloneObject = (obj) => {
  return JSON.parse(JSON.stringify(obj));
};

export const createImage = (src, width = 0, height = 0) => {
  const local = 'https://static.nimax.ru';
  if (/\.gif$/.test(src)) {
    return local + src;
  }
  const api = 'https://i.nimax.ru';
  const rt = 'rt:' + (width && height ? 'fill' : 'fit');
  const size = 'size:' + width + ':' + height;
  const plain = 'plain';

  return [api, rt, size, plain, local + src].join('/');
};

/**
 * Конвертер изображения
 * @param image
 * @param params
 * @return {string}
 */
// export const generateImage = (image, params) => {
//   const opts = {
//     url: 'http://storybook' + image,
//     proxy_url: 'http://img.nimax.localhost',
//     width: 400,
//     height: 400,
//     resize: 'fill',
//     gravity: 'no',
//     enlarge: 'yes',
//     extension: 'png'
//   };
//
//   console.log(opts);
//
//   const encoded_url = btoa(opts.url)
//     .replace(/=/g, '')
//     .replace(/\//g, '_')
//     .replace(/\+/g, '-');
//
//   const path =
//     '/' +
//     opts.resize +
//     '/' +
//     opts.width +
//     '/' +
//     opts.height +
//     '/' +
//     opts.gravity +
//     '/' +
//     opts.enlarge +
//     '/' +
//     encoded_url +
//     '.' +
//     opts.extension;
//
//   const shaObj = new jsSHA('SHA-256', 'BYTES');
//   shaObj.setHMACKey(opts.key, 'HEX');
//   shaObj.update(hex2a(opts.salt));
//   shaObj.update(path);
//   var hmac = shaObj
//     .getHMAC('B64')
//     .replace(/=/g, '')
//     .replace(/\//g, '_')
//     .replace(/\+/g, '-');
//   return opts.proxy_url + '/' + hmac + path;
//
//   function hex2a(hexx) {
//     const hex = hexx.toString(); //force conversion
//     let str = '';
//     for (let i = 0; i < hex.length; i += 2)
//       str += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
//     return str;
//   }
// };

export const copyToClipboard = (text) => {
  if (window.clipboardData && window.clipboardData.setData) {
    // IE specific code path to prevent textarea being shown while dialog is visible.
    return window.clipboardData.setData('Text', text);
  } else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
    var textarea = document.createElement('textarea');
    textarea.textContent = text;
    textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in MS Edge.
    document.body.appendChild(textarea);
    textarea.select();
    try {
      return document.execCommand('copy'); // Security exception may be thrown by some browsers.
    } catch (ex) {
      console.warn('Copy to clipboard failed.', ex);
      return false;
    } finally {
      document.body.removeChild(textarea);
    }
  }
};

/**
 * Проверка валидности телефона
 * @param value
 * @returns {boolean}
 */
export const testPhone = (value) => {
  const regex = /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/gm;

  return regex.test(value);
};

/**
 * Проверка валидность эл. почты
 * @param value
 * @returns {*}
 */
export const testEmail = (value) => {
  const regex = /^.*@.*\..{2,}$/;

  return regex.test(value);
};

export const createImageNew = (url, width = 0, height = 0, isWebp = 0) => {
  return 'https://i.nimax.ru/?file=' + url + '&w=' + width + '&h=' + height + '&webp=' + isWebp;
};

export const createVideo = (url, width = 0, height = 0, isWebm = 0) => {
  return 'https://static.nimax.ru' + url;
};

export const createPreview = (url, width = 0, height = 0) => {
  return 'https://i.nimax.ru/?file=' + url + '&w=' + width + '&h=' + height + '&preview=1';
};

export const refEmail = () => {
  const db = {
    ref: {
      'yandex.ru': 'hello@nimax.ru',
      'google.com': 'hello@nimax.ru',
      'google.ru': 'hello@nimax.ru',
      'go.mail.ru': 'hello@nimax.ru',
      'bing.com': 'hello@nimax.ru',
      'search.yahoo.com': 'hello@nimax.ru',
      'nova.rambler.ru': 'hello@nimax.ru',
      'duckduckgo.com': 'hello@nimax.ru',
      'ratingruneta.ru': 'welcome@nimax.ru',
      'ruward.ru': 'newbusiness@nimax.ru',
      'tagline.ru': 'newbiz@nimax.ru',
      'instagram.com': 'service@nimax.ru',
      'l.instagram.com': 'service@nimax.ru',
      'facebook.com': 'service@nimax.ru',
      'l.facebook.com': 'service@nimax.ru',
      'vk.com': 'service@nimax.ru',
      'away.vk.com': 'service@nimax.ru',
      'twitter.com': 'service@nimax.ru',
      't.co': 'service@nimax.ru',
      'cossa.ru': 'consult@nimax.ru',
      'spark.ru': 'consult@nimax.ru',
      'cmsmagazine.ru': 'consult@nimax.ru',
      'vc.ru': 'hey@nimax.ru',
      'nimax.pro': 'hey@nimax.ru'
    },
    source: {
      ndirect: 'contact@nimax.ru',
      mytarget: 'sales@nimax.ru',
      email: 'accounts@nimax.ru',
      vc: 'hey@nimax.ru'
    },
    content: {
      articles: 'consult@nimax.ru'
    },
    gclid: 'forsales@nimax.ru'
  };

  let value = localStorage.getItem('email');

  if (!value) {
    if (document.referrer) {
      const ref = document.referrer.toLowerCase().replace(/(https?:\/\/w?w?w?\.?)([a-z0-9.:-]+)(.*)/, '$2');

      if (db.ref[ref]) {
        value = db.ref[ref];
      } else {
        const params = new URLSearchParams(window.location.search);

        if (db.source[params.get('utm_source')]) {
          value = db.source[params.get('utm_source')];
        } else if (db.content[params.get('utm_content')]) {
          value = db.content[params.get('utm_content')];
        } else if (params.get('gclid')) {
          value = db.gclid;
        }
      }
    }
  }

  if (value) {
    localStorage.setItem('email', value);
  }
};

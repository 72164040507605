import React, { useState } from 'react';
import { Image } from 'elements';
import { classname } from 'utils';

const TeamImage = ({ className, images = [] }) => {
  const cl = classname(className);

  const [position, setPosition] = useState(0);
  const [points, setPoints] = useState([]);
  const [point, setPoint] = useState(0);
  const count = images.length;

  const mouseEnterHandler = (e) => {
    if (points.length === 0) {
      const { offsetWidth } = e.target;
      const point = Math.floor(offsetWidth / count);
      if (point > 0) {
        setPoint(point);
        const _temp = images.map((_, i) => point * i);
        if (_temp.length !== 0) {
          setPoints(_temp);
        }
      }
    }
  };

  const mouseLeaveHandler = () => {
    // position > 0 && setPosition(0);
  };

  const coursorHandler = (e) => {
    const rect = e.target.getBoundingClientRect();
    const cursor = Math.floor(e.clientX - rect.left);
    const _temp = points.findIndex((el, index) => {
      if (cursor >= el && cursor <= point * (index + 1)) {
        return true;
      }
    });
    _temp !== -1 && setPosition(_temp);
  };

  return (
    <div
      className={cl('image_wrapper')}
      onMouseMove={coursorHandler}
      onMouseEnter={mouseEnterHandler}
      onMouseLeave={mouseLeaveHandler}
    >
      {images.map((image, i) => (
        <img
          src={'https://static.nimax.ru' + image}
          key={`team-${i}`}
          style={{ transform: `rotate(${i * 2}deg)`, top: ``, opacity: `${position >= i ? 1 : 0}` }}
          className={cl('image')}
          width={413}
          height={521}
        />
      ))}
    </div>
  );
};

export default TeamImage;

import React from 'react';
import icon from './shape.svg';

const SubService = ({ service, cl }) => {
  return (
    <div className={cl('sub-service-wrapper')}>
      <a className={cl('sub-service')} href={service.link}>
        <img src={icon} className={cl('sub-service-icon')} />
        <span className={cl('sub-service-text')} dangerouslySetInnerHTML={{ __html: service.name }} />
      </a>
    </div>
  );
};

export default SubService;

export default {
  area: {
    width: 1093
  },
  gap: {
    width: 1143
  },
  fullscreen: {
    width: 1760,
    source: [
      {
        width: 2560,
        viewport: 1920
      },
      {
        width: 1920,
        viewport: 1680
      },
      {
        width: 1680,
        viewport: 1400
      },
      {
        width: 1024,
        viewport: 1280
      },
      {
        width: 768,
        viewport: 1024
      },
      {
        width: 768,
        viewport: 640
      },
      {
        width: 640,
        viewport: 320
      }
    ]
  }
};

import React, { PureComponent } from 'react';
import { classnames, classname, raw } from 'utils';

import './ContentList.css';

export class ContentList extends PureComponent {
  cl = classname('content-list');

  static propTypes = {
    
  };

  render() {
    const { className, children } = this.props;

    const classes = classnames(className, this.cl());

    return (
      <div className={classes}>
        {children.map(item => {
          return (
            <div className={this.cl('item')} key={item.name}>
              <div className={this.cl('number')} {...raw(item.number)} />
              <div className={this.cl('text')} {...raw(item.text)} />
            </div>
          );
        })}
      </div>
    );
  }
}

import React from 'react';
import { classname } from 'utils';
import './dark-showreel.css';

import ShowreelWrapper from './components/ShowreelWrapper';
import Logos from './components/Logos';
import BottomVideo from './components/BottomVideo';

const DarkShowreel = ({ title, team, description, video, images, logos, mobileBg }) => {
  const cl = classname('dark-showreel');

  return (
    <div className={cl('flow')}>
      <ShowreelWrapper cl={cl} images={images} mobileBg={mobileBg}>
        <div className={cl('wrapper')}>
          <div className={cl('title')} dangerouslySetInnerHTML={{ __html: title }} />
          <div className={cl('bottom')}>
            <div className={cl('bottom-right')}></div>
            <div className={cl('bottom-left')}>
              <div className={cl('team')} dangerouslySetInnerHTML={{ __html: team }} />
              <div className={cl('description')} dangerouslySetInnerHTML={{ __html: description }} />
            </div>
          </div>
        </div>
      </ShowreelWrapper>

      {video && <BottomVideo video={video} cl={cl} />}
      {logos && <Logos logos={logos} cl={cl} />}
    </div>
  );
};

export default DarkShowreel;

import React, { PureComponent } from 'react';

import { Image } from 'elements';
import { classnames, classname } from 'utils';

import './ListLogos.css';

/**
 * Список логотипов
 */
export class ListLogos extends PureComponent {
  cl = classname('list-logos');

  render() {
    const { className, images, title } = this.props;

    const classes = classnames(className, this.cl());

    return (
      <div className={classes}>
        {title && <div className={this.cl('title')}>{title}</div>}
        <div className={this.cl('wrapper')}>
          {images.map((item) => (
            <div className={this.cl('item')} key={item.title}>
              <Image src={item} original={true} className={this.cl('image')} />
            </div>
          ))}
        </div>
      </div>
    );
  }
}

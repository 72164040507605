import React from 'react';
import { classname } from 'utils';
import Rating from './components/Rating';
import './dark-ratings.css';

const DarkRatings = ({ title = '', ratings = [] }) => {
  const cl = classname('dark-ratings');
  return (
    <div className={cl('background')}>
      <div className={cl('wrapper')}>
        <div className={cl('inner')}>
          <div className={cl('title')} dangerouslySetInnerHTML={{ __html: title }} />
          {ratings.map((rating, i) => (
            <Rating key={`rating-${i}`} rating={rating} cl={cl} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default DarkRatings;

import React from 'react';

export default {
  width: 41,
  height: 41,
  fill: 'none',
  content: (
    <>
      <circle cx="20.267" cy="20.267" r="19.267" stroke="currentColor" strokeWidth="2" />
      <path stroke="currentColor" strokeWidth="2" d="M14 20h11M21.707 16.293L26 20l-4.293 4" />
    </>
  )
};

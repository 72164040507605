import React, { useEffect, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import SvgInline from '../../../SvgInline/SvgInline';
import useWindowSize from '../../../../utils/hooks/useWindowSize';
import { Image } from 'elements';
import Navigation from '../navigation/Navigation';
import * as S from './Slide.styled';

const Slide = ({ data }) => {
  const { slides, title, description, tags, image, color, type } = data;
  const tagsArr = tags ? tags.split(',') : [];
  const { width } = useWindowSize();

  const [active, setActive] = useState(false);
  const [current, setCurrent] = useState(0);
  const [an, setAn] = useState('prev');
  const [device, setDevice] = useState();

  const clickHandler = (nav, disabled, focus) => {
    if (active || disabled) return;
    if (nav === 'prev') {
      setCurrent((prev) => (focus !== undefined ? focus : prev - 1));
      setAn('prev');
    } else if (nav === 'next') {
      if (current === slides.length) {
        setCurrent((_) => (focus !== undefined ? focus : 0));
        setAn('prev');
      } else {
        setCurrent((prev) => (focus !== undefined ? focus : prev + 1));
        setAn('next');
      }
    }
  };

  const clickNavigationHandler = (i) => {
    if (i === current) return;
    if (i > current) clickHandler('next', false, i);
    if (i < current) clickHandler('prev', false, i);
  };

  const swipeHandler = useSwipeable({
    onSwiped: ({ dir }) => {
      const nav = dir === 'Left' ? 'next' : 'Right' ? 'prev' : 'undefined';
      if (nav) {
        const disabled = nav === 'next' ? false : current === 0;
        clickHandler(nav, disabled);
      }
    }
  });

  useEffect(() => {
    setActive(true);
    const timeId = setTimeout(() => {
      setActive(false);
    }, 700);
    return () => {
      clearInterval(timeId);
    };
  }, [current]);

  useEffect(() => {
    if (width) {
      if (width > 500) setDevice('desktop');
      if (width <= 500) setDevice('mobile');
    }
  }, [width]);

  if (!device) {
    return null;
  }

  if (device === 'mobile') {
    return (
      <div {...swipeHandler}>
        <S.Wrapper color={color} fixed={true}>
          <S.SectionTop>
            <S.Tags>
              {tagsArr.map((tag, i) => (
                <S.Tag key={`st-${i}${tag}`} dangerouslySetInnerHTML={{ __html: tag }} />
              ))}
            </S.Tags>
            <S.Title dangerouslySetInnerHTML={{ __html: title }} active={true} />
            <S.BgImage active={false}>
              <Image src={image} />
            </S.BgImage>
          </S.SectionTop>
        </S.Wrapper>
        <S.Wrapper color="#FFFFFF">
          <S.SectionBottom>
            <Navigation active={current} length={slides.length + 1} onClick={clickNavigationHandler} />
            <S.Arrows>
              <S.Arrow
                left
                active={current !== 0}
                onClick={() => {
                  clickHandler('prev', current === 0);
                }}
              >
                <SvgInline url={'/icons/arrow-dark.svg'} />
              </S.Arrow>
              <S.Arrow
                right
                active={true}
                onClick={() => {
                  clickHandler('next', false);
                }}
              >
                <SvgInline url={'/icons/arrow-dark.svg'} />
              </S.Arrow>
            </S.Arrows>
          </S.SectionBottom>
          <S.Description
            dangerouslySetInnerHTML={{
              __html: description
            }}
            active={current === 0}
          />
          <S.SectionMiddle active={current !== 0}>
            {slides.map((slide, i) => (
              <S.Item key={`sl-i-${i}`} active={current === i + 1} an={an}>
                <S.SubTitle dangerouslySetInnerHTML={{ __html: slide.title }} active={current === i + 1} an={an} />
                <S.Content dangerouslySetInnerHTML={{ __html: slide.description }} active={current === i + 1} an={an} />
              </S.Item>
            ))}
          </S.SectionMiddle>
        </S.Wrapper>
      </div>
    );
  }

  if (device === 'desktop') {
    return (
      <S.Wrapper color={color} fixed={true}>
        <S.SectionTop>
          <S.Title dangerouslySetInnerHTML={{ __html: title }} active={current === 0} />
          <S.Description
            dangerouslySetInnerHTML={{
              __html: description
            }}
            active={current === 0}
          />
          <S.BgImage active={current !== 0} type={type}>
            <Image src={image} />
          </S.BgImage>
        </S.SectionTop>
        <S.SectionMiddle active={current !== 0}>
          {slides.map((slide, i) => (
            <S.Item key={`sl-i-${i}`} active={current === i + 1} an={an}>
              <S.Num dangerouslySetInnerHTML={{ __html: `0${i + 1}` }} active={current === i + 1} an={an} />
              <S.SubTitle dangerouslySetInnerHTML={{ __html: slide.title }} active={current === i + 1} an={an} />
              <S.Content dangerouslySetInnerHTML={{ __html: slide.description }} active={current === i + 1} an={an} />
            </S.Item>
          ))}
        </S.SectionMiddle>
        <S.SectionBottom>
          <S.Tags>
            {tagsArr.map((tag, i) => (
              <S.Tag key={`st-${i}${tag}`} dangerouslySetInnerHTML={{ __html: tag }} />
            ))}
          </S.Tags>
          <Navigation active={current} length={slides.length + 1} onClick={clickNavigationHandler} />
          <S.Arrows>
            <S.Arrow
              left
              active={current !== 0}
              onClick={() => {
                clickHandler('prev', current === 0);
              }}
            >
              <SvgInline url={'/icons/arrow-light.svg'} />
            </S.Arrow>
            <S.Arrow
              right
              active={true}
              onClick={() => {
                clickHandler('next', false);
              }}
            >
              <SvgInline url={'/icons/arrow-light.svg'} />
            </S.Arrow>
          </S.Arrows>
        </S.SectionBottom>
      </S.Wrapper>
    );
  }
};

export default Slide;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { classnames, classname, raw } from 'utils';
import { Card, FaceList } from 'components';
import './CardService.css';

/**
 * Карточка услуги
 */
class CardService extends Component {
  cl = classname('card-service');

  static propTypes = {
    ...Card.propTypes,
    /** Список авторов */
    authors: PropTypes.arrayOf(PropTypes.string).isRequired,
    /** Анонс */
    announce: PropTypes.string
  };

  static defaultProps = {
    type: 'Услуга',
    authors: []
  };

  render() {
    const { className, authors, announce, ...props } = this.props;

    const classes = classnames(className, this.cl());

    return (
      <Card className={classes} {...props}>
        {announce && <div className={this.cl('announce')} {...raw(announce)} />}
        {authors && 
          <div className={this.cl('authors')}>
            <FaceList type={'hidden'} flow={'row'}>
              {authors}
            </FaceList>
          </div>
        }
      </Card>
    );
  }
}

export default CardService;

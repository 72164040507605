import React from 'react';

export default {
  width: 28,
  height: 24,
  fill: 'none',
  content: (
    <>
      <path fillRule="evenodd" clipRule="evenodd" d="M8 4V20H10L10 4H8ZM18 4L18 20H20L20 4H18Z" fill="#202020" />
    </>
  )
};

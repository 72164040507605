import React, { useState } from 'react';
import { REGEXP } from '../MediaBanner';
import Annotation from './Annotation';

const Text = ({ text, annotation, cl }) => {
  const [tag, setTag] = useState(false);

  const hideHandler = (el) => {
    setTag(el.target.dataset.attr);
  };
  const showHandler = () => {
    setTag(false);
  };
  const tagHandler = (str) => {
    if (tag === false || tag === str) {
      return cl('tag');
    } else {
      return cl('tag_hide');
    }
  };

  const showAnnotation = (str, i) => {
    const key = `${str}_${i}`;
    if (REGEXP.test(str)) {
      return (
        <Annotation
          key={key}
          annotation={annotation[str]}
          str={str}
          tag={tag}
          className={tagHandler(str)}
          showHandler={showHandler}
          hideHandler={hideHandler}
        />
      );
    } else {
      return (
        <span key={key} className={tag ? cl('text_hide') : cl('text')}>
          {str}
        </span>
      );
    }
  };

  return <div className={cl('title')}>{text.map(showAnnotation)}</div>;
};

export default Text;

import React from 'react';
import { ThemeProvider } from 'styled-components';
import Block from './Price.style';

export default ({ name, value, color = 'black_500', className }) => (
  <ThemeProvider theme={{ props: { color } }}>
    <Block className={className}>
      <Block.Name dangerouslySetInnerHTML={{ __html: name }} />
      <Block.Value dangerouslySetInnerHTML={{ __html: value }} />
    </Block>
  </ThemeProvider>
);

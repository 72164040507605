import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Img = styled.img`
  max-width: none;
  margin: 0 55px;

  @media (max-width: ${({theme}) => `${theme.m.b_768}px`}) {
    margin: 0 20px;
    height: 18px;
  }
`;

const ItemTicker = ({src}) => {
    return (
        <Img src={src} />
    )
};

ItemTicker.propTypes = {
  src: PropTypes.string
}

export default ItemTicker;

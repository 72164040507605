import React from 'react';
import { classname } from 'utils';
import './dark-annotation.css';

const DarkAnnotation = ({ link, annotation, text }) => {
  const cl = classname('dark-annotation');
  return link ? (
    <a href="link" title={annotation}>
      {text}
    </a>
  ) : (
    <span title={annotation}>{text}</span>
  );
};

export default DarkAnnotation;

import React from 'react';
import { classname } from 'utils';
import { Button, Heading, Image } from 'elements';
import './download-media.css';

const DownloadMedia = ({ title, formats, image }) => {
  const cl = classname('download-media');

  return (
    <div className={cl('wrapper')}>
      <div className={cl('image-wrapper')}>
        <Image src={image} />
      </div>
      <div className={cl('inline-block')}>
        <Heading level={4} as={'h2'} className={cl('title')}>
          {title}
        </Heading>
        <div className={cl('buttons-wrapper')}>
          {formats.map((item, i) => (
            <a
              key={'dwm_' + i}
              href={'https://static.nimax.ru' + item.link}
              target={'_blank'}
              className={cl('button-text')}
            >
              <Button>{item.type}</Button>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DownloadMedia;

// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-containers-articles-js": () => import("./../../../src/containers/articles.js" /* webpackChunkName: "component---src-containers-articles-js" */),
  "component---src-containers-categories-js": () => import("./../../../src/containers/categories.js" /* webpackChunkName: "component---src-containers-categories-js" */),
  "component---src-containers-categories-projects-js": () => import("./../../../src/containers/categories-projects.js" /* webpackChunkName: "component---src-containers-categories-projects-js" */),
  "component---src-containers-contacts-js": () => import("./../../../src/containers/contacts.js" /* webpackChunkName: "component---src-containers-contacts-js" */),
  "component---src-containers-departament-js": () => import("./../../../src/containers/departament.js" /* webpackChunkName: "component---src-containers-departament-js" */),
  "component---src-containers-departament-landing-js": () => import("./../../../src/containers/departament-landing.js" /* webpackChunkName: "component---src-containers-departament-landing-js" */),
  "component---src-containers-events-js": () => import("./../../../src/containers/events.js" /* webpackChunkName: "component---src-containers-events-js" */),
  "component---src-containers-jobs-js": () => import("./../../../src/containers/jobs.js" /* webpackChunkName: "component---src-containers-jobs-js" */),
  "component---src-containers-main-js": () => import("./../../../src/containers/main.js" /* webpackChunkName: "component---src-containers-main-js" */),
  "component---src-containers-menu-js": () => import("./../../../src/containers/menu.js" /* webpackChunkName: "component---src-containers-menu-js" */),
  "component---src-containers-notfound-js": () => import("./../../../src/containers/notfound.js" /* webpackChunkName: "component---src-containers-notfound-js" */),
  "component---src-containers-order-js": () => import("./../../../src/containers/order.js" /* webpackChunkName: "component---src-containers-order-js" */),
  "component---src-containers-order-success-js": () => import("./../../../src/containers/order-success.js" /* webpackChunkName: "component---src-containers-order-success-js" */),
  "component---src-containers-projects-js": () => import("./../../../src/containers/projects.js" /* webpackChunkName: "component---src-containers-projects-js" */),
  "component---src-containers-service-js": () => import("./../../../src/containers/service.js" /* webpackChunkName: "component---src-containers-service-js" */),
  "component---src-containers-spec-js": () => import("./../../../src/containers/spec.js" /* webpackChunkName: "component---src-containers-spec-js" */)
}


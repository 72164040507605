import React from 'react';
import { Image, Video } from 'elements';
import Tag from './Tag';

const getTagsFromString = (str) => {
  if (typeof str === 'string') {
    return str.split(',').map((item) => item.trim());
  } else {
    return [];
  }
};

const getBackground = (image, video) => {
  if (video) {
    return <Video src={[video]} autoPlay={true} loop={true} preload={'metadata'} />;
  } else if (image) {
    return <Image src={image} />;
  }
  return '';
};

const Case = ({ item, cl }) => {
  const theme = item.nnx_theme ?? 'dark';
  const image = item.nnx_image;
  const video = item.nnx_video;
  return (
    <a className={cl('case') + ' ' + theme} href={item.redirect ? item.redirect : item.uri}>
      <div className={cl('top')}>
        <div className={cl('tags')}>
          {getTagsFromString(item.nnx_tags).map((item, i) => (
            <Tag item={item} cl={cl} key={'dt' + i} />
          ))}
        </div>
        <div className={cl('title')} dangerouslySetInnerHTML={{ __html: item.nnx_title }} />
        <div className={cl('background')}>{getBackground(image, video)}</div>
      </div>
      <div className={cl('bottom')}>
        <div className={cl('annotation')} dangerouslySetInnerHTML={{ __html: item.nnx_description }} />
      </div>
    </a>
  );
};

export default Case;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { classnames, classname } from 'utils';

import './Grid.css';

/**
 * Сетка
 */
class Grid extends Component {
  cl = classname('grid');

  static propTypes = {
    /** Дополнительный класс */
    className: PropTypes.string,
    /** Список элементов */
    children: PropTypes.arrayOf(
      PropTypes.shape({
        size: PropTypes.oneOf(['s', 'mw', 'mh', 'l']),
        content: PropTypes.node.isRequired
      }).isRequired
    ),
    /** Смешение сетки (для главной страницы) */
    main: PropTypes.bool,
    /** Количество колонок */
    columns: PropTypes.oneOf([2, 3])
  };

  static defaultProps = {
    children: [],
    columns: 3
  };

  render() {
    const { children, main, className, border, columns } = this.props;

    const classes = classnames(className, this.cl({ border, main, columns }));

    return (
      <div className={classes}>
        {/* Блок для смещения */}
        {(main || (children.length === 2 && columns === 3)) && (
          <div className={this.cl('item', { empty: true, space: true })} />
        )}

        {/* Список элементов */}
        {children.map(({ size = 's', content }, index) => {
          return (
            <div className={this.cl('item', { size })} key={index}>
              <div className={this.cl('inner')}>{content}</div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default Grid;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Block from './Graph.style';
import { createImage } from 'utils';

class Graph extends PureComponent {
  static propTypes = {
    image: PropTypes.string.isRequired
  };

  render() {
    const { image } = this.props;

    return (
      <Block>
        <Block.Image src={createImage(image, 920)} />
      </Block>
    );
  }
}

export default Graph;

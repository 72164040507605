import { css } from 'styled-components';

const tools = {};

tools.flexCenter = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

tools.bgDefault = css`
  background-position: center;
  background-repeat: no-repeat;
`;

tools.flex = css`
  display: flex;
  align-items: center;
`;

tools.absolute = css`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

tools.image = (src = '', size = 'contain') => css`
  background-size: ${size};
  background-image: url(${src});
  ${tools.bgDefault}
`;

tools.applyModifiers = function(_props, modifiers) {
  const props = _props.modifiers;
  const keys = props ? Object.keys(props) : null;

  return keys
    ? keys.reduce((acc, key) => {
        if (key in props) {
          const style = modifiers[key] ? modifiers[key][props[key]] : null;
          if (style) {
            acc = `
              ${acc}
              ${style}
            `;
            if (typeof props.state !== 'undefined') {
              const state = style[props.state];
              if (state) {
                acc = `
                  ${acc}
                  ${state}
                `;
              }
            }
          }
        }
        return acc;
      }, '')
    : '';
};

export default tools;

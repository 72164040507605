import React from 'react';

const replaceSlideText = (str = '') => {
  const REGEXP = /\[([\D]{1,})\]/;
  return str.replace(REGEXP, '<span>$1</span>');
};

const Slide = ({ slide, cl }) => {
  const { position, text, link } = slide;

  return (
    <>
      <a href={link} target={'_blank'}>
        <div className={cl('slide-position-wrapper')}>
          <div className={cl('slide-position')} dangerouslySetInnerHTML={{ __html: position }} />
          <div className={cl('slide-position-text')}>место</div>
        </div>

        <div className={cl('slide-text')} dangerouslySetInnerHTML={{ __html: replaceSlideText(text) }} />
      </a>
    </>
  );
};

export default Slide;

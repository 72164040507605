import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { classnames, classname, raw } from 'utils';
import './Rating.css';

/**
 * Рейтинг
 */
class Rating extends Component {
  cl = classname('rating');

  static propTypes = {
    /** Дополнительный класс */
    className: PropTypes.string,
    /** Место */
    number: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    /** Название */
    name: PropTypes.string.isRequired,
    /** Ссылка */
    url: PropTypes.string.isRequired
  };

  render() {
    const { className, number, name, url } = this.props;

    const classes = classnames(this.cl(), className);

    return (
      <a href={url} target={'_blank'} className={classes}>
        <div className={this.cl('number')} {...raw(number)} />
        <div className={this.cl('name')} dangerouslySetInnerHTML={{ __html: name }} />
      </a>
    );
  }
}

export default Rating;

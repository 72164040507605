import React, { Component } from 'react';
import { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import { withPrefix } from 'gatsby';

import { Heading, Button, Icon } from 'elements';
import { Tags } from 'components';

import Block from './Head.style';
import tokens from './Head.tokens';

/**
 * Шапка страницы
 */
class Head extends Component {
  static propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    buttons: PropTypes.shape({
      text: PropTypes.string.isRequired,
      icon: PropTypes.string,
      url: PropTypes.string,
      onClick: PropTypes.func
    }),
    image: PropTypes.string,
    siteLink: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string),
    date: PropTypes.string,
    address: PropTypes.string,
    timeToRead: PropTypes.string,
    theme: PropTypes.oneOf(['light', 'green', 'dark'])
  };

  static defaultProps = {
    theme: 'light'
  };

  render() {
    const { title, timeToRead, tags, image, date, address, theme, button } = this.props;

    const props = { theme };

    return (
      <ThemeProvider theme={{ props }}>
        <Block>
          <Block.Wrapper>
            {image && <Block.Background image={withPrefix(image)} />}
            <Block.Heading>
              <Heading as={'h1'}>{title}</Heading>
            </Block.Heading>
            <Block.Info>
              {/* Кнопка */}
              {button && (
                <Block.Link>
                  <Button
                    color={tokens[theme].button}
                    url={button.url}
                    icon={button.icon}
                    onClick={button.onClick}
                    size="s"
                  >
                    {button.text}
                  </Button>
                </Block.Link>
              )}
              {tags && (
                <Tags>
                  {tags.map((tag, index) => (
                    <Button color="gray_50" size="s" weight="normal" key={index}>
                      {tag}
                    </Button>
                  ))}
                </Tags>
              )}
            </Block.Info>
            {timeToRead && (
              <Block.Info>
                <Icon name="time" />
                <Block.InfoText>{timeToRead}</Block.InfoText>
              </Block.Info>
            )}
            {date && (
              <Block.Info>
                <Icon name="calendar" />
                <Block.InfoText>{date}</Block.InfoText>
              </Block.Info>
            )}
            {address && (
              <Block.Info>
                <Icon name="mark" />
                <Block.InfoText>{address}</Block.InfoText>
              </Block.Info>
            )}
          </Block.Wrapper>
        </Block>
      </ThemeProvider>
    );
  }
}

export default Head;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Block from './Tags.style';

/**
 * Теги
 */
class Tags extends PureComponent {
  static propTypes = {
    children: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired
      })
    )
  };

  static defaultProps = {
    children: []
  };

  render() {
    const { children } = this.props;

    return (
      <Block>
        {children.map(item => (
          <Block.Item>{item}</Block.Item>
        ))}
      </Block>
    );
  }
}

export default Tags;

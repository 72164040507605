import React, { PureComponent } from 'react';
import { classnames, classname } from 'utils';

import { Heading, Image } from 'elements';
import { raw } from 'utils';

import './HelloSimple.css';

/**
 * Простой первый экран
 */
export class HelloSimple extends PureComponent {
  cl = classname('hello-simple');

  render() {
    const { className, title, descr, video, background } = this.props;

    const classes = classnames(className, this.cl());
    const bgImage = background ? `url(https://static.nimax.ru${background})` : null;

    return (
      <div className={classes} style={{backgroundImage: bgImage}} >
        <div className={this.cl('intro')}>
        {title && (
          <h1 className={this.cl('title')}>
             <Heading lavel='1'>{title}</Heading>
          </h1>)}
          <div className={this.cl('wrapper-descr')}>
            <div className={this.cl('descr')} {...raw(descr)} />
          </div>  
        </div>
        {video && (
          <video
            className={classnames(this.cl('bg'))}
            src={'https://static.nimax.ru' + video[0]}
            muted="muted"
            loop={true}
            autoplay="autoplay"
            playsInline={true}
            loading={'lazy'}
          >
            {video.map(item => (
              <source key={item} src={'https://static.nimax.ru' + item} type={'video/' + item.split('.').pop()} />
            ))}
          </video>
        )}
      </div>
    );
  }
}

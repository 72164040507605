import React, { useState, useRef } from 'react';
import { Icon } from 'elements';
import { classnames, classname, raw } from 'utils';

import './ReviewsList.css';

/*import Button from 'static/images/accordeon-icon.svg'
import Full from 'static/images/feedback-full-icon.svg'*/

function ReviewsList({ data, className }) {
  const [activeItem, setActiveItem] = useState(null);
  const containerRef = useRef(null);

  const cl = classname('reviews-list');
  const classes = classnames(className, cl());

  function onClick(index) {
    [].forEach.call(containerRef.current.children, (item, idx, arr) => {
      const blockContent = item.lastElementChild;
      blockContent.style.height = 0;
      if (index === idx) {
        if (index !== activeItem) {
          blockContent.style.height = blockContent.scrollHeight + 'px';
        } else {
          blockContent.style.height = 0;
        }
      }
    });

    if (index === activeItem) {
      setActiveItem(null);
      return;
    }
    setActiveItem(index);
  }

  return (
    <div className={classes}>
      <div className={cl('list')} ref={containerRef}>
        {data.map((item, index) => {
          const active = index === activeItem;
          return (
            <div className={cl('item')} key={index}>
              <div className={cl('header')} onClick={onClick.bind(this, index)}>
                <div className={cl('info')}>
                  <div className={cl('name')}>{item.name}</div>
                  <div className={cl('position')} {...raw(item.position)} />
                </div>
                <div className={cl('button', { active: active })}>
                  <Icon className={cl('button-icon')} name={'arrowCircle'} />
                </div>
              </div>
              <div className={cl('content', { active: active })}>
                <div className={cl('wrapper')}>
                  <div className={cl('quote')} {...raw(item.title)} />
                  <div className={cl('review')} {...raw(item.describe)} />
                  <a className={cl('full')} href={item.full.link} target="_blank">
                    <span dangerouslySetInnerHTML={{ __html: item.full.text }} />
                    <Icon className={cl('full-icon')} name={'arrowCircle'} />
                  </a>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ReviewsList;

import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import createObserver from '../../../../utils/createObserver';
import CursorPlay from './CursorPlay';

const BottomVideo = ({ video, cl }) => {
  const src = 'https://static.nimax.ru' + video.src;
  const ref = useRef();

  const [time, setTime] = useState(null);
  const [progress, setProgress] = useState(0);
  const [newProgress, setNewProgress] = useState(0);
  const [isPlay, setIsPlay] = useState(false);
  const [clickToggle, setClickToggle] = useState(false);
  const [userStop, setUserStop] = useState(false);
  const [videoMuted, setVideoMuted] = useState(true);
  const [move, setMove] = useState(false);
  // const [showCursor, setShowCursor] = useState(true);

  const playVideo = (entries) =>
    ref.current &&
    entries.forEach((entry) => {
      if (entry.isIntersecting && userStop === false) {
        const playPromise = ref.current.play();
        if (playPromise !== undefined) {
          playPromise
            .then((_) => {
              setTime(ref.current.duration);
              setIsPlay(true);
            })
            .catch((error) => {
              setTime(ref.current.duration);
              // setShowCursor(true);
            });
        }
      } else {
        ref.current.pause();
      }
    });

  const handleTimeUpdate = (e) => {
    if (e.target?.currentTime && !clickToggle && time > 0) {
      setProgress(Math.round((100 / time) * e.target?.currentTime));
    }
  };

  const handleChangeProgress = (e) => {
    if (e.target?.value && ref.current) {
      ref.current.pause();
      setProgress(Number(e.target.value));
    }
  };

  const handleMouseDown = () => {
    if (ref.current) {
      setClickToggle(true);
      setTimeout(() => {
        ref.current.pause();
        setIsPlay(false);
      }, 100);
    }
  };

  const handleMouseUp = () => {
    if (ref.current) {
      setClickToggle(false);
      setTimeout(() => {
        ref.current.play().then(() => {
          setIsPlay(true);
          // setShowCursor(false);
        });
      }, 100);
    }
  };

  const calculateDuration = (num, progress = 100) => {
    try {
      let minutes = 0;
      let seconds = (num / 100) * progress;
      while (seconds > 60) {
        seconds = seconds - 60;
        minutes = minutes + 1;
      }
      seconds = Math.round(seconds);
      if (seconds < 10) {
        seconds = `0${seconds}`;
      }
      return `${minutes}:${seconds}`;
    } catch (error) {
      return '0:00';
    }
  };

  const handleVideoClick = () => {
    if (videoMuted && ref.current) {
      setVideoMuted(false);
      ref.current.muted = false;
    } else if (isPlay && ref.current) {
      ref.current.pause();
      setIsPlay(false);
      // setShowCursor(true);
      setUserStop(true);
    } else {
      const playPromise = ref.current.play();
      if (playPromise !== undefined) {
        setIsPlay(true);
        // setShowCursor(false);
        setUserStop(false);
      }
    }
  };

  const handleMouseMove = () => {
    setMove(true);
  };

  useEffect(() => {
    if (clickToggle) {
      const timeId = setTimeout(() => {
        setNewProgress((time / 100) * progress);
      }, 100);

      return () => {
        clearTimeout(timeId);
      };
    }
  }, [progress]);

  useEffect(() => {
    if (ref.current) {
      ref.current.currentTime = Number(newProgress);
    }
  }, [newProgress]);

  useLayoutEffect(() => {
    const videoObserver = createObserver(playVideo, { threshold: 0.2 });
    videoObserver.observe(ref.current);
    return () => videoObserver.disconnect();
  }, [userStop]);

  useEffect(() => {
    let timeId;
    if (move) {
      timeId = setTimeout(() => {
        setMove(false);
      }, 3000);
    }
    return () => {
      clearTimeout(timeId);
    };
  }, [move]);

  return (
    <div className={`${cl('bottom-video-wrapper')} ${move ? 'move' : ''}`} onMouseMove={handleMouseMove}>
      <CursorPlay className={cl()} isMuted={videoMuted} isPlay={isPlay}>
        <video
          muted={true}
          ref={ref}
          className={cl('bottom-video')}
          onTimeUpdate={handleTimeUpdate}
          onClick={handleVideoClick}
          preload={'metadata'}
          controls={false}
          playsInline={true}
        >
          <source src={src} type={'video/' + src.split('.').pop()} />
        </video>
      </CursorPlay>
      <div className={cl('controls-wrapper')}>
        {time > 0 && (
          <div className={cl('controls-inner')}>
            <div className={cl('controls-tap-wrapper')}>
              <input
                type={'range'}
                min={0}
                max={100}
                step={1}
                value={progress}
                className={cl('controls-tap')}
                onChange={handleChangeProgress}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onTouchMove={handleMouseDown}
                onTouchEnd={handleMouseUp}
              />
              <div
                className={cl('controls-current-time')}
                style={{ left: `calc(${progress}% - ${(44 / 100) * progress}px)`, opacity: clickToggle ? 1 : 0 }}
              >
                {calculateDuration(time, progress)}
              </div>
            </div>
            <div className={cl('controls-duration')} style={{ opacity: clickToggle ? 0 : 1 }}>
              {calculateDuration(time, 100 - progress)}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BottomVideo;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { classnames, classname } from 'utils';
import * as icons from './static';
import './Icon.css';

/**
 * Иклгка
 */
class Icon extends Component {
  cl = classname('icon');

  static propTypes = {
    /** Дополнительный класс */
    className: PropTypes.string,
    /** Название */
    name: PropTypes.oneOf(Object.keys(icons)).isRequired,
    /** Размер */
    size: PropTypes.oneOf(['fit', 1, 2, 3]),
    /** Поворот */
    rotate: PropTypes.oneOf([0, 90, 180, 270])
  };

  static defaultProps = {
    size: 1,
    rotate: 0
  };

  render() {
    const { className, name, size, rotate } = this.props;
    if (!icons[name]) {
      alert(name);
    }

    const classes = classnames(className, this.cl({ size, rotate }));

    const { width, height, content } = icons[name];

    const viewBox = `0 0 ${width} ${height}`;

    return (
      <svg className={classes} viewBox={viewBox} style={{ '--icon__width': width, '--icon__height': height }}>
        {content}
      </svg>
    );
  }
}

export default Icon;

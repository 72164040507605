import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Heading, Icon } from 'elements';
import { classnames, classname, raw } from 'utils';

import './Download.css';

export class Download extends PureComponent {
  cl = classname('download');

  render() {
    const { className, title, links } = this.props;

    const classes = classnames(className, this.cl());

    return (
      <div className={classes}>
        <div className={this.cl('heading')}>
          <Heading level={1}>{title}</Heading>
        </div>
        <div className={this.cl('links')}>
          {links.map((link) => {
            const icon = link.icon ? link.icon : 'doc';
            return (
              <div className={this.cl('links-item')}>
                <div className={this.cl('links-item-icon')}>
                  <Icon name={icon} size="fit" />
                </div>
                <a href={link.url}>{link.title}</a>
              </div>
            )
          })}
        </div>
      </div>
    );
  }
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'elements';
import Block from './Share.Style';
import { copyToClipboard } from 'utils';

export default class Share extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string,
    image: PropTypes.string
  };
  static defaultProps = {
    title: 'Nimax',
    text: 'Текст'
  };
  state = {
    copied: false
  };
  timer = null;
  componentWillUnmount() {
    clearTimeout(this.timer);
  }
  share(type) {
    const { title, text, image } = this.props;
    const url = window.location.href;
    let share = '';

    if (type === 'vk') {
      share = `http://vkontakte.ru/share.php?url=${url}`;
    }

    if (type === 'fb') {
      // костыль со стака, в идеале сделать og: теги
      share = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
    }

    if (type === 'tw') {
      share = `http://twitter.com/share?url=${url}`;
    }

    const win = window.open(share, '_blank');
    win.focus();
  }
  onCopy(e) {
    if (this.state.copied) return;
    copyToClipboard(window.location.href);
    this.setState({
      copied: true
    });
    this.timer = setTimeout((e) => {
      this.setState({
        copied: false
      });
    }, 3000);
  }
  render() {
    return (
      <Block>
        <Block.Copied active={this.state.copied}>
          <Block.CopiedInner>Ссылка скопирована</Block.CopiedInner>
        </Block.Copied>
        <Block.Wrapper>
          <Block.Button onClick={(e) => this.share('vk')}>
            <Block.Icon>
              <Icon name="vk" />
            </Block.Icon>
          </Block.Button>

          {/* <Block.Button onClick={e => this.share('fb')}>
            <Block.Icon>
              <Icon name="facebook" />
            </Block.Icon>
          </Block.Button> */}

          <Block.Button onClick={(e) => this.share('tw')}>
            <Block.Icon>
              <Icon name="twitter" />
            </Block.Icon>
          </Block.Button>

          <Block.Button onClick={(e) => this.onCopy()}>
            <Block.Success>
              <Block.Icon hide={!this.state.copied}>
                <Icon name="tick" />
              </Block.Icon>
            </Block.Success>
            <Block.Icon hide={this.state.copied}>
              <Icon name="anchor" />
            </Block.Icon>
          </Block.Button>
        </Block.Wrapper>
      </Block>
    );
  }
}

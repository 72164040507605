import React, { Component } from 'react';
import {Figure} from 'components';
import { classname, classnames } from 'utils';
import { Swiper, Navigation, Pagination } from 'swiper/js/swiper.esm';
import ReactIdSwiperCustom from 'react-id-swiper/lib/ReactIdSwiper.custom';

import './Slider.css';

class CustomBuildSwiper extends Component {
  cl = classname('slider');

  static defaultProps =  {
    Swiper,
    modules: [Navigation, Pagination],
    slidesPerView: 1,
    effect: 'fade',
    shouldSwiperUpdate: true,
    speed: 600,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      type: 'fraction'
    },
    navigation: {
      nextEl: '.slider__button-next',
      prevEl: '.slider__button-prev',
    },
    containerClass: "slider",
    centeredSlides: true,
  }

  render () {
    const {slides, ...props} = this.props
    
    return(
      <ReactIdSwiperCustom {...props} className={this.cl()}>
        {slides.map( (slide) => {
          return (
            <div className={this.cl('slide')}>
              <div className={this.cl('slide-content')}>
                <Figure
                  image={slide.image}
                  video={slide.video && [slide.video]} 
                  cover="area"
                  autoPlay={slide.video && false}
                />
              </div>
              <div className={this.cl('slide-text')} dangerouslySetInnerHTML={{__html: slide.text }} />
            </div>
          )
        })}
      </ReactIdSwiperCustom>
    )
  }
}

export default CustomBuildSwiper;
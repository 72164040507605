import React, { PureComponent } from 'react';
import {Link} from 'gatsby'

import { Video, Image, Icon } from 'elements';
import { Figure } from 'components';
import { classnames, classname, raw } from 'utils';

import './ContentProject.css';

export class ContentProject extends PureComponent {
  cl = classname('content-project');

  static defaultProps = {
    background: '#fff'
  };

  render() {
    const { className, title, logo, image, video, background, link } = this.props;
    const autoplay = !(/^http/.test(video));

    const classes = classnames(className, this.cl());

    return (
      <div className={classes} style={{ background: background }}>
        <Link to={link} className={this.cl('wrapper')}>
          <div className={this.cl('header')}>
            <div className={this.cl('info')}>
              <div className={this.cl('label')}>Смотрите кейс</div>
              <div className={this.cl('title')}>
                <div dangerouslySetInnerHTML={{ __html: title.replace('[', '<span>').replace(']', '</span>') }} />
                <div className={this.cl('icon')}>
                  <Icon name={'cursor'} />
                </div>
              </div>
            </div>
            <div className={this.cl('logo')}>
              <Image src={logo} width={69} height={69} />
            </div>
          </div>
          <div className={this.cl('image')}>
            {video ? 
              <Figure
                image={image}
                video={[video]}
                cover="area"
                autoplay={autoplay}
              />
              :               
              <Image src={image} />
            }
          </div>
        </Link>
      </div>
    );
  }
}

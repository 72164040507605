import React from 'react';
import styled from 'styled-components';

const Header = styled.h2`
  font-size: 84px;

  line-height: 90px;
  letter-spacing: 0px;
  color: #ffffff;
`;

const IndustryTitles = ({ children }) => {
  return <h2>{children}</h2>;
};

export default IndustryTitles;

import styled, { css } from 'styled-components';

const data = {
  size: {
    s: 12,
    m: 16,
    l: 18,
    xl: 24
  },
  weight: {
    normal: 'normal',
    bold: 'bold',
    black: '900'
  },
  theme: {
    green_500: {
      normal: 'green_500',
      hover: 'green_700'
    },
    green_500_light: {
      normal: 'green_500',
      hover: 'black_500'
    },
    green_300: {
      normal: 'green_300',
      hover: 'white_500'
    },
    white_500: {
      normal: 'white_500',
      hover: 'gray_500'
    },
    black_500: {
      normal: 'black_500',
      hover: 'green_500'
    },
    gray_100: {
      normal: 'gray_100',
      hover: 'green_500'
    },
    black_white: {
      normal: 'black_500',
      hover: 'white_500'
    }
  }
};

const Block = styled.div`
  ${({ theme: { media, color, transition, props } }) => css`
        display: inline-block;
        position: relative;
        ${
          props.color &&
          css`
            color: ${color[data.theme[props.color].normal]};
          `
        }
        
        ${
          props.size &&
          css`
            font-size: ${data.size[props.size]}px;
          `
        }
        ${props.weight && css``}
        ${
          props.interactive &&
          css`
            transition-property: color;
            transition-duration: ${transition.fast};
            &:hover {
              color: ${props.hover
                ? color[props.hover]
                : props.color
                ? color[data.theme[props.color].hover]
                : color.green_500};
              transition-duration: ${transition.normal};
            }
          `
        }
        &:before {
            content: '';
            z-index: 1;
            position: absolute;
            top: -.15em;
            left: -.3em;
            right: -.3em;
            bottom: -.15em;
        }
    `}
`;

Block.Inner = styled.div`
  display: flex;
`;

export default Block;

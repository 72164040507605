import React from 'react';

export default {
  width: 36,
  height: 36,
  content: (
    <path
      d="M2 2l16 16L2 34M34 34L18 18 34 2"
      stroke="currentColor"
      strokeWidth="2px"
      fill="none"
      vectorEffect="non-scaling-stroke"
    />
  )
};

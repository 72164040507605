import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { classnames, classname, createImage } from 'utils';
import './Background.css';

class Background extends Component {
  cl = classname('background');

  static propTypes = {
    bgColor: PropTypes.string,
    bgImage: PropTypes.string,
    bgGradient: PropTypes.string,
    joinTop: PropTypes.bool,
    joinBottom: PropTypes.bool
  };

  static defaultProps = {
    bgImage: 'none',
    bgColor: 'transparent',
    bgGradient: 'none'
  };

  render() {
    const { className, bgColor, bgImage, children, bgGradient, joinTop, joinBottom } = this.props;

    const classes = classnames(className, this.cl());

    return (
      <div
        className={classes}
        style={{ backgroundColor: bgColor, backgroundImage: `url(${createImage(bgImage, 1760)}), ${bgGradient}` }}
        data-join-top={joinTop}
        data-join-bottom={joinBottom}
      >
        {children}
      </div>
    );
  }
}
export default Background;


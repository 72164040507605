import React from 'react'
import PropTypes from 'prop-types';
import s from './Increase.module.css'

const Increase = ({ type, from, to, text, align }) => {
  return (
    <div className={`${s.wrapper} ${align && s.center}`}>
      <div className={s.inner}>
        <span className={s.arrow}>
          {from}{type}
        </span>
        <span>
          {to}{type}
        </span>
      </div>
      <p className={s.text}>{text}</p>
    </div>
  )
}

Increase.propTypes = {
  type: PropTypes.string,
  from: PropTypes.string,
  to: PropTypes.string,
  text: PropTypes.string,
  align: PropTypes.string,
}

export default Increase;

import React from 'react';
import Tags from './Tags';
import { Image, Video } from 'elements';
import useWindowSize from '../../../../utils/hooks/useWindowSize';

const Case = ({ item, cl }) => {
  const { width, height } = useWindowSize();
  const theme = item.nnx_theme || 'dark';
  const image = item.nnx_image || item.image;
  const video = item.nnx_video || item.video;
  const whithBg = image || video ? 'no-bg' : '';

  const backgroundType = () => {
    if (video) {
      return (
        <Video src={[video]} srcMobile={[video]} autoplay={true} loop={true} muted={true} className={cl('image')} />
      );
    } else if (image) {
      return (
        <Image src={image} className={cl('image')} width={Math.floor(width / 2)} height={Math.floor(height / 2)} />
      );
    }
  };
  // Для мобильной версии другая верстка
  // PS. Лень было писать универсальную :)
  if (width > 412) {
    return (
      <a
        className={cl(`case ${theme} ${whithBg}`)}
        href={item.redirect ? item.redirect : item.uri}
        target={item.redirect ? '_blank' : ''}
      >
        <div className={cl('index')}>
          <div className={cl('tags')}>
            <Tags tags={item.nnx_tags} className={cl('tag')} />
          </div>
          <div className={cl('title')} dangerouslySetInnerHTML={{ __html: item.nnx_title }} />
        </div>
        <div className={cl('annotation_wrapper')}>
          {item.nnx_description && (
            <div className={cl('annotations')} dangerouslySetInnerHTML={{ __html: item.nnx_description }} />
          )}
          {item.nnx_description_1 && (
            <div className={cl('annotations')} dangerouslySetInnerHTML={{ __html: item.nnx_description_1 }} />
          )}
        </div>
        {backgroundType()}
      </a>
    );
  } else {
    return (
      <a
        className={cl(`case ${theme} ${whithBg}`)}
        href={item.redirect ? item.redirect : item.uri}
        target={item.redirect ? '_blank' : ''}
      >
        <div className={cl('index')}>
          <div className={cl('title')} dangerouslySetInnerHTML={{ __html: item.nnx_title }} />
        </div>
        <div className={cl('tags')}>
          <Tags tags={item.nnx_tags} className={cl('tag')} />
        </div>
        {backgroundType()}
      </a>
    );
  }
};

export default Case;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { classnames, classname, raw } from 'utils';
import {Icon} from 'elements';
import './ContentSteps.css';

class ContentSteps extends Component {
  cl = classname('content-steps');

  static propTypes = {
    /** Дополнительный класс */
    className: PropTypes.string
  };

  render() {

    const { className, children, right } = this.props;

    const classes = classnames(className, this.cl({'right': right}));

    return (
      <div className={classes}>
        {children.map((item) => {
          return (
            <div className={this.cl('item')}>
                {item.number && <div className={this.cl('number')} {...raw(item.number)} />}
                <div className={this.cl('info')}>
                  {item.title && <div className={this.cl('title')} {...raw(item.title)} />}
                  {item.text && <div className={this.cl('text')} {...raw(item.text)} />}
                  {(item.url && item.link) && 
                    <a href={item.url} className={this.cl('url')}>
                      <div className={this.cl('icon')}>
                        <Icon name={'attach'} />
                      </div>
                      <div className={this.cl('link')} {...raw(item.link)} />
                    </a>
                  }
                </div>  
            </div>
          )
        })
      }
    </div>
  )};
};

export default ContentSteps;
const getCoords = (el) => {
  const elRect = el.getBoundingClientRect();
  return {
    top: elRect.top + window.pageYOffset,
    right: elRect.right + window.pageXOffset,
    bottom: elRect.bottom + window.pageYOffset,
    left: elRect.left + window.pageXOffset
  };
};

const setAnnotationCoords = (elCoords, blockWidth) => {
  const { innerWidth } = window;
  const rightOut = elCoords.left + blockWidth - innerWidth;
  return {
    bottom: elCoords.bottom + 10,
    left: rightOut > 0 ? elCoords.left - rightOut - 20 : elCoords.left - 20
  };
};

const createAnnotationListener = (target) => {
  const body = document.getElementById('___gatsby');
  let show = false;
  target.addEventListener('mouseenter', (e) => {
    const { target } = e;
    const text = target.dataset.title;
    const div = document.createElement('div');
    div.className = 'dark_wrapper-annotation';
    div.innerHTML = text;
    div.style.cssText = 'position:absolute; opacity: 0;';
    const point = document.createElement('div');
    point.classList = 'point';
    div.append(point);
    body.append(div);
    console.dir();
    const linkCoords = getCoords(target);
    const annotationCoords = setAnnotationCoords(linkCoords, div.clientWidth);
    point.style.left = linkCoords.left - annotationCoords.left + target.offsetWidth / 2 - point.offsetWidth / 2 + 'px';
    div.style.left = annotationCoords.left + 'px';
    div.style.top = annotationCoords.bottom + 'px';
    div.style.opacity = 1;
    show = true;
  });

  target.addEventListener('mouseleave', () => {
    const annotations = [...body.querySelectorAll('.dark_wrapper-annotation')];
    annotations.forEach((target) => {
      target.remove();
    });
  });

  window.addEventListener('touchmove', () => {
    if (show) {
      const annotations = [...body.querySelectorAll('.dark_wrapper-annotation')];
      annotations.forEach((target) => {
        target.remove();
      });
      show = false;
    }
  });
};

export default createAnnotationListener;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

class PreloadImageContainer extends Component {
  state = {
    imgs: [],
    loading: true
  };

  componentDidMount() {
    const { images } = this.props;
    const promises = images.map((i) => this.loadImage(i));
    Promise.all(promises)
      .then((imgs) => {
        this.setState((prevState) => ({ ...prevState, imgs, loading: false }));
      })
      .catch((err) => {
        console.log(err);
      });
  }

  loadImage = (src) => {
    return new Promise(function (resolve, reject) {
      const img = new Image(600, 600);
      img.src = src;
      img.onload = function () {
        resolve(src);
      };
      img.onerror = img.onabort = function (err) {
        reject(err);
      };
    });
  };

  render() {
    const { render: View } = this.props;
    const { imgs, loading } = this.state;

    return !loading ? <View frames={imgs} /> : null;
  }
}

PreloadImageContainer.propTypes = {
  render: PropTypes.func,
  images: PropTypes.array
};

export default PreloadImageContainer;

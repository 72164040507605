import React from 'react';
import { classname } from 'utils';
import Swiper from 'react-id-swiper';
import Slide from './components/Slide';
import './dark-ratings-slider-new.css';
import DarkCursor from '../DarkCursor/DarkCursor';

const DarkRatingsSliderNew = ({ content = [] }) => {
  const cl = classname('dark-ratings-slider-new');

  const propsTop = {
    slidesPerView: 'auto',
    centeredSlides: false,
    initialSlide: 0,
    slideToClickedSlide: true,
    grabCursor: true
    // autoplay: {
    //   delay: 3000,
    //   pauseOnMouseEnter: true
    // }
  };

  return (
    <div className={cl('wrapper-line')}>
      <div className={cl('wrapper')}>
        <div className={cl('top-wrapper')}>
          <DarkCursor>
            <Swiper {...propsTop}>
              {content.map((slide, i) => (
                <div className={cl('slide')} key={'tp-rt-sl' + i}>
                  <Slide slide={slide} cl={cl} />
                </div>
              ))}
            </Swiper>
          </DarkCursor>
        </div>
      </div>
    </div>
  );
};

export default DarkRatingsSliderNew;

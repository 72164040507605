import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { classnames, classname } from 'utils';

import { Icon } from 'elements';

import './FormStatus.css';

/**
 * Статус формы
 */
class FormStatus extends Component {
  cl = classname('form-status');

  static propTypes = {
    /** Дополнительный класс */
    className: PropTypes.string,
    /** Событие */
    onClose: PropTypes.func
  };

  static defaultProps = {
    onClick: () => {}
  };

  onClose(e) {
    const { onClose } = this.props;

    onClose();

    e.preventDefault();
  }

  render() {
    const { className } = this.props;

    const classes = classnames(className, this.cl());

    return (
      <div className={classes}>
        <div className={this.cl('area')}>
          <div className={this.cl('window')}>
            <div className={this.cl('title')}>
              Заявка отправлена
            </div>
            <div className={this.cl('caption')}>Свяжемся с вами в течение суток</div>
            <button type={'button'} className={this.cl('close')} onClick={e => this.onClose(e)}>
              <Icon name={'cancel'} size={'fit'} />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default FormStatus;

import React, { Component } from 'react';
import { Link } from 'gatsby';

import { GetServices } from 'query';
import { classnames, classname, raw } from 'utils';

import './AboutServices.css';

/**
 * Список услуг
 */
class AboutServices extends Component {
  cl = classname('about-services');

  render() {
    const { className } = this.props;

    const classes = classnames(className, this.cl());

    return (
      <div className={classes}>
        <GetServices>
          {data =>
            data.map(({ title, url }) => <Link to={url} key={title} className={this.cl('link')} {...raw(title)} />)
          }
        </GetServices>
      </div>
    );
  }
}

export default AboutServices;

import React from 'react';
import { clearNbsp } from '../../../../utils/clearNbsp';
import './annotation.css';

const Annotation = ({ str, tag, annotation, className, showHandler, hideHandler }) => {
  const bracketRegExp = /[\[\]]/g;
  const clearAnnotation = clearNbsp(annotation);

  return (
    <>
      <span data-attr={str} onMouseEnter={hideHandler} onMouseLeave={showHandler} className={className}>
        {str.replace(bracketRegExp, '')}
        {str === tag && <div className={'annotation_tag'}>{clearAnnotation}</div>}
      </span>
    </>
  );
};

export default Annotation;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { classnames, classname, raw } from 'utils';
import './Prolog.css';

/**
 * Пролог
 */
class Prolog extends PureComponent {
  cl = classname('prolog');

  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired
  };

  render() {
    const { className, children } = this.props;

    return <div className={classnames(this.cl(), className)} {...raw(children)} />;
  }
}

export default Prolog;

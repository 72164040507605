import styled from 'styled-components';

const Block = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-right: -8px;
  margin-bottom: -10px;
`;

Block.Item = styled.div`
  margin-right: 8px;
  margin-bottom: 10px;
`;

export default Block;

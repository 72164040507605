import React, { useState } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { classnames, classname } from 'utils';
import Case from './Components/Case';
import './cases.css';

const Cases = ({ className, feed = [], more, moreText }) => {
  const cl = classname('dark-performance-cases');
  const classNames = classnames(className, cl());
  const [show, setShow] = useState(false);

  moreText = moreText || 'все проекты perfomance-маркетинга';
  const showMoreText = 'еще проекты';

  const buttonHandler = () => {
    setShow(true);
  };

  return (
    <section className={classNames}>
      <div className={cl('wrapper')}>
        {feed.map((item, i) => (
          <Case key={'dpc-' + i} item={item} cl={cl} hide={i > 5 && !show} />
        ))}
      </div>
      {!show && (
        <a className={cl('button')} onClick={() => setShow(true)} dangerouslySetInnerHTML={{ __html: showMoreText }} />
      )}
      {show && <a className={cl('button')} href={more} dangerouslySetInnerHTML={{ __html: moreText }} />}
    </section>
  );
};

export default (props) => (
  <StaticQuery
    query={graphql`
      fragment CardProjectsNew on MdxConnection {
        edges {
          node {
            frontmatter {
              announce_title
              nnx_title
              nnx_tags
              nnx_description
              video
              image
              theme
              redirect
            }
            fields {
              id
              uri
              category
            }
          }
        }
      }

      query {
        feed: allMdx {
          ...CardProjectsNew
        }
      }
    `}
    render={({ feed: { edges: feed } }) => {
      const filterHandler = (el, values) => {
        const { id } = el.node.fields;
        return values.some((value) => value.id === id);
      };

      const mapHandler = (el, values) => {
        const { id } = el.node.fields;
        const value = values.find((value) => value.id === id);

        return {
          index: value.index,
          link: el.node.frontmatter.redirect ?? el.node.fields.uri ?? '/',
          image: value.image ?? el.node.frontmatter.image,
          video: value.video ?? el.node.frontmatter.video,
          title: value.title ?? el.node.frontmatter.nnx_title ?? '',
          theme: value.theme ?? el.node.frontmatter.theme ?? 'dark',
          tags: value.tags ?? el.node.frontmatter.nnx_tags,
          description: value.description ?? el.node.frontmatter.nnx_description ?? ''
        };
      };

      const indexHandler = (el, i) => {
        if (typeof el === 'string') {
          return {
            id: el,
            index: i
          };
        } else {
          return {
            ...el,
            index: i
          };
        }
      };

      const sortHandler = (a, b) => a.index - b.index;

      const selectedCases = props.feed.map(indexHandler);
      const outputCases = feed
        .filter((el) => filterHandler(el, selectedCases))
        .map((el) => mapHandler(el, selectedCases))
        .sort(sortHandler);

      return <Cases {...props} feed={outputCases} />;
    }}
  />
);

import React from 'react';

export default {
  width: 32,
  height: 18,
  content: (
    <>
      <path
        fill="currentColor"
        d="M5.086 12.556V5.89H7.61c.504 0 .96.09 1.37.267a3.07 3.07 0 011.04.702c.284.297.5.65.648 1.058.154.403.231.83.231 1.28 0 .462-.077.898-.23 1.307a3.056 3.056 0 01-.65 1.066 3.113 3.113 0 01-1.04.72 3.398 3.398 0 01-1.369.267H5.086zm1.804-1.555h.694a1.303 1.303 0 001.075-.525c.119-.166.208-.358.267-.578.065-.219.098-.453.098-.702a2.3 2.3 0 00-.098-.675 1.69 1.69 0 00-.267-.56 1.211 1.211 0 00-.453-.374 1.303 1.303 0 00-.622-.142H6.89v3.556zM15.732 12.716c-.491 0-.942-.083-1.35-.249a3.041 3.041 0 01-1.05-.72 3.309 3.309 0 01-.666-1.11 4.173 4.173 0 01-.24-1.44c0-.516.08-.988.24-1.414.16-.433.382-.8.666-1.102.29-.303.64-.537 1.05-.703.408-.166.859-.249 1.35-.249.492 0 .942.083 1.351.25.41.165.756.4 1.04.702.29.302.516.67.676 1.102.16.426.24.898.24 1.413 0 .522-.08 1.002-.24 1.44-.16.433-.385.803-.676 1.111a2.95 2.95 0 01-1.04.72c-.409.166-.859.25-1.35.25zm0-1.546a1.3 1.3 0 001.094-.543c.272-.361.409-.838.409-1.43 0-.576-.137-1.038-.41-1.388a1.308 1.308 0 00-1.093-.533c-.462 0-.83.178-1.102.533-.267.35-.4.812-.4 1.387 0 .593.133 1.07.4 1.431.273.362.64.542 1.102.542zM23.834 12.716c-.492 0-.94-.08-1.343-.24a2.985 2.985 0 01-1.03-.693 3.175 3.175 0 01-.65-1.102 4.295 4.295 0 01-.23-1.45c0-.544.076-1.033.23-1.466.16-.438.38-.806.658-1.102a2.828 2.828 0 011.031-.694c.403-.16.848-.24 1.334-.24.42 0 .782.05 1.084.152.308.094.563.219.765.373.207.154.37.326.489.515.118.19.198.377.24.56v.694h-1.458a.858.858 0 00-.347-.534c-.184-.142-.438-.213-.764-.213-.427 0-.777.163-1.05.489-.272.32-.408.806-.408 1.458 0 .634.133 1.117.4 1.449.272.326.625.489 1.058.489.32 0 .577-.077.773-.231a.992.992 0 00.382-.552h1.458v.694a1.838 1.838 0 01-.293.64 2.15 2.15 0 01-.543.515c-.219.148-.48.267-.782.356a3.552 3.552 0 01-1.004.133z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M26.667 1.89H5.333a3.556 3.556 0 00-3.555 3.555v7.11a3.555 3.555 0 003.555 3.556h21.334a3.555 3.555 0 003.555-3.555V5.445a3.556 3.556 0 00-3.555-3.556zM5.333.11A5.333 5.333 0 000 5.445v7.11a5.333 5.333 0 005.333 5.334h21.334A5.333 5.333 0 0032 12.556V5.445A5.333 5.333 0 0026.667.11H5.333z"
        clipRule="evenodd"
      />
    </>
  )
};

import React from 'react';
import { classnames, classname, raw } from 'utils';
import Background from './components/Background';
import Description from './components/Description';
import Title from './components/Title';

import './first-screen.css';

const FirstScreen = ({ className, title, department, tags, description, video }) => {
  const cl = classname('dark-performance-first-screen');
  const classNames = classnames(className, cl());
  return (
    <section className={classNames}>
      <div className={cl('flow')}>
        <Title title={title} cl={cl} />
        <Description description={description} department={department} tags={tags} cl={cl} />
      </div>
      <Background video={video} cl={cl} />
    </section>
  );
};

export default FirstScreen;

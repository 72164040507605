import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { classname, classnames, raw } from 'utils';
import './Factoid.css';

/**
 * Фактойд
 */
class Factoid extends Component {
  cl = classname('factoid');

  static propTypes = {
    /** Размер */
    className: PropTypes.string,
    /** Размер */
    size: PropTypes.oneOf(['s', 'm', 'l']),
    /** Заголовок */
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /** Содержимое */
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    /** Подпись */
    caption: PropTypes.string,
    /** Цвет */
    color: PropTypes.oneOf(['black', 'green', 'white'])
  };

  static defaultProps = {
    size: 's',
    color: 'black'
  };

  render() {
    const { className, title, content, caption, size, color } = this.props;

    const classes = classnames(className, this.cl({ size, color, title }));

    return (
      <div className={classes}>
        {title && <div className={this.cl('title')} {...raw(title)} />}
        {content && <div className={this.cl('content')} {...raw(content)} />}
        {caption && <div className={this.cl('caption')} {...raw(caption)} />}
      </div>
    );
  }
}

export default Factoid;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Card } from 'components';
import { Button } from 'elements';
import { classname, raw } from 'utils';

import './CardProject.css';

/**
 * Карточка проекта
 */
class CardProject extends Component {
  cl = classname('card-project');

  static propTypes = {
    ...Card.propTypes,
    /** Скоро в портфолио */
    soon: PropTypes.bool,
    /** Анонс */
    announce: PropTypes.string
  };

  static defaultProps = {
    type: 'Кейс'
  };

  render() {
    const { soon, announce, ...props } = this.props;

    if (soon) {
      props.url = null;
    }

    return (
      <Card className={this.cl()} {...props}>
        {announce && <div className={this.cl('announce')} {...raw(announce)} />}
        {soon && (
          <div className={this.cl('wrapper')}>
            <Button className={this.cl('soon')}>Скоро в портфолио</Button>
          </div>
        )}
      </Card>
    );
  }
}

export default CardProject;

import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

export const GetCategory = ({ children, id }) => (
  <StaticQuery
    query={graphql`
      {
        allCategoriesJson {
          nodes {
            title
            feed
            description
            seo_title
            seo_h1
            parent {
              ... on File {
                absolutePath
                name
                sourceInstanceName
                modifiedTime
              }
            }
          }
        }
      }
    `}
    render={res => children(res.allCategoriesJson.nodes.find(item => item.parent.name === id))}
  />
);

import React from 'react';
import { classname } from 'utils';

import './dark-work-format.css';

const DarkWorkFormat = ({ title = '', content = [] }) => {
  const cl = classname('dark-format-work');

  return (
    <div className={cl('wrapper')}>
      <div className={cl('title-wrapper')}>
        <div className={cl('title')} dangerouslySetInnerHTML={{ __html: title }} />
      </div>
      <div className={cl('content-wrapper')}>
        {content.map((item, i) => (
          <div className={cl('content-item')} key={'fw-' + i}>
            <div className={cl('content-title-wrapper')}>
              <div className={cl('content-title')} dangerouslySetInnerHTML={{ __html: item.title }} />
            </div>
            <div className={cl('content-text-wrapper')}>
              <div className={cl('content-text')} dangerouslySetInnerHTML={{ __html: item.text }} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DarkWorkFormat;

import { Component } from 'react';
import PropTypes from 'prop-types';

/**
 * Почта
 */
class Email extends Component {
  state = {
    email: 'order@nimax.ru'
  };

  static propTypes = {
    children: PropTypes.func.isRequired,
    email: PropTypes.string
  };

  componentDidMount() {
    if (!process.browser) return;

    let value = localStorage.getItem('email');

    if (value) {
      this.setState({
        email: value
      });
    }
  }

  render() {
    const { children, email } = this.props;

    return children(email || this.state.email);
  }
}

export default Email;

import React from 'react';

const ItemWrapper = ({ link, className, children }) => {
  return link ? (
    <a href={link} className={className}>
      {children}
    </a>
  ) : (
    <div className={className}>{children}</div>
  );
};

export default ItemWrapper;

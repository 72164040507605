import React from 'react';
import { Image } from 'elements';

const Technology = ({ item, cl, active }) => {
  const { image, imageActive, name } = item;
  return (
    <div className={cl('tech-item') + (active ? ' selected' : '')}>
      <div className={cl('tech-image')}>
        <Image src={image} className={active ? 'selected' : ''} />
        <Image src={imageActive} className={active ? 'color selected' : 'color'} />
      </div>
      <div className={cl('tech-name')}>{name}</div>
    </div>
  );
};

export default Technology;

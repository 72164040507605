import React from 'react';
import PropTypes from 'prop-types';
import * as S from './Navigation.styled';

const Navigation = ({ length, active, onClick }) => {
  const points = new Array(length).fill('');
  return (
    <S.Wrapper>
      {points.map((_, i) => (
        <S.Point onClick={() => onClick(i)} key={`nav-p-${i}`} active={active === i} />
      ))}
    </S.Wrapper>
  );
};

Navigation.propTypes = {
  length: PropTypes.number.isRequired,
  active: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired
};

export default Navigation;

import styled, { css } from 'styled-components';

const Block = styled.div`
  position: relative;
`;

Block.Append = styled.div`
  ${({ theme: { m, variable }, align }) => css`
    position: absolute;
    top: 0;
    ${align === 'left' ? 'right' : 'left'}: 100%;
    @media (${m.large(2000)}) {
        width: 280px;
        margin-${align === 'left' ? 'right' : 'left'}: 80px;
    }
    @media (${m.and(1366, 2000)}) {
      width: ${variable(180, 280, 1366, 2000)};
      margin-${align === 'left' ? 'right' : 'left'}: ${variable(30, 80, 1366, 2000)};
    }
    @media (${m.less(1366)}) {
      position: static;
    }
    
    a {
      color: var(--color-black)
    }
  `}
`;

export default Block;

import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Share, Article, FaceList, Head, SeoHidden } from 'components';
import { Button, Heading, Image, Prolog, Icon } from 'elements';
import { classname, classnames, cyrillicToTranslit } from 'utils';
import './Detail.css';
import { navigate } from 'gatsby';
import JobFooter from '../JobFooter/JobFooter';

/**
 * Детальная страница
 */
class Detail extends PureComponent {
  cl = classname('detail');

  static propTypes = {
    /** Дополнительный класс */
    className: PropTypes.string,
    /** Заголовок */
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    /** Теги */
    tags: PropTypes.arrayOf(PropTypes.string.isRequired),
    /** Логотип */
    logo: PropTypes.string,
    /** Анонс */
    announce: PropTypes.string,
    /** Заголовок анонса */
    suptitle: PropTypes.string,
    /** Тема */
    // theme: Head.propTypes.theme,
    themeInner: PropTypes.oneOf(['light', 'dark', 'green', 'inversion']),
    /** Время на чтение */
    // timeToRead: Head.propTypes.timeToRead,
    /** Контент */
    children: PropTypes.node.isRequired,
    /** Кнопка */
    button: PropTypes.shape({
      url: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      icon: PropTypes.string
    }),
    headBg: PropTypes.string,
    announceBg: PropTypes.string,
    seo_h1: PropTypes.string,
    heading_text: PropTypes.string
  };

  static defaultProps = {
    themeInner: 'light'
  };

  render() {
    const {
      className,
      title,
      tags,
      theme,
      themeInner,
      button,
      timeToRead,
      logo,
      suptitle,
      announce,
      children,
      headBg,
      announceBg,
      address,
      city,
      job,
      seo_h1,
      heading_text,
      type,
      order_title,
      order_title_hh,
      hh_link
    } = this.props;

    return (
      <div className={classnames(this.cl(), className)}>
        <div className={this.cl('wrapper')}>
          <div className={this.cl('share', { align: 'top' })}>
            <Share />
          </div>
          <div
            className={this.cl('header', { bg: Boolean(headBg) })}
            style={headBg && { backgroundImage: `url(https://static.nimax.ru${headBg})` }}
          >
            <div className={this.cl('header-inner')}>
              {seo_h1 ? (
                <>
                  <Heading level={1} className={this.cl('heading', { theme: themeInner })}>
                    {title}
                  </Heading>
                  <SeoHidden>
                    <h1>{seo_h1}</h1>
                  </SeoHidden>
                </>
              ) : (
                <Heading as={'h1'} level={1} className={this.cl('heading', { theme: themeInner })}>
                  {title}
                </Heading>
              )}
              {logo && (
                <div className={this.cl('header-append')}>
                  {logo && (
                    <div className={this.cl('logo')}>
                      <Image src={logo} width={180} height={180} />
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className={this.cl('info')}>
              {tags && (
                <div className={this.cl('tags')}>
                  {button && (
                    <div className={this.cl('button')}>
                      <Button url={button.url} size={'s'} icon={button.icon}>
                        {button.text}
                      </Button>
                    </div>
                  )}
                  {tags.map((tag) => (
                    <div className={this.cl('tag')} key={tag}>
                      <Button
                        color={'gray_50'}
                        size={'s'}
                        weight={'normal'}
                        key={tag}
                        type={'button'}
                        url={`/tags/${cyrillicToTranslit.transform(tag.trim().toLowerCase(), '_')}/`}
                      >
                        {tag}
                      </Button>
                    </div>
                  ))}
                  {(city || address) && (
                    <div className={this.cl('location')}>
                      <Icon name={'location'} className={this.cl('location-icon')} />
                      {city}, {address}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className={this.cl('content')}>
            {announce && (
              <div className={this.cl('announce', { is_bg: !!announceBg, bg: announceBg })}>
                {logo && (
                  <div className={this.cl('announce-append')}>
                    {logo && (
                      <div className={this.cl('logo')}>
                        <Image src={logo} width={265} height={265} />
                      </div>
                    )}
                  </div>
                )}
                {announce !== 'empty' && (
                  <div className={this.cl('announce-inner')}>
                    {suptitle && <div className={this.cl('suptitle')} dangerouslySetInnerHTML={{ __html: suptitle }} />}
                    <Prolog>{announce}</Prolog>
                  </div>
                )}
              </div>
            )}
            <Article>{children}</Article>
            {tags && (
              <div className={this.cl('footer')}>
                <div className={this.cl('tags')}>
                  {button && (
                    <div className={this.cl('button')}>
                      <Button url={button.url} size={'s'} icon={button.icon}>
                        {button.text}
                      </Button>
                    </div>
                  )}
                  {tags.map((tag) => (
                    <div className={this.cl('tag')} key={tag}>
                      <Button
                        color={'gray_50'}
                        size={'s'}
                        weight={'normal'}
                        key={tag}
                        type={'button'}
                        url={`/tags/${cyrillicToTranslit.transform(tag.toLowerCase(), '_')}`}
                      >
                        {tag}
                      </Button>
                    </div>
                  ))}

                  {(city || address) && (
                    <div className={this.cl('location')}>
                      <Icon name={'location'} className={this.cl('location-icon')} />
                      {city}, {address}
                    </div>
                  )}
                </div>
              </div>
            )}
            {job && (
              <JobFooter
                title={title}
                order_title={order_title}
                order_title_hh={order_title_hh}
                hh_link={hh_link}
                type={type}
                className={this.cl()}
              />
            )}
          </div>
          <div className={this.cl('share', { align: 'bottom' })}>
            <Share />
          </div>
        </div>
      </div>
    );
  }
}

export default Detail;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { classnames, classname } from 'utils';
import { Image, Button } from 'elements';
import { Figure }  from 'components';
import './Nda.css';

/**
 * NDA
 */
class Nda extends Component {
  cl = classname('nda');

  static propTypes = {
    /** Дополнительный класс */
    className: PropTypes.string,
    /** Изображение */
    image: PropTypes.string,
    /** Текст */
    description: PropTypes.string
  };

  render() {
    const { className, image, description } = this.props;

    const classes = classnames(this.cl(), className);

    return (
        <div className={classes}>
          <Figure cover={'gap'}>
            {image && (
              <div className={this.cl('image')}>
                <Image src={image} width={1760} />
              </div>
            )}
          </Figure>
          <div className={this.cl('footer')}>
            <div className={this.cl('description')} dangerouslySetInnerHTML={{__html: description }} />
            <div className={this.cl('button')}>
              <Button url="/order/">Оставить заявку</Button>
            </div>
          </div>
        </div>
    );
  }
}

export default Nda;

import React from 'react';
import { clearNbsp } from '../../../../utils/clearNbsp';

const AwardItem = ({ item, cl }) => {
  return (
    <div className={cl('item')}>
      <a href={item.link}>
        <p className={cl('position')}>{item.position}</p>
        <p className={cl('text')}>{clearNbsp(item.text)}</p>
      </a>
    </div>
  );
};

export default AwardItem;

import React, { useRef } from 'react';
import { classnames, classname } from 'utils';
import { Icon } from 'elements';

import './dark-cursor.css';

function DarkCursor({ children, className }) {
  const containerRef = useRef(null);
  const controlRef = useRef(null);

  function onMouseEvent(e) {
    e.persist();
    throttleFunc(e);
  }

  function onMouseDown(e) {
    if (controlRef.current) {
      controlRef.current.classList.add('up');
    }
    e.persist();
    setTimeout(() => throttleFunc(e), 100);
  }

  function onMouseUp(e) {
    if (controlRef.current) {
      controlRef.current.classList.remove('up');
    }
    e.persist();
    setTimeout(() => throttleFunc(e), 100);
  }

  function throttle(func, ms) {
    let isThrottled = false;
    let savedArgs = false;
    let savedThis = false;

    function wrapper() {
      if (isThrottled) {
        // (2)
        savedArgs = arguments;
        savedThis = this;
        return;
      }

      func.apply(this, arguments); // (1)

      isThrottled = true;

      setTimeout(function () {
        isThrottled = false; // (3)
        if (savedArgs) {
          wrapper.apply(savedThis, savedArgs);
          savedArgs = savedThis = null;
        }
      }, ms);
    }

    return wrapper;
  }

  const throttleFunc = throttle((e) => {
    const { type } = e;
    if (containerRef.current && controlRef.current) {
      if (type === 'mouseenter') {
        controlRef.current.classList.add('in');
        controlRef.current.classList.remove('out');
      } else if (type === 'mouseleave') {
        controlRef.current.classList.add('out');
        controlRef.current.classList.remove('in');
      } else {
        const { clientX, clientY } = e;
        const {
          current,
          current: { clientHeight, clientWidth }
        } = containerRef;
        const {
          current: currentControl,
          current: { clientHeight: clientHeightControl, clientWidth: clientWidthControl }
        } = controlRef;
        const { left, top } = current.getBoundingClientRect();
        const heightOffset = clientHeight / 2;
        const widthOffset = clientWidth / 2;
        const x = clientX - left - widthOffset - clientWidthControl / 2;
        const y = clientY - top - heightOffset - clientHeightControl / 2;
        currentControl.style.transform = `translate(${x}px, ${y}px`;
      }
    }
  }, 90);

  const cl = classname('cursor');
  const classes = classnames(className, cl());

  return (
    <div
      className={classes}
      ref={containerRef}
      onMouseMove={onMouseEvent}
      onMouseLeave={onMouseEvent}
      onMouseEnter={onMouseEvent}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
    >
      {children}
      <div className={cl('cursor')} ref={controlRef}>
        <Icon name="arrowNew" rotate={0} size={1} />
        <Icon name="arrowNew" rotate={180} size={1} />
      </div>
    </div>
  );
}

export default DarkCursor;

import React, { useState, useRef } from 'react';
import { classname, classnames } from 'utils';
import { Icon } from 'elements';
import './Stages.css';

function Stages({ list, className }) {
  const cl = classname('stages');
  const classes = classnames(cl(), className);

  return (
    <div className={classes}>
      <div className={cl('list')}>
        {list.map((item, index) => {
          const getNumber = index + 1 > 9 ? index + 1 : '0' + (index + 1);
          return (
            <div className={cl('item')} key={index}>
              <div className={cl('number')}>
                {getNumber}
                <Icon name="arrowLong" />
              </div>
              {item.title && <div className={cl('name')} dangerouslySetInnerHTML={{ __html: item.title }} />}
              {item.describe && <div className={cl('text')} dangerouslySetInnerHTML={{ __html: item.describe }} />}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Stages;

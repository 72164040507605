import React from 'react';
import { classname } from 'utils';
import DescriptionItem from './components/DescriptionItem';
import './main-description.css';

const MainDescription = ({ content = [] }) => {
  const cl = classname('main_description');

  return (
    <div className={cl('wrapper')}>
      <div className={cl()}>
        {content.map((item, index) => (
          <DescriptionItem item={item} key={index + item.text} cl={cl} />
        ))}
      </div>
    </div>
  );
};

export default MainDescription;

import React from 'react';
import { classname } from 'utils';
import { Video } from 'elements';
import './dark-approach.css';
import Approach from './components/Approach';

const DarkApproach = ({ title, description, content, footnote, background }) => {
  const cl = classname('dark-approach');
  return (
    <div className={cl('wrapper')}>
      <div className={cl('pinned')}>
        <div className={cl('title')} dangerouslySetInnerHTML={{ __html: title }} />
        <div className={cl('background')}>
          <Video src={[background]} autoPlay={true} loop={true} />
        </div>
      </div>
      <div className={cl('content-wrapper')}>
        <div className={cl('description')} dangerouslySetInnerHTML={{ __html: description }} />
        <div className={cl('content')}>
          {content.map((item, i) => (
            <Approach item={item} cl={cl} key={'ap-' + i} />
          ))}
        </div>
        <div className={cl('footnote')} dangerouslySetInnerHTML={{ __html: footnote }} />
      </div>
    </div>
  );
};

export default DarkApproach;

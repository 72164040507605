import React from 'react';
import { classname } from '../../utils';
import { Cursor } from '../index';
import Swiper from 'react-id-swiper';
import PropTypes from 'prop-types';
import { classnames } from '@bem-react/classnames';
import './RatingListLarge.css';

const cl = classname('rating-list-large');

const RatingListLarge = ({ className, ratingItems }) => {
  const swiperProps = {
    slidesPerView: 'auto',
    spaceBetween: 30,
    grabCursor: true,
    breakpoints: {
      768: {
        spaceBetween: 52
      },
      1080: {
        spaceBetween: 60
      },
      1920: {
        spaceBetween: 100
      }
    }
  };

  return (
    <div className={classnames(cl(), className)}>
      <Cursor>
        <Swiper {...swiperProps}>
          {ratingItems.map((item) => (
            <div key={item.text} className={cl('item')}>
              <div className={cl('header')}>
                <div className={cl('number')}>{item.number}</div>
                <div className={cl('number-label')}>место</div>
              </div>

              <div className={cl('text')}>{item.text}</div>
            </div>
          ))}
        </Swiper>
      </Cursor>
    </div>
  );
};

RatingListLarge.propTypes = {
  className: PropTypes.string,
  ratingItems: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.element,
      number: PropTypes.number
    })
  ).isRequired
};

export default RatingListLarge;

import React from 'react';
import { classname } from 'utils';

const Tag = ({ tag, className, show }) => {
  const { text, left, bottom, rotate } = tag;
  const cl = classname(className);
  const style = {
    left: `${left}%`,
    bottom: `${show ? bottom : -20}%`,
    transform: `rotate(${rotate}deg)`,
    opacity: `${show ? 0.75 : 0}`,
    transition: `${show ? '1000ms' : '0ms'}`
  };
  return (
    <span className={cl()} style={style}>
      {text}
    </span>
  );
};

export default Tag;

import React, { useRef } from 'react';
import { classnames, classname } from 'utils';
import { Figure, Cursor } from 'components';
import {Icon} from 'elements';
import Swiper from 'react-id-swiper';

import './SliderCustom.css';

function SliderCustom({list, className, theme}) {

  theme = theme ? theme : 'dark'

  const cl = classname('slider-custom');
  const classes = classnames(className, cl({theme}));

  const props = {
    Swiper,
    slidesPerView: 'auto',
    spaceBetween: 30,
    centeredSlides: false,
    initialSlide: 0,
    slideToClickedSlide: true,
    grabCursor: true,
    breakpoints: {
      768: {
        spaceBetween: 50,
      },
      1280: {
        spaceBetween: 80,
      },
    },
  }

  return (
    <div className={classes}>
      <div className={cl('wrapper')}>
        <Cursor>
          <Swiper {...props}>
              {list.map((item, index) => (
                  <div className={cl('slide')}>
                      <Figure
                          className={cl('image')}
                          key={index}
                          image={item.image}
                          cover="area"
                          caption={item.descr ? item.descr : null}
                      />
                  </div>
              ))}
          </Swiper>
        </Cursor>
      </div>  
      <div className={cl('arrow')}><Icon size={'fit'} name={'arrowBig'} /></div>
    </div>
  )
}

export default SliderCustom;

import React, { useRef } from 'react';
import { classname } from 'utils';
import { Icon } from 'elements';
import useWindowSize from '../../../../utils/hooks/useWindowSize';

function CursorPlay({ children, className = 'emty', isMuted, isPlay }) {
  const containerRef = useRef(null);
  const controlRef = useRef(null);
  const { width } = useWindowSize();

  function onMouseEvent(e) {
    e.persist();
    throttleFunc(e);
  }

  function throttle(func, ms) {
    let isThrottled = false;
    let savedArgs = false;
    let savedThis = false;

    function wrapper() {
      if (isThrottled) {
        // (2)
        savedArgs = arguments;
        savedThis = this;
        return;
      }

      func.apply(this, arguments); // (1)

      isThrottled = true;

      setTimeout(function () {
        isThrottled = false; // (3)
        if (savedArgs) {
          wrapper.apply(savedThis, savedArgs);
          savedArgs = savedThis = null;
        }
      }, ms);
    }

    return wrapper;
  }

  const throttleFunc = throttle((e) => {
    const { type } = e;
    if (containerRef.current && controlRef.current && width >= 475) {
      if (type === 'mouseenter') {
        controlRef.current.classList.add('in');
        controlRef.current.classList.remove('out');
      } else if (type === 'mouseleave') {
        controlRef.current.classList.add('out');
        controlRef.current.classList.remove('in');
        controlRef.current.style.transform = null;
      } else {
        const { clientX, clientY } = e;
        const {
          current,
          current: { clientHeight, clientWidth }
        } = containerRef;
        const {
          current: currentControl,
          current: { clientHeight: clientHeightControl, clientWidth: clientWidthControl }
        } = controlRef;
        const { left, top } = current.getBoundingClientRect();
        const heightOffset = clientHeight / 2;
        const widthOffset = clientWidth / 2;
        const x = clientX - left - widthOffset - clientWidthControl / 2;
        const y = clientY - top - heightOffset - clientHeightControl / 2;
        currentControl.style.transform = `translate(${x}px, ${y}px`;
      }
    } else if (containerRef.current && controlRef.current) {
      controlRef.current.style.transform = null;
    }
  }, 90);

  const cl = classname(className + '__cursor');

  return (
    <div
      className={cl()}
      ref={containerRef}
      onMouseMove={onMouseEvent}
      onMouseLeave={onMouseEvent}
      onMouseEnter={onMouseEvent}
      style={{ cursor: 'pointer' }}
    >
      {children}
      <div className={cl('inner')} ref={controlRef} style={{ opacity: 1 }}>
        <Icon name={isMuted ? 'volume' : isPlay ? 'videoPause' : 'videoPlay'} rotate={0} size={1} />
      </div>
    </div>
  );
}

export default CursorPlay;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { classnames, classname, raw } from 'utils';
import {Image} from 'elements';
import './Conditions.css';

class Conditions extends Component {
  cl = classname('conditions');

  static propTypes = {
    /** Дополнительный класс */
    className: PropTypes.string
  };

  render() {

    const { className, children } = this.props;

    const classes = classnames(className, this.cl());

    return (
      <div className={classes}>
        {children.map((item) => {
          return (
            <div className={this.cl('item')}>
                {item.title && <div className={this.cl('title')} {...raw(item.title)} />}
                {item.content &&
                  <div className={this.cl('wrapper')}>
                    <div className={this.cl('content')}>
                      {item.precaption && <div className={this.cl('precaption')} {...raw(item.precaption)} />}
                      {item.content}
                    </div>
                    {item.caption && <div className={this.cl('caption')} {...raw(item.caption)} />}
                  </div>
                }
                
                {item.image && 
                  <Image
                    original={true}
                    src={item.image}
                    className={this.cl('image')}
                  />
                }
            </div>
          )
        })
      }
    </div>
  )};
};

export default Conditions;
import React from 'react';

const Description = ({ tags, description, department, cl }) => {
  const { mobile: tagsM, desktop: tagsD } = tags;
  return (
    <div className={cl('description-wrapper')}>
      <div className={cl('tags-wrapper')}>
        {tagsD.map((tag, i) => (
          <div className={cl('tag')} key={'dpfstd-' + i} dangerouslySetInnerHTML={{ __html: tag }} />
        ))}
        {tagsM.map((tag, i) => (
          <div className={cl('tag-mobile')} key={'dpfstm-' + i} dangerouslySetInnerHTML={{ __html: tag }} />
        ))}
      </div>
      <div className={cl('text-wrapper')}>
        <h2 className={cl('department')} dangerouslySetInnerHTML={{ __html: department }} />
        <div className={cl('description-text-wrapper')}>
          {description.map((text, i) => (
            <p className={cl('description-text')} key={'dpfst-' + i} dangerouslySetInnerHTML={{ __html: text }} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Description;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Icon } from 'elements';
import { classnames, classname, raw } from 'utils';
import './Button.css';

/**
 * Кнопка
 */
class Button extends Component {
  cl = classname('button');

  static propTypes = {
    /** Дополнительный класс */
    className: PropTypes.string,
    /** Ссылка */
    url: PropTypes.string,
    /** Тип кнопки */
    type: PropTypes.oneOf(['button', 'submit', 'reset']),
    /** Активность */
    disabled: PropTypes.bool,
    /** Иконка */
    icon: PropTypes.string,
    /** Размер */
    size: PropTypes.oneOf(['s', 'm', 'l', 'fit', 'xl']),
    /** Регистр */
    uppercase: PropTypes.bool,
    /** Текст */
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /** Событие по клику */
    onClick: PropTypes.func,
    /** Толщина */
    weight: PropTypes.oneOf(['normal', 'bold']),
    /** Табиндекс */
    tabIndex: PropTypes.number
  };

  static defaultProps = {
    uppercase: true,
    disabled: false,
    size: 'm',
    weight: 'bold'
  };

  render() {
    const {
      className,
      url,
      type,
      disabled,
      shape,
      icon,
      size,
      uppercase,
      weight,
      children,
      onClick,
      iconSize,
      tabIndex
    } = this.props;

    const classes = classnames(
      className,
      this.cl({ disabled, size, uppercase, weight, shape, text: Boolean(children), icon: Boolean(icon) })
    );

    const props = {
      className: classes,
      disabled,
      type,
      tabIndex
    };

    let Tag = type ? 'button' : 'div';

    if (url) {
      const internal = /^\/(?!\/)/.test(url);

      if (!internal) {
        Tag = 'a';
        props.href = url;
        props.target = '_blank';
      } else {
        Tag = Link;
        props.to = url;
      }
    }

    if (onClick) {
      props.onClick = e => onClick(e);
      if (Tag === 'div') Tag = 'button';
      if (!props.type) props.type = 'button';
    }

    return (
      <Tag className={classes} {...props}>
        <div className={this.cl('wrapper')}>
          {icon && (
            <div className={this.cl('icon')}>
              <Icon name={icon} size={iconSize} />
            </div>
          )}
          {children && <div className={this.cl('text')} {...raw(children)} />}
        </div>
      </Tag>
    );
  }
}

export default Button;

import React, { useEffect, useState } from 'react';

const SvgInline = ({ url, className }) => {
  const [svg, setSvg] = useState(null);

  useEffect(() => {
    fetch(url)
      .then((res) => res.text())
      .then(setSvg);
  }, [url]);

  return <div className={className} dangerouslySetInnerHTML={{ __html: svg }} />;
};

export default SvgInline;

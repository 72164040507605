import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { classnames, classname, raw } from 'utils';

import { Icon } from 'elements';

import './Error.css';

/**
 * Ошибка
 */
class Error extends Component {
  cl = classname('error');

  static propTypes = {
    /** Доп. класс */
    className: PropTypes.string,
    /** Событие зарытия окна */
    onClose: PropTypes.func,
    /** Содержимое */
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
  };

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return Boolean(nextProps.children);
  }

  /**
   * Событие по закрытию окна
   * @param event
   */
  onClose(event) {
    const { onClose } = this.props;

    if (onClose) {
      onClose();
    }

    event.preventDefault();
  }

  render() {
    const { className, children } = this.props;

    const classes = classnames(className, this.cl());

    return (
      <div className={classes} {...raw(children)}>
        {/*<div className={this.cl('text')}>*/}
        {/*  <div {...raw(children)} />*/}
        {/*</div>*/}
        {/*<button type={'button'} className={this.cl('close')} onClick={e => this.onClose(e)}>*/}
        {/*  <div className={this.cl('icon')}>*/}
        {/*    <Icon name={'cancel'} size={'fit'} />*/}
        {/*  </div>*/}
        {/*</button>*/}
      </div>
    );
  }
}

export default Error;

import React, { useEffect, useRef, useState } from 'react';
import { classnames, classname } from 'utils';
import Swiper from 'react-id-swiper';
import { Image } from 'elements';
import SvgInline from '../../../SvgInline/SvgInline';

import './approach.css';
import useScreenSizeCheck from '../../../../utils/hooks/useScreenSizeCheck';

const Approach = ({ feed = [], title = '', className }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [annotations, setAnnotations] = useState(feed.map((el, index) => ({ title: el.title, index })));
  const isDesktop = useScreenSizeCheck(1200);

  const ref = useRef(null);

  const cl = classname('dark-approach-n');
  const classNames = classnames(className, cl());

  const noSwipingClass = 'swiper-no-swiping';

  const options = {
    slidesPerView: 1,
    centeredSlides: false,
    initialSlide: 0,
    grabCursor: false,
    loop: false,
    effect: 'fade',
    noSwipingClass,
    on: {
      slideChange: () => {
        onSlideChangeHandler();
      }
    }
  };

  const onSlideChangeHandler = () => {
    const index = ref.current?.childNodes[0]?.swiper?.activeIndex;
    if (typeof index === 'number') {
      setAnnotations((prev) => {
        const sorted = prev.sort((a, b) => a.index - b.index);
        return [...sorted.filter((el) => index <= el.index), ...sorted.filter((el) => index > el.index)];
      });
      setCurrentSlide(index);
    }
  };

  const goNext = () => {
    if (ref.current?.childNodes[0]?.swiper?.isEnd) {
      return ref.current?.childNodes[0]?.swiper?.slideTo();
    }
    return ref.current?.childNodes[0]?.swiper?.slideNext();
  };

  const goPrev = () => {
    if (ref.current?.childNodes[0]?.swiper?.isBeginning) {
      return ref.current?.childNodes[0]?.swiper?.slideTo(feed.length - 1);
    }
    return ref.current?.childNodes[0]?.swiper?.slidePrev();
  };

  const setSlideHandler = (index) => {
    return ref.current?.childNodes[0]?.swiper?.slideTo(index);
  };

  // TO-DO: Можно сделать блокировку кнопок, если слайд последний
  // const isFirst = (current) => {};
  // const isLast = (current) => {};

  return (
    <section className={classNames}>
      <h2 className={cl('title')} dangerouslySetInnerHTML={{ __html: title }} />
      <div className={cl('wrapper')}>
        <div className={cl('slider')} ref={ref}>
          <Swiper {...options}>
            {feed.map((item, i) => (
              <div key={'dswa-' + i} className={classnames(cl('slider-slide'), isDesktop ? noSwipingClass : '')}>
                <div className={cl('slide-img-wrapper')}>
                  <Image src={item.img} className={cl('slider-image')} />
                </div>
                <div className={cl('side-content')}>
                  <div>
                    <div className={cl('slide-num')} dangerouslySetInnerHTML={{ __html: `0${i + 1}` }} />
                    <h3 className={cl('slide-title')} dangerouslySetInnerHTML={{ __html: item.title }} />
                    <div className={cl('slide-text')} dangerouslySetInnerHTML={{ __html: item.text }} />
                  </div>
                  <div className={cl('slide-pagination')}>
                    <div className={cl('slide-pagination-nums')}>
                      <span>0{i + 1}</span>
                      <span>/</span>
                      <span>0{feed.length}</span>
                    </div>
                    <div className={cl('slide-pagination-arrows')}>
                      <div onClick={goPrev}>
                        <SvgInline url={'/icons/arrow.svg'} className={cl('slide-arrow-left')} />
                      </div>
                      <div onClick={goNext}>
                        <SvgInline url={'/icons/arrow.svg'} className={cl('slide-arrow-right')} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Swiper>
        </div>
        <div className={cl('annotations')}>
          {annotations.map((el) => {
            if (el.index !== currentSlide)
              return (
                <div className={cl('annotation')} key={'dpa-' + el.title} onClick={() => setSlideHandler(el.index)}>
                  <div className={cl('annotation-num')} dangerouslySetInnerHTML={{ __html: `0${el.index + 1}` }} />
                  <h3 className={cl('annotation-title')} dangerouslySetInnerHTML={{ __html: el.title }} />
                </div>
              );
          })}
        </div>
      </div>
    </section>
  );
};

export default Approach;

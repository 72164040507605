import React from 'react';
import { classnames, classname } from 'utils';
import { Image } from 'elements';
import './team.css';

const Team = ({ title, description, image, footer, className }) => {
  const cl = classname('dark-team');
  const classNames = classnames(className, cl());
  return (
    <section className={classNames}>
      <div className={cl('wrapper')}>
        <div className={cl('top')}>
          <h2 className={cl('title')} dangerouslySetInnerHTML={{ __html: title }} />
        </div>
        <div className={cl('text-wrapper')}>
          <div className={cl('description')} dangerouslySetInnerHTML={{ __html: description }} />
          <div className={cl('description-sub')} dangerouslySetInnerHTML={{ __html: footer }} />
        </div>
        <div className={cl('img-wrapper')}>
          <Image src={image} className={cl('image')} />
        </div>
        <div className={cl('description-sub mobile')} dangerouslySetInnerHTML={{ __html: footer }} />
      </div>
    </section>
  );
};

export default Team;

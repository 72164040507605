import styled from 'styled-components';

const Progress = {};

Progress.Svg = styled.svg`
  height: 100%;
  width: 100%;
  transform: rotate(-90deg);
`;

Progress.Circle = styled.circle`
  stroke: rgba(0, 0, 0, 0.3);
  strokewidth: 13;
`;

Progress.Bar = styled.circle`
  stroke: white;
  strokewidth: 13;
`;

Progress.Root = styled.div`
  height: 100%;
  width: 100%;
`;

export default Progress;

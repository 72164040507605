import React, { PureComponent } from 'react';
import { Icon } from 'elements';
import { classnames, classname, raw } from 'utils';

import './ItemsSpoilered.css';

export class ItemsSpoilered extends PureComponent {
  cl = classname('items-spoilered');

  render() {
    const { className, dataLeft, dataRight } = this.props;

    const classes = classnames(className, this.cl());

    return (
      <div className={classes}>
        <div className={this.cl('column')}>
          {dataLeft.map((item) => {
            return (
              <a className={this.cl('item')} href={item.link} key={`sp-${item.title}`}>
                <Icon className={this.cl('title-icon')} name="arrowCircle" />
                <Icon className={this.cl('title-icon_mobile')} name="arrowR" />
                <div className={this.cl('title')} {...raw(item.title)} />
                <div className={this.cl('text')} {...raw(item.text)} />
              </a>
            );
          })}
        </div>
        <div className={this.cl('column')}>
          {dataRight.map((item) => {
            return (
              <a className={this.cl('item')} href={item.link} key={`sp-${item.title}`}>
                <Icon className={this.cl('title-icon')} name={'arrowCircle'} />
                <Icon className={this.cl('title-icon_mobile')} name={'arrowR'} />
                <div className={this.cl('title')} {...raw(item.title)} />
                <div className={this.cl('text')} {...raw(item.text)} />
              </a>
            );
          })}
        </div>
      </div>
    );
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import { INDUSTRY_CATEGORIES } from '../../utils/constants';
import Tickers from './Tickers';
import styled from 'styled-components';
import ItemTicker from './ItemTicker';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 156px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  position: relative;
  overflow: hidden;

  @media (max-width: ${({ theme }) => `${theme.m.b_768}px`}) {
    height: 71px;
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  white-space: nowrap;
  animation: bannerMove 25s linear infinite;
  will-change: transform;

  @keyframes bannerMove {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(-50%, 0);
    }
  }
`;

const TickerRaw = ({ category }) => {
  return (
    <Wrapper>
      <InnerWrapper>
        {[...Tickers[category], ...Tickers[category]].map((t, i) => (
          <ItemTicker key={`${t}${i}}`} src={t} />
        ))}
      </InnerWrapper>
    </Wrapper>
  );
};

TickerRaw.propTypes = {
  category: PropTypes.oneOf(Object.values(INDUSTRY_CATEGORIES))
};

export default TickerRaw;

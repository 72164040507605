import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'elements';
import { Link } from 'gatsby';
import { classname, classnames } from 'utils';
import './Match.css';

class Match extends Component {
  cl = classname('match');

  static propTypes = {
    /** Дополнительный класс */
    className: PropTypes.string,
    /** Ссылка на иконке */
    url: PropTypes.string
  };

  render () {
    const {url, className} = this.props;
    const classes = classnames(this.cl(), className);
    const linkProps = {className: classes};
    const internal = /https?:\/\//.test(url);
    const Tag = url ? (!internal ? Link : 'a') : 'div';
    if (!internal) {
      linkProps.to = url;
    } else {
      linkProps.href = url;
      linkProps.target = '_blank';
    }
    

    return(
      <Tag {...linkProps}>
        <div className={this.cl('icon')}>
          <Icon name={'match'} />
        </div>  
      </Tag>
    )
  }
}

export default Match;
import React from 'react';
import useScreenSizeCheck from '../../../../utils/hooks/useScreenSizeCheck';

import CursorImages from './CursorImages';
import MobileBackground from './MobileBackground';

const ShowreelWrapper = ({ cl, images, mobileBg, children }) => {
  const isDesktop = useScreenSizeCheck(475);

  if (isDesktop) {
    return (
      <CursorImages cl={cl} images={images}>
        {children}
      </CursorImages>
    );
  } else {
    return (
      <MobileBackground cl={cl} background={mobileBg}>
        {children}
      </MobileBackground>
    );
  }
};

export default ShowreelWrapper;

import React, { PureComponent } from 'react';

import { Heading } from 'elements';
import { classnames, classname, raw } from 'utils';

import './FactoidList.css';

/**
 * Список фактойдов
 */
export class FactoidList extends PureComponent {
  cl = classname('factoid-list');

  render() {
    const { className, title, children } = this.props;

    const classes = classnames(className, this.cl());

    return (
      <div className={classes}>
        <div className={this.cl('heading')}>
          <Heading level={2}>{title}</Heading>
        </div>
        <div className={this.cl('wrapper')}>
          {children.map((item, index) => (
            <div className={this.cl('item')} key={item.title}>
              <div className={this.cl('title')} {...raw(item.title)} />
              <div className={this.cl('descr')} {...raw(item.descr)} />
              <div className={this.cl('index')}>{index + 1}</div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

import React, { Children, Component } from 'react';
import PropTypes from 'prop-types';
import { classname, classnames } from 'utils';

import './ChangeWord.css';

export default class ChangeWord extends Component {
  cl = classname('change-word');

  static propTypes = {
    words: PropTypes.array.isRequired,
  };

  constructor(props){
    super(props);
    
    this.state = { 
      activeWord: null,
      count: 0
    };
      
  }

  componentDidMount() {
    this.interval = setInterval(() => this.change(), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  change() {
    const { words } = this.props;
    let i = this.state.count;
    const l = words.length;
    i++;
    if(i >= l) {
      i = 0
    }
    this.setState({ activeWord: words[i], count: i})
  }

  render() {
    const { activeWord } = this.state;
    const classes = classnames(this.cl());
    return (
      <span className={classes}>{activeWord}</span>
    )
  }
}
import React from 'react';
import { classname } from 'utils';
import Swiper from 'react-id-swiper';
import Slide from './components/Slide';
import './dark-ratings-slider.css';
import DarkCursor from '../DarkCursor/DarkCursor';

const DarkRatingsSlider = ({ content = [], title = '' }) => {
  const cl = classname('dark-ratings-slider');
  const { top = [], bottom = [] } = content;

  const propsTop = {
    slidesPerView: 'auto',
    spaceBetween: 30,
    centeredSlides: false,
    initialSlide: 0,
    slideToClickedSlide: true,
    grabCursor: true,
    autoplay: {
      delay: 3000,
      pauseOnMouseEnter: true
    },
    loop: true,
    breakpoints: {
      768: {
        spaceBetween: 52
      },
      1080: {
        spaceBetween: 60
      },
      1920: {
        spaceBetween: 80
      }
    }
  };

  const propsBottom = {
    ...propsTop,
    autoplay: {
      delay: 3000,
      reverseDirection: true,
      pauseOnMouseEnter: true
    }
  };

  return (
    <div className={cl('wrapper')}>
      <div className={cl('title')} dangerouslySetInnerHTML={{ __html: title }} />
      <div className={cl('top-wrapper')}>
        <DarkCursor>
          <Swiper {...propsTop}>
            {top.map((slide, i) => (
              <div className={cl('slide')} key={'tp-rt-sl' + i}>
                <Slide slide={slide} cl={cl} />
              </div>
            ))}
          </Swiper>
        </DarkCursor>
      </div>

      <div className={cl('bottom-wrapper')}>
        <DarkCursor>
          <Swiper {...propsBottom}>
            {bottom.map((slide, i) => (
              <div className={cl('slide')} key={'bt-rt-sl' + i}>
                <Slide slide={slide} cl={cl} />
              </div>
            ))}
          </Swiper>
        </DarkCursor>
      </div>
    </div>
  );
};

export default DarkRatingsSlider;

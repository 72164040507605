import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Link } from 'gatsby';

export default ({ children }) => (
  <StaticQuery
    query={graphql`
      {
        site {
          siteMetadata {
            services {
              title
              url
              subMenu {
                title
                url
              }
            }
          }
        }
      }
    `}
    render={(res) => (
      <ul className='menu-services'>
        {res.site.siteMetadata.services.map((service, index) => (
          <li
            className={`about__link${service.subMenu ? ' is-sub' : ''}`}
            key={index}
            onClick={handleClick}
          >
            <Link to={service.url}>{service.title}</Link>
            {service.subMenu && (
              <ul className='sub-menu'>
                {service.subMenu.map((subMenuItem, subIndex) => (
                  <li key={subIndex}>
                    <Link to={subMenuItem.url}>{subMenuItem.title}</Link>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    )}
  />
);

function handleClick(event) {
  const target = event.currentTarget;
  const aboutLinks = document.querySelectorAll(".about__link.is-sub");

  aboutLinks.forEach((link) => {
    if (link === target) {
      link.classList.toggle("active");
    } else {
      link.classList.remove("active");
    }
  });
}
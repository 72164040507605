import React, { PureComponent } from 'react';
import { Link } from 'gatsby';

import { Icon } from 'elements';
import { classnames, classname, raw } from 'utils';

import './ListServicesAlt.css';

/**
 * Список услуг альтернативный вариант
 */
export class ListServicesAlt extends PureComponent {
  cl = classname('list-services-alt');

  render() {
    const { className, data } = this.props;

    const classes = classnames(className, this.cl());

    return (
      <div className={classes}>
        {data.map((item) => (
          <div className={this.cl('item')}>
            {item.link ? (
              <Link to={item.link} className={this.cl('title')} key={item.title} data-hover={true}>
                <div className={this.cl('name')} {...raw(item.title)} />
                <div className={this.cl('icon')}>
                  <Icon name={'arrowR'} />
                </div>
              </Link>
            ) : (
              <div className={this.cl('title')} key={item.title}>
                <div className={this.cl('name')} {...raw(item.title)} />
              </div>
            )}
            {item.descr && <div className={this.cl('descr')} {...raw(item.descr)} />}
          </div>
        ))}
      </div>
    );
  }
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Email } from 'elements';
import { classnames, classname } from 'utils';
import './AboutContacts.css';

/**
 * Контакты
 */
class AboutContacts extends Component {
  cl = classname('about-contacts');

  static propTypes = {
    /** Дополнительный класс */
    className: PropTypes.string
  };

  render() {
    const { className } = this.props;

    const classes = classnames(className, this.cl());

    return (
      <div className={classes}>
        <div className={this.cl('info')}>
          <a href="tel:+78123855206" className={this.cl('phone')}>
            +7 (812) 385-52-06
          </a>
          <Email>
            {email => (
              <a href={`mailto:${email}`} className={this.cl('email')}>
                {email}
              </a>
            )}
          </Email>
        </div>
        <Button url="/order/" className={this.cl('button')}>
          Заявка
        </Button>
      </div>
    );
  }
}

export default AboutContacts;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Heading } from 'elements';
import { classnames, classname } from 'utils';

import './ContentHeading.css';

export class ContentHeading extends PureComponent {
  cl = classname('content-heading');

  static propTypes = {
    /** Тема */
    theme: PropTypes.oneOf(['light', 'dark', 'green']),
  };

  static defaultProps = {
    theme: 'dark'
  };

  render() {
    const { className, theme, children } = this.props;

    const classes = classnames(className, this.cl({ theme }));

    return (
      <div className={classes}>
        <Heading level={1}>{children}</Heading>
      </div>
    );
  }
}

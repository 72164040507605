import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { classnames, classname } from 'utils';
import './Loader.css';

/**
 * Индикатор загрузки
 */
class Loader extends Component {
  cl = classname('loading');

  static propTypes = {
    /** Доп. класс */
    className: PropTypes.string
  };

  render() {
    const { className } = this.props;

    const classes = classnames(this.cl(), className);

    return (
      <div className={classes}>
        <img
          className={this.cl('image')}
          src={require('./static/loader.png')}
          srcSet={require('./static/loader@2x.png') + ' 2x'}
          alt={'Загрузка'}
        />
      </div>
    );
  }
}

export default Loader;

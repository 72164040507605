import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid white;
  padding: 0 20px;
  border-radius: 50px;
  @media (max-width: 500px) {
    border: 1px solid black;
  }
`;

export const Point = styled.div`
  position: relative;
  height: 6px;

  width: ${({ active }) => (active ? '30px' : '6px')};
  background-color: white;
  opacity: ${({ active }) => (active ? '1' : '0.5')};
  cursor: ${({ active }) => (active ? 'default' : 'pointer')};

  border-radius: 50px;
  transition: width 500ms, opacity 500ms;

  // Энто чтобы по поинтеру попасть можно было :/
  ::before {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
  }

  @media (max-width: 500px) {
    background-color: black;
  }
`;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FilesComponent from 'react-files';
import { classnames, classname } from 'utils';
import { Icon } from 'elements';
import './Files.css';

/**
 * Загрузка файлов
 */
class Files extends Component {
  cl = classname('files');

  clButton = classname('files-button');

  static propTypes = {
    /** Дополнительный класс */
    className: PropTypes.string,
    /** Имя поля */
    name: PropTypes.string.isRequired,
    /** Список файлов */
    files: PropTypes.array.isRequired,
    /** Фукнция установки ref */
    setRef: PropTypes.func.isRequired,
    /** Функция удаления файла */
    filesRemoveOne: PropTypes.func.isRequired,
    /** Событие на добавление файлов */
    onFilesChange: PropTypes.func.isRequired,
    /** Событие на ошибку */
    onFilesError: PropTypes.func.isRequired
  };

  static defaultProps = {
    files: [],
    text: 'Прикрепить файл'
  };

  renderFile(file) {
    const { filesRemoveOne } = this.props;

    return (
      <div key={file.id} className={this.cl('item')}>
        <button type={'button'} className={this.cl('remove')} onClick={() => filesRemoveOne(file)}>
          <Icon name={'cancel'} size={'fit'} />
        </button>
        <div className={this.cl('name')}>{file.name}</div>
      </div>
    );
  }

  render() {
    const { className, name, files, onFilesChange, onFilesError, setRef, text } = this.props;

    const classes = classnames(this.cl(), className);

    return (
      <div className={classes}>
        <FilesComponent
          ref={(node) => setRef(node)}
          name={name}
          onChange={(file) => onFilesChange(file)}
          onError={(error, file) => onFilesError(error, file)}
          multiple
          maxFiles={10}
          maxFileSize={20000000}
          minFileSize={0}
        >
          <button type={'button'} className={classnames(this.clButton(), this.cl('button'))}>
            <div className={this.clButton('wrapper')}>
              <div className={this.clButton('icon')}>
                <Icon name={'attach'} />
              </div>
              <div className={this.clButton('text')}>{text}</div>
            </div>
          </button>
        </FilesComponent>

        {files.length > 0 && (
          <div className={this.cl('list')}>
            {this.renderFile(files[0])}
            {files.length > 1 && (
              <div className={this.cl('more')}>
                <div className={this.cl('count')}>+{files.length - 1}</div>
                <div className={this.cl('popup')}>
                  <div className={this.cl('items')}>{files.slice(1).map((file) => this.renderFile(file))}</div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default Files;

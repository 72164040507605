import React from 'react';

export default {
  width: 32,
  height: 18,
  content: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.33333 1.77778H26.6667C28.6303 1.77778 30.2222 3.36965 30.2222 5.33333V12.4444C30.2222 14.4081 28.6303 16 26.6667 16H5.33333C3.36965 16 1.77778 14.4081 1.77778 12.4444V5.33333C1.77778 3.36965 3.36965 1.77778 5.33333 1.77778ZM0 5.33333C0 2.38782 2.38781 0 5.33333 0H26.6667C29.6122 0 32 2.38782 32 5.33333V12.4444C32 15.39 29.6122 17.7778 26.6667 17.7778H5.33333C2.38782 17.7778 0 15.39 0 12.4444V5.33333ZM6.75087 5.75H9.17187C9.59187 5.75 9.96987 5.804 10.3059 5.912C10.6479 6.02 10.9359 6.176 11.1699 6.38C11.4099 6.584 11.5929 6.83 11.7189 7.118C11.8509 7.4 11.9169 7.721 11.9169 8.081C11.9169 8.387 11.8599 8.684 11.7459 8.972C11.6319 9.26 11.4579 9.518 11.2239 9.746C10.9959 9.968 10.7109 10.148 10.3689 10.286C10.0269 10.418 9.62787 10.484 9.17187 10.484H8.57787V12.5H6.75087V5.75ZM9.16287 8.945C9.45087 8.945 9.67587 8.867 9.83787 8.711C10.0059 8.549 10.0899 8.339 10.0899 8.081C10.0899 7.829 10.0059 7.634 9.83787 7.496C9.67587 7.352 9.45087 7.28 9.16287 7.28H8.54187V8.945H9.16287ZM13.8065 5.75H16.3625C16.8785 5.75 17.3405 5.84 17.7485 6.02C18.1625 6.194 18.5135 6.434 18.8015 6.74C19.0895 7.04 19.3085 7.394 19.4585 7.802C19.6145 8.204 19.6925 8.636 19.6925 9.098C19.6925 9.566 19.6145 10.007 19.4585 10.421C19.3085 10.835 19.0895 11.195 18.8015 11.501C18.5135 11.807 18.1625 12.05 17.7485 12.23C17.3405 12.41 16.8785 12.5 16.3625 12.5H13.8065V5.75ZM16.3355 10.925C16.5755 10.925 16.7855 10.877 16.9655 10.781C17.1515 10.685 17.3045 10.556 17.4245 10.394C17.5445 10.226 17.6345 10.031 17.6945 9.809C17.7605 9.587 17.7935 9.35 17.7935 9.098C17.7935 8.852 17.7605 8.624 17.6945 8.414C17.6345 8.198 17.5445 8.009 17.4245 7.847C17.3045 7.685 17.1515 7.559 16.9655 7.469C16.7855 7.373 16.5755 7.325 16.3355 7.325H15.6335V10.925H16.3355ZM26 5.75H21.662V12.5H23.489V9.836H25.73V8.414H23.489V7.325H26V5.75Z"
        fill="currentColor"
      />
    </>
  )
};

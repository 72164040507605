import React from 'react';
import Button from './magnetic-button.style';
import {useState, useRef} from "react";

export default function MagneticButton(props) {
  const elementRef = useRef(null);
  const decoRef = useRef(null);
  const innerRef = useRef(null);
  const {
    href,
    type,
    color,
    border,
    filled,
    contentScale = true,
    children,
    onClick} = props;
  const [hover, setHover] = useState(false);
  const [transformStyles, setTransformStyles] = useState({
    tx: {previous: 0, current: 0, amt: 0.1},
    ty: {previous: 0, current: 0, amt: 0.1},
    scale: {previous: 1, current: 1, amt: 0.2}
  });

  const lerp = (a, b, n) => (1 - n) * a + n * b;
  const getDistance = (x1, y1, x2, y2) => Math.hypot(x1 - x2, y1 - y2);
  const getMousePos = (evt) => {
    return {
      x : evt.clientX,
      y : evt.clientY
    };
  };

  const moveButton = (reset) => {
    elementRef.current.style.transform = reset ? '' : `translate3d(${transformStyles['tx'].previous}px, ${transformStyles['ty'].previous}px, 0)`;
    decoRef.current.style.transform = reset ? '' : `scale(${transformStyles['scale'].current})`;
    innerRef.current.style.transform = reset ? '' : `translate3d(${transformStyles['tx'].previous*0.2}px, ${transformStyles['ty'].previous*0.2}px, 0)`;
  }


  const onMouseEnter = () => setHover(true);
  const onMouseLeave = () => {
    setHover(false);
    setTransformStyles({
      tx: {previous: 0, current: 0, amt: 0.1},
      ty: {previous: 0, current: 0, amt: 0.1},
      scale: {previous: 1, current: 1, amt: 0.2}
    });
    requestAnimationFrame(() => moveButton(true));
  };

  const onMouseMove = (evt) => {
    evt.persist();
    const rect = elementRef.current ? elementRef.current.getBoundingClientRect() : null;
    if (!rect) {
      return;
    }

    const mousePosition = getMousePos(evt);
    const distanceMouseButton = getDistance(mousePosition.x + window.scrollX, mousePosition.y + window.scrollY, rect.left + rect.width/2, rect.top + rect.height/2);
    const distanceToTrigger = rect ? rect.width * 1.5 : null;
    const renderedStyles = transformStyles;
    let x = 0;
    let y = 0;

    if (distanceMouseButton < distanceToTrigger) {
      x = (mousePosition.x + window.scrollX - (rect.left + rect.width/2))*.3;
      y = (mousePosition.y + window.scrollY - (rect.top + rect.height/2))*.3;
    }

    renderedStyles['tx'].current = x;
    renderedStyles['ty'].current = y;

    for (const key in renderedStyles) {
      renderedStyles[key].previous = lerp(renderedStyles[key].previous, renderedStyles[key].current, renderedStyles[key].amt);
    }

    if (hover) {
      renderedStyles.scale.current = 1.2;
      setTransformStyles(renderedStyles);
    }

    requestAnimationFrame(() => moveButton());
  };

  return (
    <Button
      href={href}
      type={type}
      color={color}
      border={border}
      filled={filled}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseLeave}
      ref={elementRef}
      isHover={hover}
    >
      <Button.Deco color={color} border={border} isHover={hover} ref={decoRef}>
        {filled ? <Button.Filter color={color} isHover={hover} /> : ''}
      </Button.Deco>
      <Button.Inner isHover={hover} ref={innerRef}>
        <Button.Content isHover={hover} contentScale={contentScale}>
          {children}
        </Button.Content>
      </Button.Inner>
    </Button>
  )
}

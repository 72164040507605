import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { classnames, classname } from 'utils';
import { Card, FaceList } from 'components';
import { Icon, Button, Recording } from 'elements';
import './CardEvent.css';

/**
 * Карточка события
 */
class CardEvent extends Component {
  cl = classname('card-event');

  state = {
    disabled: false
  };

  static propTypes = {
    /** Переодичность */
    repeat: PropTypes.string,
    /** Город */
    city: PropTypes.string,
    /** Адрес */
    address: PropTypes.string,
    /** Надпись на кнопке*/
    btnTitle: PropTypes.string,
    /** Запись выступления */
    recording: PropTypes.string,
    /**Тема выступления */
    topic: PropTypes.string,
    /** Список авторов */
    authors: PropTypes.arrayOf(PropTypes.string),
  };

  renderBeginDate() {
    if (!/^\d{4}-\d{2}-\d{2}/.test(this.props.begin)) {
      return <div className={this.cl('date')}>{this.props.begin}</div>;
    } else {
      const current = new Date();
      const datetime = new Date(this.props.begin.replace(' ', 'T'));

      const date = new Intl.DateTimeFormat('ru', {
        timeZone: 'Europe/Moscow',
        day: 'numeric',
        month: 'long',
        ...(current.getFullYear() !== datetime.getFullYear() ? { year: 'numeric' } : {})
      }).format(datetime);

      const time = new Intl.DateTimeFormat('ru', {
        timeZone: 'Europe/Moscow',
        hour: 'numeric',
        minute: '2-digit'
      }).format(datetime);

      return (
        <div className={this.cl('date')}>
          <div className={this.cl('item')}>{date}</div>{' '}
          {datetime.getHours() !== 3 && <div className={this.cl('item')}>в {time}</div>}
        </div>
      );
    }
  }

  renderEndDate() {
    const current = new Date();
    const datetime = new Date(this.props.end.replace(' ', 'T'));

    const date = new Intl.DateTimeFormat('ru', {
      timeZone: 'Europe/Moscow',
      day: 'numeric',
      month: 'long',
      ...(current.getFullYear() !== datetime.getFullYear() ? { year: 'numeric' } : {})
    }).format(datetime);

    const time = new Intl.DateTimeFormat('ru', {
      timeZone: 'Europe/Moscow',
      hour: 'numeric',
      minute: '2-digit'
    }).format(datetime);

    return (
      <div className={this.cl('date')}>
        &mdash;
        <br />
        <div className={this.cl('item')}>{date}</div>{' '}
        {datetime.getHours() !== 3 && <div className={this.cl('item')}>в {time}</div>}
      </div>
    );
  }

  componentDidMount() {
    const { begin } = this.props;

    if (process.browser && begin) {
      if (new Date() > new Date(this.props.begin)) {
        this.setState({
          disabled: true
        });
      }
    }
  }

  render() {
    const { className, begin, end, repeat, city, type, address, label, btnTitle, recording, topic, authors, ...props } = this.props;
    const { disabled } = this.state;

    const classes = classnames(className, this.cl({disabled: disabled, recording: Boolean(recording) }));

    return (
      <Card
        className={classes}
        {...props}
        type={type || (recording ? <Recording>Запись выступления</Recording> : 'Событие') }
        label={!disabled || !recording ? label : false} 
        childrenHead={
          repeat && (
            <div className={this.cl('repeat')}>
              {repeat}
              <Icon className={this.cl('repeat-icon')} name={'repeat'} />
            </div>
          )
        }
      >
        <div className={this.cl('topic')} dangerouslySetInnerHTML={{__html: topic }} />
        <div className={this.cl('footer')}>
          {authors && (
            <div className={this.cl('authors')}>
              <FaceList type={'hidden'} flow={'row'}>
                {authors}
              </FaceList>
            </div>
          )}
          {(!disabled && (city || address)) && (
            <div className={this.cl('footer_contacts')}>
                <div className={this.cl('address')}>
                  {city}
                  {address && (
                    <>
                      , <br />
                      {address}
                    </>
                  )}
                </div>
              {begin && this.renderBeginDate()}
              {end && this.renderEndDate()}
            </div>
          )}
          {disabled && (
            <Button className={this.cl('button', { disabled: disabled })} size={'s'}>
              {btnTitle ? btnTitle : !disabled ? `Записаться${label ? ` на ${label}` : ''}` : 'Запись закрыта'}
            </Button>
          )}
          
        </div>
      </Card>
    );
  }
}

export default CardEvent;

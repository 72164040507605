import styled, { css } from 'styled-components';
import tools from 'utils/tools';

const Share = styled.div`
  border: 1px solid #d4d4d4;
  display: inline-flex;
  flex-direction: column;
  background: white;
  position: relative;
  @media (max-width: 1024px) {
    display: none;
  }
`;

Share.Wrapper = styled.div`
  background: white;
  position: relative;
`;

Share.Button = styled.div`
  ${({ theme: { color } }) => css`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    background: white;
    height: 65px;
    width: 65px;
    cursor: pointer;
    transition: all 0.2s ease;
    &:hover {
      background: ${color.gray_100};
    }
    &:not(:last-child) {
      border-bottom: 1px solid ${color.gray_300};
    }
  `}
`;

Share.Success = styled.div`
  ${tools.absolute}
  ${tools.flexCenter}
`;

Share.CopiedInner = styled.div`
  background-color: black;
  color: white;
  padding: 10px;
  white-space: nowrap;
  font-size: 14px;
  transition: all 0.5s ease;
  transform: translateX(-100%);
`;

Share.Copied = styled.div`
  overflow: hidden;
  color: white;
  position: absolute;
  bottom: 17px;
  right: 125%;

  transition: all 0.5s ease;

  transform: translateX(120%);
  ${props =>
    props.active &&
    css`
      transform: none;
      opacity: 1;
      visibility: visible;
      ${Share.CopiedInner} {
        transform: none;
      }
    `}
`;

Share.Icon = styled.div`
  width: 32px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  ${props =>
    props.hide &&
    css`
      transform: scale(0);
    `}
`;

export default Share;

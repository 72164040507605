import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Image } from 'elements';
import { classnames, classname } from 'utils';

import './ContentPoster.css';

export class ContentPoster extends PureComponent {
  cl = classname('content-poster');

  static propTypes = {
    /** Тема */
    theme: PropTypes.oneOf(['light', 'dark', 'green']),
  };

  static defaultProps = {
    theme: 'dark'
  };

  render() {
    const { className, poster, theme, children } = this.props;

    const classes = classnames(className, this.cl({ theme }));

    return (
      <div className={classes}>
        <div className={this.cl('poster')}>
          <Image src={poster} original={true} />
        </div>
         
        <div className={this.cl('content')}>{children}</div>
          
      </div>
    );
  }
}

import React from 'react';
import Marquee from 'react-fast-marquee';
import { classnames, classname } from 'utils';
import { Image } from 'elements';

import './marquee.css';
import SvgInline from '../../../SvgInline/SvgInline';

const DarkMarquee = ({ logos = [], className }) => {
  const cl = classname('dark-marquee');
  const classNames = classnames(className, cl());
  return (
    <div className={classNames}>
      <Marquee gradient={false} className={cl('marquee')} speed={20}>
        {[...logos, ...logos, ...logos].map((url, i) => (
          <SvgInline url={url} key={'dmq-' + i} className={cl('logo')} />
        ))}
      </Marquee>
    </div>
  );
};

export default DarkMarquee;

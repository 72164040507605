import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { classnames, classname } from 'utils';
import { Heading } from 'elements';
import './Quote.css';

class Quote extends Component {
  cl = classname('quote');

  static propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    text: PropTypes.node,
  };

  render() {
    const {className, label, text} = this.props;

    return (
      <div className={classnames(this.cl(), className)}>
        {label && 
          <div className={this.cl('label')}>{label}</div>
        }
        <div className={this.cl('text')}><Heading level={1}>{text}</Heading></div>
      </div>
    )
  }
}

export default Quote
import React from 'react';

const Approach = ({ item, cl }) => {
  const { note, text } = item;
  return (
    <div className={cl('item')}>
      <div className={cl('item-note')} dangerouslySetInnerHTML={{ __html: note }} />
      <div className={cl('item-text')} dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  );
};

export default Approach;

import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: #1e1e1e;
`;

export const Flex = styled.div`
  display: flex;
  gap: 1px;
  @media (max-width: 1023px) {
    flex-direction: column;
  }
  @media (max-width: 500px) {
    gap: 0;
  }
`;

export const Item = styled.div`
  width: calc(100% / 2 - 1px);
  @media (max-width: 1023px) {
    width: 100%;
  }
`;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Button } from 'elements';

import { classnames, classname, raw } from 'utils';

import './ContentFactoid.css';

export class ContentFactoid extends PureComponent {
  cl = classname('content-factoid');

  static propTypes = {
    /** Дополнительный класс */
    className: PropTypes.string,
    /** Расположение */
    direction: PropTypes.oneOf(['row', 'column', 'wide', 'gap'])
  };

  static defaultProps = {
    direction: 'row'
  };

  render() {
    const { className, children, direction, footnote, footnotePos, type } = this.props;

    const classes = classnames(this.cl({ direction }), className);

    return (
      <div className={classes}>
        {children.map((item, i) => {
          const Tag = item.url ? 'a' : 'div';

          return (
            <div className={this.cl('item')} key={item.name}>
              <div className={this.cl('number')} {...raw(item.number)} />
              <Tag
                href={item.url}
                target={'_blank'}
                className={this.cl(type === 'solo' ? 'name solo' : 'name')}
                {...raw(item.name)}
              />
              {item.text && <div className={this.cl('text')} {...raw(item.text)} />}
              {item.link && (
                <a href={item.link} target={'_blank'} className={this.cl('link')} data-hover={true}>
                  <Button>{item.anchor ? item.anchor : 'Посмотреть пример'}</Button>
                </a>
              )}
              {i == footnotePos - 1 && <div className={this.cl('footnote')}>{footnote}</div>}
            </div>
          );
        })}
      </div>
    );
  }
}

import React from 'react';
import * as S from './CaseSlider.styled';
import Slide from './components/slide/Slide';

const CaseSlider = ({ content = [] }) => {
  return (
    <S.Wrapper>
      <S.Flex>
        {content.map((el, i) => (
          <S.Item key={`csl-${i}`}>
            <Slide data={el} />
          </S.Item>
        ))}
      </S.Flex>
    </S.Wrapper>
  );
};

export default CaseSlider;

import React from 'react';

const Advantage = ({ name, text, cl }) => {
  return (
    <div className={cl('advantage')}>
      <div className={cl('advantage-name')} dangerouslySetInnerHTML={{ __html: name }} />
      <div className={cl('advantage-text')} dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  );
};

export default Advantage;

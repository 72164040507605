import React, { useEffect, useState } from 'react';

const setWord = (text, words, count) => {
  const word = `<span>${words[count]}</span>`;
  return text.replace('[words]', word);
};

const Title = ({ title, cl }) => {
  const { text, words } = title;
  const [dynamicTitle, setDynamicTitle] = useState(setWord(text, words, 0));

  useEffect(() => {
    let count = 0;
    const intervalId = setInterval(() => {
      setDynamicTitle(setWord(text, words, count));
      if (count >= words.length - 1) {
        count = 0;
      } else {
        count = count + 1;
      }
    }, 3000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div className={cl('title-wrapper')}>
      <h1 className={cl('title')} dangerouslySetInnerHTML={{ __html: dynamicTitle }} />
    </div>
  );
};

export default Title;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createImage, classname, classnames } from 'utils';
import './Image.css';

class Image extends Component {
  static propTypes = {
    /** Дополнительный класс */
    className: PropTypes.string,
    /** Путь к файлу */
    src: PropTypes.string.isRequired,
    /** Ширина */
    width: PropTypes.number,
    /** Высота */
    height: PropTypes.number,
    original: PropTypes.bool
  };

  static defaultProps = {
    width: 0,
    height: 0,
    alt: ''
  };

  render() {
    const { className, src, width, height, alt, original, source, style = {} } = this.props;

    const classes = classname('image');

    return original ? (
      <img
        className={classnames(classes(), className)}
        src={'https://static.nimax.ru' + src}
        alt={alt}
        loading={'lazy'}
      />
    ) : source ? (
      <picture>
        {source.map(({ width, viewport }) => (
          <source
            srcSet={`${createImage(src, width, height)}, ${createImage(src, width * 2)} 2x`}
            media={`(min-width: ${viewport}px)`}
            key={viewport}
          />
        ))}
        <img
          className={classnames(classes(), className)}
          src={createImage(src, width, height)}
          srcSet={createImage(src, width * 2) + ' 2x'}
          loading={'lazy'}
          alt={alt}
        />
      </picture>
    ) : (
      <img
        className={classnames(classes(), className)}
        src={createImage(src, width, height)}
        srcSet={createImage(src, width * 2) + ' 2x'}
        loading={'lazy'}
        alt={alt}
        style={style}
      />
    );
  }
}

export default Image;

import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: row;

  @media (max-width: ${({ theme }) => `${theme.m.b_1024}px`}) {
    flex-direction: column;
    height: auto;
  }
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 100px 0 0 190px;

  @media (max-width: ${({ theme }) => `${theme.m.b_1440}px`}) {
    padding: 40px 0 0 190px;
  }

  @media (max-width: ${({ theme }) => `${theme.m.b_1024}px`}) {
    padding: 40px 0 0 120px;
    align-items: flex-start;
  }

  @media (max-width: ${({ theme }) => `${theme.m.b_768}px`}) {
    padding: 40px 40px 0 40px;
  }
`;

export const Title = styled.h1`
  font-size: 120px;
  line-height: 120px;
  color: ${({ theme: { color } }) => color.white_500};
  margin-bottom: 100px;
  margin-left: -8px;

  @media (max-width: ${({ theme }) => `${theme.m.b_1440}px`}) {
    font-size: 100px;
    line-height: 104px;
    margin-bottom: 60px;
  }

  @media (max-width: ${({ theme }) => `${theme.m.b_1024}px`}) {
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 18px;
  }
`;

export const SubTitle = styled.div`
  width: 679px;
  color: ${({ theme: { color } }) => color.white_500};
  font-size: ${({ primary }) => (primary ? '24px' : '16px')};
  line-height: ${({ primary }) => (primary ? '40px' : '24px')};
  opacity: ${({ opacity }) => (opacity ? opacity : 1)};
  margin-bottom: 20px;
  padding-left: 85px;

  @media (max-width: ${({ theme }) => `${theme.m.b_1440}px`}) {
    font-size: ${({ primary }) => (primary ? '22px' : '14px')};
    line-height: ${({ primary }) => (primary ? '33px' : '21px')};
  }

  @media (max-width: ${({ theme }) => `${theme.m.b_1024}px`}) {
    display: ${({ mobileHide }) => (mobileHide ? 'none' : 'block')};
    padding-left: 0;
  }

  @media (max-width: ${({ theme }) => `${theme.m.b_425}px`}) {
    width: 100%;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  flex: 1;
  padding: 0 0 40px 85px;
  justify-self: flex-end;

  > svg {
    fill: ${({ theme: { color } }) => color.white_500};
    transform: rotate(90deg);
  }

  @media (max-width: ${({ theme }) => `${theme.m.b_1024}px`}) {
    display: ${({ mobileHide }) => (mobileHide ? 'none' : 'block')};
    padding: 0 10px 40px 120px;
    flex: 0;
  }

  @media (max-width: ${({ theme }) => `${theme.m.b_768}px`}) {
    padding: 0 10px 0 0;
  }
`;

export const MobileWrapper = styled.div`
  display: none;

  @media (max-width: ${({ theme }) => `${theme.m.b_1024}px`}) {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  @media (max-width: ${({ theme }) => `${theme.m.b_768}px`}) {
    padding: 0 40px;
  }
`;

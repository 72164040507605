import React from 'react';
import { classnames } from 'utils';
import { Seo, Feed, SeoHidden } from 'components';
import { Hat } from 'components/Hat/Hat';

import { getFeedList, portfolioSitesFeedKeys } from './utils';

function generateFeedFromKeys(keyMap) {
  const feed = [];
  for (const key of portfolioSitesFeedKeys) {
    if (keyMap[`projects/${key}`]) {
      feed.push(keyMap[`projects/${key}`]);
    }
  }
  return feed;
}

const projectsMeta = require('../../../../projects-meta.json');
const cl = classnames('projects-listing');
const ProjectDepartments = (props) => {
  // const departments = ['brands', 'change', 'media', 'services', 'sites'];

  const departmentsMap = {
    brands: ['m/Брендинг', 'Брендинг'],
    sites: ['Сайты', 'Сервисы', 'm/Сайты и сервисы'],
    performance: ['SEO', 'Реклама', 'm/Реклама, SEO'],
    media: ['SMM', 'Контент', 'm/Коммуникации']
  };

  const [feed] = React.useState(
    props.category === 'sites'
      ? generateFeedFromKeys(props.feed)
      : getFeedList('portfolio', props.feed, { filters: departmentsMap[props.category] })
  );

  const id = props.id;
  const projectsList = feed.map((i) => i.id);

  return (
    <div className={classnames(cl, props.className)} id={id}>
      {/**
       * SEO: Теги и H1 (скрытый)
       */}
      <div style={{ display: 'none' }}>
        <Seo title={projectsMeta[props.category].seo_title} description={projectsMeta[props.category].description} />
        <SeoHidden>
          <h1>{projectsMeta[props.category].seo_title}</h1>
        </SeoHidden>
      </div>
      {/**
       * H2, он же Заголовок страницы
       */}
      <Hat title={projectsMeta[props.category].title} pageType={id} as="h2" />
      {/**
       * Листинг проектов
       */}
      <div className={classnames(cl, 'projects-feed')}>
        <Feed list={[...projectsList, 'more/all']} />
      </div>
    </div>
  );
};

export default ProjectDepartments;

import React from 'react';
import { classname, classnames } from 'utils';
import { Icon } from 'elements';
import { FaceList } from 'components';

import './job-footer.css';

const JobFooter = ({ title, order_title, order_title_hh, hh_link, type, className }) => {
  const cl = classname('job-footer');

  const HR = {
    web: ['uspenskaya'],
    media: ['yakovleva'],
    yakovleva: ['yakovleva'],
    all: ['perevalova'],
    popova: ['popova']
  };

  const MAIL = {
    web: <a href="mailto:eu@nimax.ru">eu@nimax.ru</a>,
    media: <a href="mailto:rabota@nimax.ru">rabota@nimax.ru</a>,
    all: <a href="mailto:rabota@nimax.ru">rabota@nimax.ru</a>,
    popova: <a href="mailto:rabota@nimax.ru">rabota@nimax.ru</a>,
    yakovleva: <a href="mailto:ira.y@nimax.ru">ira.y@nimax.ru</a>
  };

  const setSingleType = (str, obj) => {
    if (typeof str === 'string') {
      if (obj[str.toLocaleLowerCase()]) {
        return str.toLocaleLowerCase();
      }
    }
    return 'all';
  };
  
  return (
    <div className={classnames(cl('wrapper'))}>
      <div className={cl('title')}>
        <p
          className={cl('inner_title')}
          dangerouslySetInnerHTML={{
            __html: order_title ? order_title : `Присылайте портфолио и рассказ о себе с темой «${title}»`
          }}
        />
        <Icon name={'cursor'} className={cl('action-icon')} size={'fit'} />
        {setSingleType(type, MAIL) === 'web' ? (
          <a href="mailto:eu@nimax.ru">eu@nimax.ru</a>
        ) : setSingleType(type, MAIL) === 'yakovleva' ? (
          <a href="mailto:ira.y@nimax.ru">ira.y@nimax.ru</a>
        ) : (
          <a href="mailto:rabota@nimax.ru">rabota@nimax.ru</a>
        )}
        {!!order_title_hh && !!hh_link && (
          <>
            <p
              className={cl('inner_title')}
              dangerouslySetInnerHTML={{
                __html: order_title_hh
              }}
            />
            <a href={hh_link}>hh.ru</a>
          </>
        )}
        <div className={cl('hr')}>
          <FaceList>{HR[setSingleType(type, HR)]}</FaceList>
        </div>
      </div>
    </div>
  );
};

export default JobFooter;

import React from 'react';
import { classname } from 'utils';
import Swiper from 'react-id-swiper';
import DarkCursor from '../DarkCursor/DarkCursor';
import './dark-team-slider.css';
import Slide from './components/Slide';

const DarkTeamSlider = ({ title, images = [], gradient, description }) => {
  const cl = classname('dark-team-slider');
  const swiperProps = {
    slidesPerView: 'auto',
    centeredSlides: false,
    initialSlide: 0,
    slideToClickedSlide: true,
    grabCursor: true
  };
  return (
    <div className={`${cl('wrapper')} ${gradient ? 'gradient' : ''}`}>
      <div className={cl('text-wrapper')}>
        <div className={cl('title')} dangerouslySetInnerHTML={{ __html: title }} />
        {description && <div className={cl('text')} dangerouslySetInnerHTML={{ __html: description }} />}
      </div>

      <DarkCursor>
        <Swiper {...swiperProps}>
          {images.map((image, i) => (
            <div className={cl('slide')} key={'dts-' + i + image.title}>
              <Slide image={image} cl={cl} />
            </div>
          ))}
        </Swiper>
      </DarkCursor>
    </div>
  );
};

export default DarkTeamSlider;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { classnames, classname, raw } from 'utils';
import './Motive.css';

/**
 * Главная мысль воторой вариант
 */
class Motive extends Component {
  cl = classname('motive');

  static propTypes = {
    /** Дополнительный класс */
    className: PropTypes.string,
    /** Содержимое */
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
  };

  render() {
    const { children, className } = this.props;

    const classes = classnames(className, this.cl());

    return <div className={classes} {...raw(children)} />;
  }
}

export default Motive;

import React from 'react';
import { classnames, classname } from 'utils';
import './order.css';

const Order = ({ content, className }) => {
  const cl = classname('dark-order');
  const classNames = classnames(className, cl());

  return (
    <section className={classNames}>
      <div className={cl('wrapper')}>
        {content.map((item, i) => (
          <div className={cl('item')} key={'dpob-' + i}>
            <div className={cl('title')} dangerouslySetInnerHTML={{ __html: item.title }} />
            <div className={cl('description')} dangerouslySetInnerHTML={{ __html: item.description }} />
            <div className={cl('button')}>
              <a href={item.link} dangerouslySetInnerHTML={{ __html: item.buttonText }} />
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Order;

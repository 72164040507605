import React from 'react';
import { classname } from 'utils';
import Service from './components/Service';
import './dark-services.css';

const DarkServices = ({ services = [], title = '' }) => {
  const cl = classname('dark-services');

  return (
    <div className={cl('wrapper')}>
      <div className={cl('inner-title')}>
        <h2 className={cl('title')} dangerouslySetInnerHTML={{ __html: title }} />
      </div>
      <div className={cl('inner-services')}>
        {services.map((service, i) => (
          <Service key={`service-${i}`} service={service} cl={cl} />
        ))}
      </div>
    </div>
  );
};

export default DarkServices;

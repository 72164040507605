import React, { PureComponent } from 'react';

import Block from './SeoHidden.style';

/**
 * Скрытые элементы для SEO
 */
class SeoHidden extends PureComponent {
  render() {
    const { children } = this.props;

    return <Block>{children}</Block>;
  }
}

export default SeoHidden;

import React, { useRef } from 'react';
import Swiper from 'react-id-swiper';
import { classnames, classname } from 'utils';
import { Figure, Cursor } from 'components';

import './block-slider.css';

const BlockSlider = ({ className, feed = [] }) => {
  const ref = useRef(null);

  const cl = classname('block-slider');
  const classNames = classnames(className, cl());

  const noSwipingClass = 'swiper-no-swiping';
  const options = {
    slidesPerView: 1,
    centeredSlides: false,
    initialSlide: 0,
    grabCursor: true,
    loop: false,
    noSwipingClass,
    on: {
      slideChange: () => {
        onSlideChangeHandler();
      }
    }
  };

  const onSlideChangeHandler = () => {};

  // const goNext = () => {
  //   return ref.current?.childNodes[0]?.swiper?.slideNext();
  // };

  // const goPrev = () => {
  //   return ref.current?.childNodes[0]?.swiper?.slidePrev();
  // };

  return (
    <div className={classNames}>
      <div className={cl('wrapper')}>
        <Cursor>
          <div className={cl('slider')} ref={ref}>
            <Swiper {...options}>
              {feed.map((item, i) => (
                <div className={cl('item')} key={'bsc-' + i}>
                  {item}
                </div>
              ))}
            </Swiper>
          </div>
        </Cursor>
      </div>
    </div>
  );
};

export default BlockSlider;

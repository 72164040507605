import React, { Component } from 'react';

import { Email, Phone, Button, Heading, Address } from 'elements';
import { ListTags } from 'components';
import { classnames, classname, raw } from 'utils';

import './Hat.css';

export class Hat extends Component {
  cl = classname('hat');

  render() {
    const { className, title, as, office } = this.props;

    const classes = classnames(className, this.cl());
    return (
      <div className={classes}>
        <div className={this.cl('wrapper')}>
          <Heading as={as} className={this.cl('title')}>
            {title}
          </Heading>
          <div className={this.cl('links')}>
            <Phone>{(phone) => <a href={'tel:' + phone}>{phone}</a>}</Phone>
            <Email>
              {(email) => (
                <a href={`mailto:${email}`} className={this.cl('email')}>
                  {email}
                </a>
              )}
            </Email>
            {office && (
              <a href="https://yandex.ru/maps/-/CCCVnW6D">
                <Address><div className={this.cl('small')}>ООО "Нимакс" ОГРН 1109847023626 </div>190005, Санкт-Петербург, Московский проспект, д. 55, 3&nbsp;этаж</Address>
              </a>
            )}
          </div>
        </div>
      </div>
    );
  }
}

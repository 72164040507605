const font = {
  navigo: 'Navigo, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif',
  ptserif: 'PT Serif, Helvetica, Arial, sans-serif'
};

const color = {
  white_500: '#fff',

  black_500: '#000',
  black_400: '#413C49',

  green_700: '#508869',
  green_600: '#67aa85',
  green_500: '#6fbe92',
  green_300: '#87d5a9',
  green_100: '#c3f4d9',

  gray_500: '#B4B4B4',
  gray_300: '#d4d4d4',
  gray_200: '#e1e1e1',
  gray_100: '#f1f1f1',
  gray_50: '#F4F4F4'
};

const size = {
  content: {
    start: 20,
    end: 60
  }
};

const transition = {
  func: 'ease-in-out',
  fast: '150ms',
  normal: '300ms',
  slow: '450ms'
};

/**
 * Брейкпоинт
 */
const breakpoints = {
  phone_320: 320,
  phone_375: 375,
  phone_425: 425,
  phone_540: 540,
  phablet_768: 768,
  tablet_1024: 1024,
  desktop_1280: 1280,
  desktop_1440: 1440,
  desktop_1600: 1600,
  desktop_1920: 1920,
  desktop_2560: 2560
};

/**
 * Короткие медиавыражения
 */
const m = {
  b_320: 320,
  b_375: 375,
  b_425: 425,
  b_540: 540,
  b_768: 768,
  b_1024: 1024,
  b_1280: 1280,
  b_1440: 1440,
  b_1600: 1600,
  b_1920: 1920,
  b_2560: 2560,
  large: (value) => `min-width: ${value + 1}px`,
  less: (value) => `max-width: ${value}px`,
  and: (min, max) => `min-width: ${min + 1}px) and (max-width: ${max}px`
};

/**
 * Медиавыражения
 */
const media = {
  phone_320: {
    large: `min-width: ${breakpoints.phone_320 + 1}px`,
    less: `max-width: ${breakpoints.phone_320}px`
  },
  phone_375: {
    large: `min-width: ${breakpoints.phone_375 + 1}px`,
    less: `max-width: ${breakpoints.phone_375}px`
  },
  phone_425: {
    large: `min-width: ${breakpoints.phone_425 + 1}px`,
    less: `max-width: ${breakpoints.phone_425}px`
  },
  phone_540: {
    large: `min-width: ${breakpoints.phone_540 + 1}px`,
    less: `max-width: ${breakpoints.phone_540}px`
  },
  phablet_768: {
    large: `min-width: ${breakpoints.phablet_768 + 1}px`,
    less: `max-width: ${breakpoints.phablet_768}px`
  },
  tablet_1024: {
    large: `min-width: ${breakpoints.tablet_1024 + 1}px`,
    less: `max-width: ${breakpoints.tablet_1024}px`
  },
  desktop_1280: {
    large: `min-width: ${breakpoints.desktop_1280 + 1}px`,
    less: `max-width: ${breakpoints.desktop_1280}px`
  },
  desktop_1440: {
    large: `min-width: ${breakpoints.desktop_1440 + 1}px`,
    less: `max-width: ${breakpoints.desktop_1440}px`
  },
  desktop_1600: {
    large: `min-width: ${breakpoints.desktop_1600 + 1}px`,
    less: `max-width: ${breakpoints.desktop_1600}px`
  },
  desktop_1920: {
    large: `min-width: ${breakpoints.desktop_1920 + 1}px`,
    less: `max-width: ${breakpoints.desktop_1920}px`
  },
  desktop_2560: {
    large: `min-width: ${breakpoints.desktop_2560 + 1}px`,
    less: `max-width: ${breakpoints.desktop_2560}px`
  },
  and: (min, max) => `(${min}) and (${max})`,
  or: (min, max) => `(${min}) or (${max})`
};

/**
 * Вариативное значение
 */
const variable = (valueMin, valueMax, viewportMin = 320, viewportMax = 1920, unit = 'px') => {
  const valueDiff = valueMax - valueMin;
  const viewportDiff = viewportMax - viewportMin;

  return `calc(${valueMin + unit} + (${valueDiff} * ((100vw - ${viewportMin}px) / ${viewportDiff})))`;
};

/**
 * Вариативный параметр
 */
const variableProperty = (name, valueMin, valueMax, viewportMin = 375, viewportMax = 1920, append = '') => {
  return `
        @media (min-width: ${viewportMax + 1}px) {
            ${name}: ${valueMax}px;
        }
        @media (max-width: ${viewportMax}px) and (min-width: ${viewportMin + 1}px) {
            ${name}: ${variable(valueMin, valueMax, viewportMin, viewportMax)};
        }
        @media (max-width: ${viewportMin}px)  {
            ${name}: ${valueMin}px;
        }
    `;
};

export default {
  font,
  color,
  size,
  transition,
  breakpoints,
  media,
  m,
  variable,
  variableProperty
};

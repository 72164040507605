import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { classname, classnames } from 'utils';
import './Caption.css';

/**
 * Подпись
 */
class Caption extends Component {
  cl = classname('caption');

  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired
  };

  render() {
    const { children } = this.props;

    return <div className={classnames(this.cl())}>{children}</div>;
  }
}

export default Caption;

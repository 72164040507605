import React, { useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import createObserver from '../../utils/createObserver';

const Wrapper = styled.div`
  flex: 1;
  opacity: ${({isShow}) => isShow ? 1 : 0};
  animation: ${({isShow}) => {
    if(isShow) {
      return 'transitionAnimation 1s cubic-bezier(0, 0.55, 0.45, 1)';
    }
  }};
  transition: opacity 1s linear;
  
  @keyframes transitionAnimation {
    from {
      transform: translateY(-50px);
    }
    to {
      transform: translateY(0px);
    }
  }

  @keyframes transitionAnimationMobile {
    from {
      transform: translateY(-25px);
    }
    to {
      transform: translateY(0px);
    }
  }

  @media (max-width: ${({theme}) => `${theme.m.b_1024}px`}) {
    flex: 0;
  }
`;

const FadeIn = ({ alignSelf, children }) => {
  const wrapperRef = useRef(null);
  const [isShow, setIsShow] = useState(false);

  const handleIsShow = () => {
    setIsShow(true);
  };

  useLayoutEffect(() => {
    const observer = createObserver(handleIsShow);
    observer.observe(wrapperRef.current);
  }, []);

  return (
    <Wrapper ref={wrapperRef} isShow={isShow} alignSelf={alignSelf}>
      {children}
    </Wrapper>
  );
};

export default FadeIn;

import styled, { css } from 'styled-components';

const MagneticButton = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: none;
  outline: none;
  cursor: pointer;
  user-select: none;
  transition: color 0.3s ease-in, transform 0.6s linear;

  ${(props) =>
    props.isHover &&
    css`
      transition: color 0.3s ease-out, transform 0.1s linear;
    `}
`;

MagneticButton.Deco = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 50%;
  border-color: var(--color-green_500);
  transition-property: transform;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;

  ${(props) =>
    props.border &&
    css`
      border-width: 2px;
      border-style: solid;
    `}

  ${(props) =>
    props.isHover &&
    css`
      transition-timing-function: ease-in;
    `}
`;

MagneticButton.Filter = styled.div`
  background-color: #6fbe92;
  position: absolute;
  width: 150%;
  height: 200%;
  border-radius: 50%;
  top: -50%;
  transform: translate3d(0, 75%, 0);
  transition-duration: 0.3s;
  transition-timing-function: ease-out;

  ${(props) =>
    props.isHover &&
    css`
      transform: translate3d(0, 0, 0);
      transition-timing-function: ease-in;
    `}
`;

MagneticButton.Inner = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  transition-property: transform;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;

  ${(props) =>
    props.isHover &&
    css`
      transition-timing-function: ease-in;
    `}
`;

MagneticButton.Content = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  transition-property: transform;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;

  ${(props) =>
    props.isHover &&
    props.contentScale &&
    css`
      transform: scale(0.8);
      transition-timing-function: ease-in;
    `}
`;

export default MagneticButton;

import { LocationProvider } from '@reach/router';
import { navigate } from 'gatsby';

let status = false;

const back = function() {
  window.history.back();
};

status = false;

LocationProvider.defaultProps.history.listen(() => {
  status = true;
});

export default back;

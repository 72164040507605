import React from 'react';
import { classnames } from 'utils';
import { Seo, Feed, SeoHidden, ToggleList } from 'components';
import { Button } from 'elements';
import { Hat } from 'components/Hat/Hat';

import { getFeedList, getTagsListFromFeed, protfolioFeedKeys } from './utils';

const cl = classnames('tags projects-listing');

const ProjectsTags = (props) => {
  const [feed, setFeed] = React.useState(getFeedList('tags', props.feed, { keys: protfolioFeedKeys, tags: [] }));
  const [activeTags, setActiveTags] = React.useState([]); // string[]

  const id = props.id;
  const projectsList = feed.map((i) => i.id);
  // const tags = getTagsListFromFeed(feed);
  const tags = getTagsListFromFeed(getFeedList('tags', props.feed, { keys: protfolioFeedKeys, tags: [] }));
  const inactiveTags = tags.filter((t) => !activeTags.some((at) => at === t));
  const sortedTags = [...activeTags, ...(projectsList.length === 1 ? [] : inactiveTags)];

  const toggleListItems = (items) => {
    return [
      activeTags.length ? (
        <Button size="s" onClick={onClickReset} className="reset">
          Сбросить теги
        </Button>
      ) : null,
      ...items.map((tag, index) => (
        <Button
          size="s"
          key={tag + index}
          onClick={() => onClickTag(tag)}
          className={classnames(checkActiveTag(tag) && 'active')}
        >
          {tag}
        </Button>
      ))
    ];
  };

  function checkActiveTag(tag) {
    return !!activeTags.find((i) => i === tag);
  }

  function onClickTag(tag) {
    setActiveTags((prev) => {
      if (checkActiveTag(tag)) {
        return prev.filter((i) => i !== tag);
      } else {
        return [
          // ...prev,
          tag
        ];
      }
    });
  }

  function onClickReset() {
    setActiveTags([]);
    updateFeed();
  }

  function updateFeed() {
    setFeed(getFeedList('tags', props.feed, { keys: protfolioFeedKeys, tags: activeTags }));
  }

  React.useEffect(() => {
    updateFeed();
  }, [activeTags]);

  return (
    <div className={classnames(cl, props.className)} id={id}>
      {/**
       * SEO: Теги и H1 (скрытый)
       */}
      <div style={{ display: 'none' }}>
        <Seo title="Теги digital-агентства Nimax" description="Теги digital-агентства Nimax" />
        <SeoHidden>
          <h1>Теги digital-агентства Nimax</h1>
        </SeoHidden>
      </div>
      {/**
       * H2, он же Заголовок страницы
       */}
      <Hat title="Теги" pageType={id} as="h2" />
      {/**
       * Tags
       */}
      <div className={classnames(cl, 'filter')}>
        <div className={classnames(cl, 'filter-list')}>
          <ToggleList items={toggleListItems(sortedTags)} minItems={inactiveTags.length < 8 ? 0 : 8} />
        </div>
      </div>
      {/**
       * Листинг тегов
       */}
      <div className={classnames(cl, 'projects-feed')}>
        <Feed list={projectsList} />
      </div>
    </div>
  );
};

export default ProjectsTags;

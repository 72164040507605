import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { classnames, classname } from 'utils';
import { Icon } from 'elements';
import './Checkbox.css';

class Checkbox extends Component {
  cl = classname('checkbox');

  static propTypes = {
    /** Дополнительный класс */
    className: PropTypes.string,
    /** Лейбл */
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    /** Имя инпута */
    name: PropTypes.string.isRequired,
    /** Значение */
    value: PropTypes.bool,
    /** Событие изменения значения */
    onChange: PropTypes.func
  };

  static defaultProps = {
    value: false
  };

  onChange(e) {
    const { onChange, name, value } = this.props;

    if (e.target.closest('a') || !onChange) return;

    onChange(name, !value);
  }

  render() {
    const { className, name, value, children } = this.props;

    return (
      <button
        className={classnames(this.cl({ checked: value }), className)}
        type={'button'}
        onClick={e => this.onChange(e)}
      >
        <div className={this.cl('wrapper')}>
          <div className={this.cl('box')} >
            <Icon className={this.cl('icon')} name={'check'} />
          </div>
          {typeof children === 'string' ? (
            <div className={this.cl('label')} dangerouslySetInnerHTML={{ __html: children }} />
          ) : (
            <div className={this.cl('label')}>{children}</div>
          )}
        </div>
        <input type="hidden" name={name} value={value ? '1' : '0'} />
      </button>
    );
  }
}

export default Checkbox;

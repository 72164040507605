import React from 'react';
import SubService from './SubService';
import { Image } from 'elements';
import ItemWrapper from './ItemWrapper';

const Service = ({ service, cl }) => {
  return (
    <div className={cl('service-item-wrapper')}>
      <ItemWrapper className={cl('service-item')} link={service.link}>
        <div className={cl('service-name')} dangerouslySetInnerHTML={{ __html: service.name }} />
        <div className={cl('service-description')}>
          {service.description && (
            <div className={cl('service-description-text')} dangerouslySetInnerHTML={{ __html: service.description }} />
          )}
          {service.subServices &&
            service.subServices.map((service, i) => <SubService key={`sub-${i}`} service={service} cl={cl} />)}
        </div>
        {service.image && (
          <div className={cl('service-image')}>
            <Image src={service.image} />
          </div>
        )}
      </ItemWrapper>
    </div>
  );
};

export default Service;

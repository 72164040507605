import React from 'react';
import { classnames, classname } from 'utils';
import './main-wrapper.css';

const MainWrapper = ({ children }) => {
  const cl = classname('main_wrapper');
  return <div className={cl()}>{children}</div>;
};

export default MainWrapper;

import React from 'react';
import { classname } from 'utils';
import Advantage from './components/Advantage';
import './dark-advantages.css';
import { Video, Image } from 'elements';

const DarkAdvantages = ({ content = [], title, video, image }) => {
  const cl = classname('dark-advantages');
  return (
    <div className={cl('wrapper')}>
      <div className={cl('pinned')}>
        <h2 className={cl('title')} dangerouslySetInnerHTML={{ __html: title }} />
        <div className={cl('background')}>
          {video ? (
            <Video src={[video]} loop={true} autoPlay={true} preload={'metadata'} />
          ) : image ? (
            <Image src={image} />
          ) : (
            ''
          )}
        </div>
      </div>
      <div className={cl('advantages')}>
        {content.map((item, i) => (
          <Advantage key={'adv-' + i} name={item.name} text={item.text} cl={cl} />
        ))}
      </div>
    </div>
  );
};

export default DarkAdvantages;

import styled, { css } from 'styled-components';
import tools from 'utils/tools';

const backgrounds = {
  default: 'white_500',
  green: 'green_500',
  dark: 'black_500'
};

const colors = {
  default: 'black_500',
  green: 'black_500',
  dark: 'white_500'
};

const Block = styled.div`
  ${({ theme: { props, color } }) => css`
    position: relative;
    background: ${color[backgrounds[props.theme]]};
    color: ${color[colors[props.theme]]};
    min-height: 420px;
    @media (max-width: 1024px) {
      min-height: auto;
    }
  `}
`;

Block.Wrapper = styled.div`
  padding: 70px;
  @media (max-width: 1024px) {
    padding: 70px 50px;
  }
  @media (max-width: 768px) {
    padding: 50px 30px;
  }
  @media (max-width: 600px) {
    padding: 40px 20px;
  }
`;

Block.Background = styled.div`
  ${tools.absolute}
  ${({ theme, image }) => `
    ${image && tools.image(image, 'cover')}
  `}
`;

Block.Heading = styled.div`
  width: 75%;
  max-width: 754px;
  @media (max-width: 768px) {
    max-width: initial;
    width: auto;
  }
`;

Block.LinkIcon = styled.div`
  height: 16px;
  width: 16px;
  margin-right: 6px;
  ${tools.flex}
`;

Block.Item = styled.div`
  margin-right: 8px;
  margin-bottom: 10px;
`;

Block.Info = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 60px;
  @media (max-width: 768px) {
    margin-top: 30px;
  }
`;

Block.Tag = styled(Block.Item)``;

Block.Link = styled(Block.Item)`
  flex-shrink: 0;
  margin-right: 15px;
`;

Block.InfoText = styled.div`
  position: relative;
  margin-left: 8px;
`;

Block.Buttons = styled.div`
  flex-wrap: wrap;
  padding-right: 15px;
  ${tools.flex}
`;

Block.Tags = styled.div`
  flex-wrap: wrap;
  padding-right: 15px;
  ${tools.flex}
`;

export default Block;

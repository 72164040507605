import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { classname, classnames } from 'utils';
import './Paragraph.css';

/**
 * Параграф
 */
class Paragraph extends Component {
  cl = classname('paragraph');

  static propTypes = {
    /** Дополнительный класс */
    className: PropTypes.string,
    /** Содержимое */
    children: PropTypes.oneOfType([PropTypes.node])
  };

  render() {
    const { className, children } = this.props;

    return typeof children === 'string' ? (
      <p className={classnames(this.cl(), className)} dangerouslySetInnerHTML={{ __html: children }} />
    ) : (
      <p className={classnames(this.cl(), className)}>{children}</p>
    );
  }
}

export default Paragraph;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { classnames, classname } from 'utils';

import './Radio.css';

export class Radio extends PureComponent {
  cl = classname('radio');

  static propTypes = {
    className: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
      })
    ),
    onChange: PropTypes.func
  };

  static defaultProps = {
    options: []
  };

  onChange(value) {
    const { onChange } = this.props;

    if (onChange) {
      onChange(value);
    }
  }

  render() {
    const { className, name, value, options } = this.props;

    const classes = classnames(className, this.cl());

    return (
      <div className={classes} style={{ '--radio__count': options.length }}>
        <input type={'hidden'} name={name} value={value} />
        {options.map(item => (
          <button
            key={item.value}
            type={'button'}
            className={this.cl('item', { active: item.value === value })}
            onClick={() => this.onChange(item.value)}
          >
            {item.name}
          </button>
        ))}
      </div>
    );
  }
}

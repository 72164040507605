import React from 'react';
import { classnames, classname } from 'utils';
import { clearNbsp } from '../../../utils/clearNbsp';
import './main-review.css';
import { Image } from 'elements';

const MainReview = ({ text, author }) => {
  const { title, first, second } = text;
  const cl = classname('main_review');
  return (
    <div className={cl('wrapper')}>
      <p className={cl('title')}>{clearNbsp(title)}</p>
      <div className={cl('inner')}>
        <span className={cl('first')}>{clearNbsp(first)}</span>
        <span className={cl('second')}>{clearNbsp(second)}</span>
      </div>
      <div className={cl('author')}>
        <div className={cl('author_image')}>
          <Image src={author.image} width={70} height={70} />
        </div>
        <div>
          <p className={cl('author_name')}>Владимир Дорохин</p>
          <p className={cl('author_position')}>Руководитель отдела маркетинга Dobro</p>
        </div>
      </div>
    </div>
  );
};

export default MainReview;

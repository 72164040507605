import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql, StaticQuery } from 'gatsby';
import { classnames, classname } from 'utils';
import './AboutOffices.css';

/**
 * Список офисов
 */
class AboutOffices extends Component {
  cl = classname('about-offices');

  static propTypes = {
    /** Дополнительный класс */
    className: PropTypes.string
  };

  render() {
    const { className, children } = this.props;

    const classes = classnames(className, this.cl());

    return (
      <div className={classes}>
        {children.map(({ city, postCode, address, office }) => (
          <div className={this.cl('item')} key={city}>
            <div className={this.cl('title')}>{city}</div>
            <div className={this.cl('content')}>
              {postCode}, {city},<br />
              {address}, {office}
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      {
        allMdx(filter: { fields: { id: { regex: "/^offices//" } } }) {
          edges {
            node {
              frontmatter {
                title
                city
                postCode
                address
                office
                video
              }
            }
          }
        }
      }
    `}
    render={({ allMdx }) => <AboutOffices {...props}>{allMdx.edges.map(item => item.node.frontmatter)}</AboutOffices>}
  />
);

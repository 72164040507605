import React from 'react';

export default {
  width: 35,
  height: 35,
  content: (
    <>
      <g transform="translate(9,10)">
        <path d="M2.50232 13.0072L13.2779 2.15524" stroke="currentColor" strokeWidth="2" />
        <path d="M7.5918 1.55859L13.2782 2.1557L13.4004 8.38334" stroke="currentColor" strokeWidth="2" />
      </g>
    </>
  )
};

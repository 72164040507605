import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { raw } from 'utils';
import { Image } from 'elements';

import Block from './Also.style';
import { Link } from 'gatsby';

/**
 * Смотреть ещё
 */
class Also extends Component {
  static propTypes = {
    label: PropTypes.string,
    title: PropTypes.string.isRequired,
    image: Image.propTypes,
    time: PropTypes.string,
    url: PropTypes.string
  };

  static defaultProps = {
    /*label: 'Смотрите также'*/
  };

  render() {
    const { label, title, image, time, url } = this.props;
    const attrs = {
      title: title
    };

    if (/^\/(?!\/)/.test(url)) {
      attrs.to = url;
      attrs.as = Link;
    } else {
      attrs.href = url;
      attrs.as = 'a';
    }

    return (
      <Block {...attrs}>
        <Block.Label {...raw(label)} />
        {image && (
          <Block.Image>
            <Image {...image} />
          </Block.Image>
        )}
        <Block.Title {...raw(title)} />
        {time && <Block.Time {...raw(time)} />}
      </Block>
    );
  }
}

export default Also;

import React from 'react';
import { classname } from 'utils';
import Service from './components/Service';
import './main-services.css';

const MainServices = ({ title = '', services = [] }) => {
  const cl = classname('main_services');

  return (
    <div className={cl()}>
      <div className={cl('title_wrapper')}>
        <div></div>
        <h2 className={cl('title')}>{title}</h2>
      </div>
      <div className={cl('services_wrapper')}>
        {services.map((service, i) => (
          <Service service={service} key={`service--${i}`} cl={cl} />
        ))}
      </div>
    </div>
  );
};

export default MainServices;
